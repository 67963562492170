<template>
  <q-card flat class="q-pa-sm">
    <q-item-label v-if="pinData.title">{{pinData.title}}</q-item-label>
    <q-item-label caption v-if="pinData.subtitle">{{pinData.subtitle}}</q-item-label>
    <q-item-label caption v-if="pinData.description">{{pinData.description}}</q-item-label>
    <q-item-label caption v-if="pinData.date">{{pinData.date}}</q-item-label>
  </q-card>
</template>

<script>
  export default {
    props: ['pinData']
  }
</script>
<style scoped lang="css"></style>