<template>
  <q-page class="q-pa-lg flex justify-around q-gutter-x-md">
    <div class="row">
      <div class="col-xs-12 col-sm-6 q-px-md">
        <q-item-label header class="q-pl-sm">Mobile App</q-item-label>
        <q-card class="q-pa-md flex row items-center justify-center" style="height: 340px">
          <MobileAppBox></MobileAppBox>
        </q-card>
      </div>

      <!-- Support -->
      <div class="col-xs-12 q-px-md col-sm-6">
        <q-item-label header class="q-pl-sm">Support</q-item-label>
        <q-card class="q-pa-md flex row items-center justify-center" style="height: 340px">
          <SupportBox></SupportBox>
        </q-card>
      </div>

      <!-- User Recently Completed -->
      <div class="col-auto">
        <q-item-label header class="q-pl-sm">Your Recently Completed Checklists</q-item-label>
        <q-card class="q-pa-sm">
          <UserRecentlyCompleted></UserRecentlyCompleted>
        </q-card>
      </div>
    </div>
  </q-page>
  <!-- </div> -->
</template>

<style></style>

<script>
import SupportBox from './blocks/SupportBox.vue'
import MobileAppBox from './blocks/MobileAppBox.vue'
import UserRecentlyCompleted from './blocks/UserRecentlyCompleted.vue'

export default {
  name: 'HomeUser',
  methods: {},
  components: { SupportBox, MobileAppBox, UserRecentlyCompleted },
  mounted() {
    this.$log('HomeUser was loaded!!!')
    if (this.$route.query.reload) {
      setTimeout(() => location.reload(), 2000)
    }
  },
}
</script>
