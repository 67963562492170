<template lang="html">
  <div class="flex column justify-center items-center q-gutter-sm">
    <q-item-label header class="text-center text-primary text-bold">Need help?</q-item-label>
    <q-item-label caption :lines="2" class="text-center">Check out our Knowledge Base for instructions and FAQs.</q-item-label>
    <q-btn unelevated no-caps rounded class="q-my-md self-center bg-secondary text-primary text-bold" @click="openKnowledgeBase">Knowledge Base</q-btn>
    <q-item-label header class="text-center text-primary text-bold">Need more help?</q-item-label>
    <q-item-label caption :lines="3" class="text-center">
      Contact our support team at <a class="text-accent" href="mailto:help@mysimplecheck.com">help@mysimplecheck.com</a> for a response within 48 hours.
    </q-item-label>
  </div>
</template>

<script>

export default {
  name: 'SupportBox',
  props: [],
  components: {},
  data() {
    return {

    }
  },
  computed: {
   
  },
  mounted () {
    
  },
  methods: {
    openKnowledgeBase() {
      window.open('https://support.mysimplecheck.com', '_blank')
    },
  }
}
</script>

<style lang="css"></style>
