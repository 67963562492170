<template lang="html">
  <q-card class="q-pa-sm">
    <q-table v-show="true" :title="titleText" :data="list" :columns="columns" row-key="id" :pagination.sync="pagination" class="table-sticky-header">
      <template v-slot:body="props" class="cursor-pointer">
        <q-tr :props="props" @click="props.expand = !props.expand">
          <q-td key="submittedByName" :props="props" style="cursor: pointer">
            <div class="q-my-sm" style="cursor: pointer">
              <q-item-label class="text-left">{{ props.row.submittedByName }}</q-item-label>
              <q-item-label class="text-left" caption v-show="props.row.tree">{{ props.row.tree.map((node) => node.label).join(' > ') }}</q-item-label>
              <q-item-label class="text-left" caption v-show="props.row.comment">"{{ props.row.comment }}"</q-item-label>
            </div>
          </q-td>
          <q-td key="startedAt" :props="props" style="cursor: pointer">
            <q-item-label class="text-left">{{ props.row.startedAt | readableDate }}</q-item-label>
          </q-td>
          <q-td key="completedAt" :props="props" style="cursor: pointer">
            <q-item-label class="text-left">{{ props.row.completedAt | readableDate }}</q-item-label>
          </q-td>
          <q-td key="actions" :props="props" style="cursor: pointer" class="q-pa-none">
            <q-btn flat class="self-center text-grey-7" style="cursor: pointer; font-size: 13px; width: 40px" icon="fas fa-ellipsis-v" @click.stop>
              <q-menu transition-show="jump-down" transition-hide="jump-up">
                <q-list style="min-width: 100px">
                  <q-item clickable>
                    <q-item-section @click="gotoUrl(props.row.link)">View Full Checklist</q-item-section>
                  </q-item>
                  <q-item clickable>
                    <q-item-section>
                      <q-item-label @click="copyUrl(props.row.link)">Copy Link</q-item-label>
                    </q-item-section>
                  </q-item>
                </q-list>
              </q-menu>
            </q-btn>
            <q-btn flat class="self-center text-grey-7" style="cursor: pointer; font-size: 13px; width: 40px" :icon="!props.expand ? 'fas fa-chevron-down' : 'fas fa-chevron-up'" />
          </q-td>
        </q-tr>
        <q-tr v-show="props.expand" :props="props" no-hover>
          <q-td colspan="100%" class="q-gutter-y-xs bg-grey-2">
            <q-list>
              <div v-for="question in props.row.questions" :key="question.name">
                <q-item>
                  <q-item-section>
                    <q-item-label>{{ question.name }}</q-item-label>
                    <q-item-label caption lines="2" v-if="question.comment">"{{ question.comment }}"</q-item-label>
                  </q-item-section>
                  <q-item-section side>
                    <q-badge :style="{ 'background-color': question.color }" :label="question.text" />
                  </q-item-section>
                </q-item>
                <q-separator spaced inset />
              </div>
              <q-btn flat rounded no-caps size="sm" class="text-accent bg-transparent float-right" @click="gotoUrl(props.row.link)">View Full Checklist</q-btn>
            </q-list>
          </q-td>
        </q-tr>
      </template>
      <template v-slot:top-right>
        <q-btn
          rounded
          unelevated
          class="bg-secondary text-primary text-bold"
          label="Export to Excel"
          no-caps
          @click="exportTable(configChecklist.name, 'exportList', 'exportColumns')"
        />
      </template>
    </q-table>
    <q-dialog v-model="showImg">
      <q-card>
        <q-card-section>
          <q-img contain :src="imgUrl" width="600px" height="600px" />
        </q-card-section>
        <q-card-actions align="right">
          <q-btn flat label="OK" color="primary" v-close-popup />
        </q-card-actions>
      </q-card>
    </q-dialog>
  </q-card>
</template>

<script>
import TableExcelExport from './blocks/TableExcelExport.vue'
import { copyToClipboard } from 'quasar'

export default {
  name: 'DetailsReport',
  props: ['configChecklist', 'reportData'],
  mixins: [TableExcelExport],
  data() {
    return {
      showImg: false,
      imgUrl: null,
      pagination: { rowsPerPage: 100, sortBy: 'startedAt', descending: true },
    }
  },
  mounted() {
    this.$log('DetailsReport mounted, building columns with configChecklist ' + this.configChecklist.name)
  },
  methods: {
    gotoUrl(link) {
      window.open(link, '_blank')
    },
    copyUrl(link) {
      copyToClipboard(link)
        .then(() => {
          this.$q.notify({
            type: 'positive',
            message: 'Link Copied to Clipboard',
          })
        })
        .catch(() => {
          this.$q.notify({
            type: 'danger',
            message: 'Something went wrong, the link could not be copied.',
          })
        })
    },
  },
  computed: {
    titleText() {
      let count = this.reportData && this.reportData.numChecklists
      return `${count} Checklists`
    },
    columns() {
      return [
        { name: 'submittedByName', align: 'left', label: 'Info', field: 'submittedByName', sortable: true },
        { name: 'startedAt', align: 'left', label: 'Started', field: 'startedAt', sortable: true, format: (m) => m.format('MM/DD/YY, HH:mm') },
        { name: 'completedAt', align: 'left', label: 'Completed', field: 'completedAt', sortable: true, format: (m) => m.format('MM/DD/YY HH:mm') },
        { name: 'actions', align: 'right', label: '', field: '', sortable: false },
      ]
    },
    list() {
      if (!this.reportData) return []
      return this.reportData.checklists.map((c) => {
        let row = {
          startedAt: this.$moment.unix(c.startedAt.seconds || c.startedAt._seconds),
          completedAt: this.$moment.unix(c.completedAt.seconds || c.completedAt._seconds),
          submittedByName: c.submittedByName,
          id: c.id,
          comment: c.comment,
          tree: c.tree,
          questions: [],
          link: window.location.origin + '/' + this.$router.resolve(`/completed/${c.id}`).href,
        }
        // loop through the items to find the checklists's questions
        this.configChecklist.items.forEach((q) => {
          // each checklist has a results object, with keys of the configItemIds.
          let result = c.results[q.itemId]
          if (!result) return // this was not found, either because of a special column of this report, or an item that is not in the config any longer
          if (result.isInput) row[`${q.name}input`] = true
          result.name = q.name || q.description
          row.questions.push(result)
        })
        return row
      })
    },
    exportQuestionInfo() {
      let questionItems = []
      if (this.configChecklist.items && this.configChecklist.items.length > 0) {
        this.configChecklist.items.forEach((item, index) => {
          let idx = index + 1
          let qInfo = {
            name: `~Q${idx}`,
            label: item.name || item.description,
            field: `~Q${idx}`,
            text: item.name || item.description,
            itemId: item.itemId,
            sortable: true,
            headerStyle: 'min-width: 200px; max-width: 250px; white-space: normal;',
          }
          questionItems.push(qInfo)
          qInfo = {
            name: `~Q${idx}comment`,
            label: `~Q${idx}comment`,
            field: `~Q${idx}comment`,
            text: `~Q${idx} comment`,
            sortable: true,
            headerStyle: 'max-width: 200px; white-space: normal;',
          }
          questionItems.push(qInfo)
          qInfo = {
            name: `~Q${idx}images`,
            label: `~Q${idx}images`,
            field: `~Q${idx}images`,
            format: (val) => {
              if (val && val[0]) {
                return {
                  text: 'Photo',
                  hyperlink: Object.values(val)[0],
                }
              }
            },
            text: `~Q${idx} first image`,
            sortable: true,
            headerStyle: 'max-width: 200px; white-space: normal;',
          }
          questionItems.push(qInfo)
          qInfo = {
            name: `~Q${idx}images`,
            label: `~Q${idx}images`,
            field: `~Q${idx}images`,
            format: (val) => {
              if (val && val[1]) {
                return {
                  text: 'Photo',
                  hyperlink: Object.values(val)[1],
                }
              }
            },
            text: `~Q${idx} second image`,
            sortable: true,
            headerStyle: 'max-width: 200px; white-space: normal;',
          }
          questionItems.push(qInfo)
          qInfo = {
            name: `~Q${idx}images`,
            label: `~Q${idx}images`,
            field: `~Q${idx}images`,
            format: (val) => {
              if (val && val[2]) {
                return {
                  text: 'Photo',
                  hyperlink: Object.values(val)[2],
                }
              }
            },
            text: `~Q${idx} third image`,
            sortable: true,
            headerStyle: 'max-width: 200px; white-space: normal;',
          }
          questionItems.push(qInfo)
        })
      }
      return questionItems
    },
    exportColumns() {
      return [
        { name: 'startedAt', label: 'Started', field: 'startedAt', sortable: true, format: (m) => m.format('MM/DD/YYYY HH:mm') },
        { name: 'tree', label: 'Tagged Information', field: 'tree', format: (t) => t && t.map((node) => node.label).join(' > ') },
        { name: 'completedAt', label: 'Completed', field: 'completedAt', sortable: true, format: (m) => m.format('MM/DD/YYYY HH:mm') },
        { name: 'submittedByName', label: 'Performed By', field: 'submittedByName', sortable: true },
        {
          name: 'link',
          label: 'View',
          field: 'link',
          sortable: false,
          format: (v) => {
            return {
              text: 'View',
              hyperlink: v,
            }
          },
          headerStyle: 'min-width: 120px; white-space: normal;',
        },
        { name: 'comment', label: 'Comment', field: 'comment', sortable: true },
        ...this.exportQuestionInfo,
      ]
    },
    exportList() {
      return this.reportData.checklists.map((c) => {
        let row = {
          startedAt: this.$moment.unix(c.startedAt._seconds || c.startedAt.seconds),
          completedAt: this.$moment.unix(c.completedAt._seconds || c.completedAt.seconds),
          submittedByName: c.submittedByName,
          id: c.id,
          comment: c.comment,
          tree: c.tree,
          link: window.location.origin + '/' + this.$router.resolve(`/completed/${c.id}`).href,
        }
        // loop through the items to find the checklists's questions
        this.exportQuestionInfo.forEach((q) => {
          // each checklist has a results object, with keys of the configItemIds.
          let result = c.results[q.itemId]
          if (!result) return // this was not found, either because of a special column of this report, or an item that is not in the config any longer
          row[q.name] = result.text
          if (result.isInput) row[`${q.name}input`] = true
          row[`${q.name}color`] = result.color
          row[`${q.name}comment`] = result.comment
          row[`${q.name}images`] = (result.images && result.images.map((img) => img.url)) || []
        })
        return row
      })
    },
  },
}
</script>

<style lang="sass">
.table-sticky-header
  /* height or max-height is important */
  max-height: 100vh

  .q-table__top,
  thead tr:first-child th
    /* bg color is important for th; just specify one */
    background-color: white

  thead tr th
    position: sticky
    z-index: 1
  thead tr:first-child th
    top: 0

  /* this is when the loading indicator appears */
  &.q-table--loading thead tr:last-child th
    /* height of all previous header rows */
    top: 48px
</style>
