<template>
  <list-view :list="trees" :selectedItem="selectedTree" @itemSelected="selectTree">
    <template slot="header">
      <q-btn rounded unelevated no-caps class="q-mr-sm bg-secondary text-primary text-bold" size="md" label="Create New" @click="$refs.treeModal.toggle()" />
      <tree-modal ref="treeModal" @close="getTrees($event)"></tree-modal>
    </template>
    <template v-slot:view>
      <!-- {{ JSON.stringify(item, null, 2) }} -->
      <tree :selectedTree="selectedTree" @cancel="cancelEdits" @save="saveCurrentTree"></tree>
    </template>
    <template v-slot:empty>
      <q-card class="w-100 q-pa-md flex column">
        <q-card-section>
          <div class="text-h6">Locations</div>
        </q-card-section>
        <q-separator inset />
        <q-card-section>
          <div class="text-subtitle2">
            Locations are used to tag a checklist before it is performed. The data is stored as a hierarchy. This information is part of completed checklists in reporting.
          </div>
        </q-card-section>
        <q-card-section>
          <div class="text-subtitle2">
            A checklist can be assigned a Location while managing the checklist. When a user starts a checklist with a Location, they will be prompted to select through the
            hierarchy.
          </div>
        </q-card-section>
        <q-card-section>
          <div class="text-subtitle2">Create a new Location, or select an existing one on the left.</div>
        </q-card-section>
      </q-card>
    </template>
  </list-view>
</template>

<script>
import TreeModal from './CreateTreeModal.vue'
import Tree from './Tree.vue'
import ListView from '../blocks/ListViewPage.vue'
export default {
  name: 'TreeList',
  components: { TreeModal, ListView, Tree },
  data() {
    return {
      selectedTreeId: null,
      trees: [],
    }
  },
  computed: {
    selectedTree() {
      if (!this.trees.length) return null
      return this.trees.find((t) => t.id === this.selectedTreeId)
    },
  },
  mounted() {
    this.getTrees()
  },
  methods: {
    selectTree(treeId) {
      this.$log(`selecting tree with id ${treeId}`)
      this.selectedTreeId = treeId
    },
    async saveCurrentTree() {
      this.$log('saving current tree')
      this.$eventBus.$emit('spinner')
      await this.$firestore
        .collection('trees')
        .doc(this.selectedTreeId)
        .set(
          {
            root: JSON.stringify(this.selectedTree.root),
            name: this.selectedTree.name,
          },
          { merge: true }
        )
      this.$eventBus.$emit('spinner', false)
    },
    async cancelEdits() {
      const idx = this.trees.findIndex((t) => t.id === this.selectedTreeId)
      if (idx > -1) this.trees.splice(idx, 1)
      const t = await this.$firestore.collection('trees').doc(this.selectedTreeId).get()
      const tree = t.data()
      tree.id = t.id
      tree.root = JSON.parse(tree.root)
      this.trees.push(tree)
      this.selectedTreeId = null
    },
    async getTrees(treeId) {
      this.$eventBus.$emit('spinner')
      this.selectedTreeId = null
      this.trees.splice(0, this.trees.length)
      const trees = await this.$firestore.collection('trees').where('companyId', 'in', this.$store.getters.accountCompanies).get()
      trees.forEach((t) => {
        // these are DocumentSnapshots
        const tree = t.data()
        tree.id = t.id
        tree.root = JSON.parse(tree.root)
        this.trees.push(tree)
      })
      if (treeId) this.selectedTreeId = treeId
      this.$eventBus.$emit('spinner', false)
    },
  },
}
</script>
