<template lang="html">
  <div class="flex column self-center items-center" v-if="company.limits" style="max-width: 300px">
    <q-circular-progress
      show-value
      font-size="12px"
      :value="usagePercentage"
      size="150px"
      :thickness="0.22"
      :color="usageColor"
      track-color="grey-3"
      class="q-ma-sm"
      style="min-width: 150px; max-width: 300px"
    >
      <q-item-label :lines="3" slot="default" class="text-center" caption>
        {{ company.limits.completedChecklists.toLocaleString() }}/{{ company.limits.checklistLimit.toLocaleString() }} <br />
        Checklists
      </q-item-label>
      <q-tooltip> Usage meter resets with the next successful payment.</q-tooltip>
    </q-circular-progress>
    <q-item-label :lines="3" class="text-center" caption>{{ usageLabel }}</q-item-label>
    <q-btn
      unelevated
      no-caps
      rounded
      class="float-left q-mt-sm text-primary text-bold bg-secondary"
      size="md"
      :label="usagePercentage > 90 ? 'Upgrade Now' : 'Upgrade'"
      @click="upgradeNow"
    ></q-btn>
  </div>
</template>

<script>
export default {
  name: 'UsageMeter',
  props: ['company'],
  data() {
    return {}
  },
  computed: {
    currentUser() {
      return this.$store.state.user.data
    },
    usagePercentage() {
      if (!this.company.limits) return
      if (this.company.limits.completedChecklists === 0) return 0
      return Math.round((this.company.limits.completedChecklists / this.company.limits.checklistLimit) * 100)
    },
    usageColor() {
      if (this.usagePercentage >= 90) return 'red-10'
      if (this.usagePercentage >= 75) return 'orange'
      return 'primary'
    },
    usageLabel() {
      var label = ''
      if (this.usagePercentage < 75) label = 'Upgrade to increase your limit.'
      if (this.usagePercentage >= 75) label = 'Consider upgrading to avoid reporting halts.'
      if (this.usagePercentage >= 90) label = 'Upgrade now to avoid reporting halts.'
      if (this.usagePercentage >= 100) label = 'Upgrade now to recover locked reports.'
      return "You're using " + this.usagePercentage + '% of your completed checklist limit. ' + label
    },
  },
  mounted() {},
  methods: {
    async upgradeNow() {
      this.$q.loading.show({
        backgroundColor: 'white',
        spinnerColor: 'primary',
      })
      const functionRef = this.$firebase.app().functions('us-central1').httpsCallable('stripe-createUpgradeNowLink')
      const { data } = await functionRef({ companyId: this.company.companyId, returnUrl: window.location.origin + '/' + this.$router.resolve(`/Home?reload=true`).href })
      this.$q.loading.hide()
      if (data) window.location.assign(data.url)
    },
  },
}
</script>

<style lang="css"></style>
