import { render, staticRenderFns } from "./ReportHeader.vue?vue&type=template&id=0d1f6d4f&lang=html&"
import script from "./ReportHeader.vue?vue&type=script&lang=js&"
export * from "./ReportHeader.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports
import QCard from 'quasar/src/components/card/QCard.js';
import QSelect from 'quasar/src/components/select/QSelect.js';
import QChip from 'quasar/src/components/chip/QChip.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QPopupProxy from 'quasar/src/components/popup-proxy/QPopupProxy.js';
import QDate from 'quasar/src/components/date/QDate.js';
import QTabs from 'quasar/src/components/tabs/QTabs.js';
import QTab from 'quasar/src/components/tabs/QTab.js';import ClosePopup from 'quasar/src/directives/ClosePopup.js';
import qInstall from "../../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(component, 'components', {QCard,QSelect,QChip,QBtn,QPopupProxy,QDate,QTabs,QTab});qInstall(component, 'directives', {ClosePopup});
