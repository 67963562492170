<template lang="html">
  <div>
    <q-input
      ref="txtName"
      outlined
      dense
      v-model="searchTerm"
      :label="myPlaceHolder"
      style="width:100%; background-color: white;"
      clearable
    ></q-input>
  </div>
</template>

<script>
import throttle from 'lodash/throttle'
export default {
  name: 'SearchBar',
  props: {
    myPlaceHolder: String
  },
  data() {
    return {
      searchTerm: ''
    }
  },
  watch: {
    searchTerm() {
      this.termUpdated()
    }
  },
  methods: {
    clear() {
      this.searchTerm = ''
    },
    termUpdated: throttle(function() {
      this.$emit('termUpdated', this.searchTerm)
    }, 200),
    makeFocus() {
      this.$refs.txtName.focus()
    }
  },
  mounted() {}
}
</script>

<style lang="css"></style>
