import Vue from 'vue'
import './styles/quasar.sass'
import iconSet from 'quasar/icon-set/fontawesome-v5.js'
import '@quasar/extras/fontawesome-v5/fontawesome-v5.css'
import Quasar from 'quasar/src/vue-plugin.js';import Notify from 'quasar/src/plugins/Notify.js';import Loading from 'quasar/src/plugins/Loading.js';import Dialog from 'quasar/src/plugins/Dialog.js';import LocalStorage from 'quasar/src/plugins/LocalStorage.js';

Vue.use(Quasar, {
  config: {},
  plugins: { Notify, Loading, Dialog, LocalStorage },
  extras: ['roboto-font'],
  iconSet: iconSet
})
