<template>
  <!-- <div class="flex justify-center"> -->
  <!-- <q-banner class="bg-negative text-white text-bold">
      You're nearing your checklist limit. Consider upgrading now to avoid reporting halts.
      <template v-slot:action>
        <q-btn flat color="white" no-caps label="Dismiss" />
        <q-btn flat color="white" no-caps label="Upgrade Now" />
      </template>
    </q-banner> -->

  <q-page class="q-pa-lg flex justify-around q-gutter-x-md">
    <div class="row">
      <!-- Usage Meter -->
      <div v-if="company && company.limits" class="col-sm-4 col-xs-12 q-px-md">
        <q-item-label header class="q-pl-sm">Usage</q-item-label>
        <q-card class="q-pa-md flex row justify-center" style="height: 340px">
          <UsageMeter :company="company"></UsageMeter>
        </q-card>
      </div>

      <!-- Join Code -->
      <div v-if="company && company.joinCode" class="col-xs-12 col-sm-4 q-px-md">
        <q-item-label header class="q-pl-sm">Company Join Link</q-item-label>
        <q-card class="q-pa-md" flex row style="height: 340px">
          <JoinLink :company="company" :joinUrl="joinUrl"></JoinLink>
        </q-card>
      </div>

      <!-- Support -->
      <div class="col-xs-12 q-px-md col-sm-4">
        <q-item-label header class="q-pl-sm">Support</q-item-label>
        <q-card class="q-pa-md flex row" style="height: 340px">
          <SupportBox></SupportBox>
        </q-card>
      </div>

      <!-- Recently Completed -->
      <div class="col-auto">
        <q-item-label header class="q-pl-sm">Recently Completed Checklists</q-item-label>
        <q-card class="q-pa-sm">
          <RecentlyCompleted :company="company"></RecentlyCompleted>
        </q-card>
      </div>
    </div>
  </q-page>
  <!-- </div> -->
</template>

<style></style>

<script>
import UsageMeter from './blocks/UsageMeter.vue'
import JoinLink from './blocks/JoinLink.vue'
import RecentlyCompleted from './blocks/RecentlyCompleted.vue'
import SupportBox from './blocks/SupportBox.vue'

export default {
  name: 'Home',
  props: ['company'],
  mounted() {},
  computed: {
    joinUrl() {
      return window.location.origin + '/' + this.$router.resolve(`/Login?newSignup=true&joinCode=${this.company.joinCode}`).href
    },
  },
  methods: {},
  components: { UsageMeter, JoinLink, RecentlyCompleted, SupportBox },
}
</script>
