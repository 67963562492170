<template>
  <q-dialog v-model="showing">
    <q-card class="q-pa-md" style="max-width: 430px;" v-if="option">
      <q-item-label header class="self-center q-pa-none">CHOOSE ICON</q-item-label>
      <q-card-section class="flex wrap row">
        <q-btn no-caps unelevated size="sm" :class="option.iconName === '' ? 'icon selected' : 'icon'" style="font-size: 14px;" label="None" @click="select('')"></q-btn>
        <q-icon :class="option.iconName === icon ? 'icon selected' : 'icon'" v-for="icon in icons" :key="icon" :name="icon" @click="select(icon)"></q-icon>
      </q-card-section>
    </q-card>
  </q-dialog>
</template>

<script>
export default {
  props: {
    cancelText: {
      type: String,
      default: 'Cancel',
      required: false
    }
  },
  data() {
    return {
      icons: ['far fa-thumbs-up', 'far fa-thumbs-down', 'far fa-smile', 'far fa-frown', 'fas fa-check', 'fas fa-times', 'fas fa-poop', 'fas fa-hand-sparkles' ],
      showing: false,
      selected: '',
      option: null
    }
  },
  methods: {
    toggle(option) {
      this.option = option
      this.showing = !this.showing
    },
    select (name) {
      this.option.iconName = name
      this.showing = false
    }
  }
}
</script>
<style lang="css" scoped>
  .icon {
    width: 50px;
    height: 50px;
    font-size: 30px;
    background-color: whitesmoke;
    border-radius: 3px;
    margin-right: 10px;
    margin-bottom: 10px;
    cursor: pointer;
    color: rgb(40,40,40);
  }
  .selected {
    background-color: #027BE3;
    color: white;
  }
</style>