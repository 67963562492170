<template lang="html">
  <div>
    <div class="flex row q-mb-lg q-mt-sm justify-center" style="height: 40px; min-width: 100%">
      <div class="" style="font-size: 1.2em; line-height: 40px">{{ titleText }}</div>
      <q-space />
      <div class="" style="font-size: 1em; line-height: 40px">
        Completed checklists <span style="background: #66bb6a; padding: 3px">count per day</span>, or <span style="background: #ef534f; padding: 3px">no checklists.</span>
      </div>
      <q-space />
      <div class="text-right" style="line-height: 40px">Reverse Days</div>
      <q-toggle v-model="reverse" color="primary" />
      <div v-if="this.configChecklist.treeId" class="text-right" style="line-height: 40px">List by</div>
      <q-select dense v-if="this.configChecklist.treeId" class="q-ml-md" style="width: 150px" v-model="groupBy" :options="groupOptions" />
      <q-btn rounded unelevated class="bg-secondary text-primary text-bold" label="Export to Excel" no-caps @click="exportGrid" />
    </div>
    <div style="overflow-x: scroll">
      <table class="q-mb-sm grid">
        <thead>
          <tr>
            <th style="height: 40px"></th>
            <td v-for="day in days" :key="day" style="max-width: 35px; min-width: 35px">
              <div style="padding-top: 6px; padding-left: 2px; min-height: 30px; transform: rotate(290deg)">{{ day }}</div>
            </td>
          </tr>
        </thead>
        <tbody>
          <tr v-for="title in titles" :key="title" class="grid-row">
            <th nowrap style="line-height: 35px">{{ title }}</th>
            <td v-for="d in days" :key="d" :class="grid[title][d] ? 'yes' : 'no'" class="text-center">{{ grid[title][d] || '' }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
const DAY = 'M/D'
import ExcelJS from 'exceljs'
import { exportFile } from 'quasar'
export default {
  name: 'GridReport',
  props: ['configChecklist', 'reportData'],
  mixins: [],
  data() {
    return {
      groupBy: 'User',
      groupOptions: ['User', 'Location'],
      reverse: false,
    }
  },
  mounted() {
    // this.$log('GridReport mounted, building grid for ' + this.configChecklist.name + ' with dates ' + JSON.stringify(this.reportData || this.reportData.filters.date))
  },
  methods: {
    async exportGrid() {
      const BORDER = {
        top: { style: 'thin', color: { argb: 'FFFFFFFF' } },
        left: { style: 'thin', color: { argb: 'FFFFFFFF' } },
        bottom: { style: 'thin', color: { argb: 'FFFFFFFF' } },
        right: { style: 'thin', color: { argb: 'FFFFFFFF' } },
      }
      let workbook = new ExcelJS.Workbook()
      workbook.created = new Date()
      let worksheet = workbook.addWorksheet(`${this.configChecklist.name.replace(/[[\]:\\/*!?]/g, '_')} by ${this.groupBy}`)
      worksheet.properties.defaultColWidth = 7
      worksheet.getColumn(1).width = this.groupBy === 'User' ? 35 : 60
      worksheet.getCell('A1').value = this.titleText
      worksheet.getCell('A1').style = { font: { bold: true, size: 14, color: { argb: 'FF000000' }, name: 'Arial', family: 2 } }
      worksheet.getCell('A1').alignment = { vertical: 'middle', horizontal: 'left' }

      let dateRow = worksheet.getRow(3)
      this.days.forEach((day, dayIdx) => {
        let col = dateRow.getCell(2 + dayIdx)
        col.alignment = { horizontal: 'center' }
        col.value = day
      })

      // build each row of titles, which are already sorted
      this.titles.forEach((title, titleIdx) => {
        let row = worksheet.getRow(4 + titleIdx)
        row.getCell(1).value = title
        row.getCell(1).alignment = { vertical: 'middle', horizontal: 'right' }
        row.getCell(1).font = { size: 14, color: { argb: 'FF000000' }, name: 'Arial', family: 2 }
        row.height = 30
        this.days.forEach((day, dayIdx) => {
          let col = row.getCell(2 + dayIdx)
          col.value = this.grid[title][day]
          col.alignment = { vertical: 'middle', horizontal: 'center' }
          col.fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: this.grid[title][day] ? 'FF66BB6A' : 'FFEF534F' } }
          col.border = BORDER
        })
      })

      const status = exportFile(`Completed-${this.configChecklist.name.replace(' ', '')}-export.xlsx`, await workbook.xlsx.writeBuffer())

      if (status !== true) {
        this.$q.notify({
          message: 'Browser denied file download...',
          color: 'negative',
          icon: 'warning',
        })
      }
    },
  },
  watch: {
    configChecklist() {
      if (!this.configChecklist.treeId && this.groupBy === 'Location') this.groupBy = 'User'
    },
  },
  computed: {
    titleText() {
      let count = this.reportData && this.reportData.numChecklists
      return `${count} Checklists`
    },
    days() {
      if (!this.reportData || !this.reportData.filters) return []
      let days = []
      let day = this.$moment(this.reportData.filters.date.from, 'YYYY/MM/DD').startOf('day')
      let lastDay = this.$moment(this.reportData.filters.date.to, 'YYYY/MM/DD').endOf('day')
      this.$log(`starting day ${day.format(DAY)} and last day ${lastDay.format(DAY)}`)
      while (day.isBefore(lastDay)) {
        days.push(day.format(DAY))
        day.add(1, 'd')
      }
      if (this.reverse) return days.reverse()
      return days
    },
    titles() {
      if (!this.grid) return []
      let titles = Object.keys(this.grid).sort()
      return titles
    },
    grid() {
      if (!this.reportData) return {}
      return this.groupBy === 'User' ? this.reportData.gridUser : this.reportData.gridTree
    },
  },
}
</script>

<style lang="css" scoped>
table.grid tbody th,
table.grid thead th {
  position: sticky;
  left: 0;
  z-index: 1;
}
table.grid tbody th,
table.grid thead th {
  background-color: white;
}

.grid-row .yes {
  background-color: #66bb6a;
}
.grid-row .no {
  background-color: #ef534f;
}
</style>
