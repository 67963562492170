<template>
  <q-card flat>
    <q-card-section>
      <div class="sl-chart"></div>
    </q-card-section>
    <q-separator />
    <q-card-actions class="q-px-none q-pb-none" align="right" v-if="!noTransform">
      <q-btn-toggle v-model="chartType" toggle-color="accent" no-caps :options="newOptions" />
    </q-card-actions>
  </q-card>
</template>

<script>
import { generate } from 'c3'
import 'c3/c3.css'

export default {
  props: {
    data: {
      type: Object,
      required: true,
      validator: (o) => Object.values(o).every((v) => typeof v === 'number'),
    },
    colors: {
      type: Object,
      required: false,
    },
    type: {
      type: String,
      default: 'bar',
      validator: (t) => ['line', 'pie', 'bar'].includes(t),
    },
    options: {
      type: Array,
      default: () => ['pie', 'bar'],
      validator: (o) => o.every((v) => ['line', 'pie', 'bar'].includes(v)),
    },
    noTransform: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      chart: null,
      chartType: null,
      //
      icons: {
        bar: 'fas fa-chart-bar',
        line: 'fas fa-chart-line',
        pie: 'fas fa-chart-pie',
      },
    }
  },
  mounted() {
    const self = this
    this.chartType = this.type
    this.$nextTick(() => {
      self.drawChart(self.$el.querySelector('.sl-chart'))
    })
  },
  watch: {
    // since data to this chart could be dynamic, we need to re-draw when it changes. Alternative is to not render chart with v-if outside this component
    data() {
      this.drawChart(this.$el.querySelector('.sl-chart'))
    },
    chartType(to, from) {
      this.transform(to, from)
    },
  },
  computed: {
    newOptions() {
      return this.options.map((opt) => {
        return { label: opt.charAt(0).toUpperCase() + opt.slice(1), value: opt }
      })
    },
    pieData() {
      return {
        data: {
          columns: Object.keys(this.data).map((label) => [label, this.data[label]]),
          type: 'pie',
          colors: this.colors,
        },
      }
    },
    chartData() {
      if (this.chartType === 'pie') return this.pieData
      const { data } = this
      const labels = Object.keys(data).sort((a, b) => data[b] - data[a])
      // Cap at 30 categories and average for now
      const limit = 30
      if (labels.length > limit) {
        data.Others = 0
        for (const label of labels.slice(limit - 1)) {
          data.Others += data[label]
          delete data[label]
        }
        const combined = labels.length - limit
        data[`Other ${combined} Ave`] = data.Others / combined
        delete data.Others
        labels.splice(limit - 1, labels.length - (limit - 1), `Other ${combined} Ave`)
      }
      let currentColors = this.colors
      return {
        data: {
          json: labels.map((label) => {
            return { label, Count: data[label] % 1 ? Number(data[label].toFixed(2)) : data[label] }
          }),
          keys: {
            x: 'label',
            value: ['Count'],
          },
          color(col, d) {
            return currentColors[labels[d.index]]
          },
          type: this.chartType,
        },
        axis: { x: { type: 'category' } },
        legend: { show: false },
      }
    },
  },
  methods: {
    drawChart(bindto) {
      this.chart = generate({
        bindto,
        ...this.chartData,
      })
    },
    transform(to, from) {
      if (this.chartType === from) return
      if ((from === 'bar' && to === 'line') || (from === 'line' && to === 'bar')) {
        this.chart.transform(to)
      } else {
        // TODO: Find a way to transform here without redrawing
        this.drawChart(this.$el.querySelector('.sl-chart'))
      }
    },
  },
}
</script>

<style lang="css"></style>
