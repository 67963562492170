<template lang="html">
  <q-card class="q-pa-sm">
    <div v-if="reportData">
      <div class="flex row q-mb-lg q-mt-sm justify-center" style="height: 40px; min-width: 100%">
        <div class="" style="font-size: 1.2em; line-height: 40px">{{ titleText }}</div>
        <q-space />
        <div v-if="configChecklist.treeId" class="text-right" style="line-height: 40px">List by</div>
        <q-select dense v-if="configChecklist.treeId" class="q-ml-md" style="width: 150px" v-model="groupBy" :options="groupOptions" />
        <div v-if="configChecklist.treeId && groupBy === 'Location'" class="text-right q-ml-md" style="line-height: 40px">Level</div>
        <q-select dense v-if="configChecklist.treeId && groupBy === 'Location'" class="q-ml-xs" style="width: 50px" v-model="levelGroup" :options="levelOptions" />
        <q-btn rounded unelevated class="bg-secondary text-primary text-bold q-ml-md" label="Export to PDF" no-caps @click="$emit('export')" />
      </div>
      <q-card v-for="row in list" :key="row.title" bordered class="q-ma-sm">
        <q-card-section>
          <div class="text-h6">{{ row.title }}</div>
        </q-card-section>
        <q-card-section>
          <q-scroll-area style="height: 400px">
            <div class="row no-wrap">
              <div
                class="flex column col"
                v-for="col in reportData.questionInfo"
                :key="col.label"
                :style="{ 'min-width': row[col.name].itemType === 'options' ? '200px' : '250px' }"
              >
                <div class="self-stretch text-center text-bold">{{ col.label }}</div>
                <div>
                  <chart v-if="row[col.name].itemType === 'options'" type="pie" :data="row[col.name].data" :colors="row[col.name].colors" no-transform></chart>
                  <chart-table v-if="row[col.name].itemType === 'input'" :data="row[col.name].data" :colors="row[col.name].colors"></chart-table>
                </div>
              </div>
            </div>
          </q-scroll-area>
        </q-card-section>
      </q-card>
    </div>
  </q-card>
</template>

<script>
import Chart from './blocks/Chart.vue'
import ChartTable from './blocks/ChartTable.vue'
export default {
  name: 'Grouped',
  props: ['configChecklist', 'reportData'],
  components: { Chart, ChartTable },
  mixins: [],
  data() {
    return {
      groupBy: 'User',
      groupOptions: ['User', 'Location'],
      levelGroup: 1,
      levelOptions: [1, 2, 3, 4, 5],
      showImg: false,
      imgUrl: null,
      pagination: { rowsPerPage: 0, sortBy: 'startedAt', descending: true },
    }
  },
  mounted() {
    this.$log('DetailsReport mounted, building columns with configChecklist ' + this.configChecklist.name)
  },
  methods: {
    gotoUrl(link) {
      window.open(link, '_blank')
    },
  },
  computed: {
    titleText() {
      let count = this.reportData && this.reportData.numChecklists
      return `${count} Checklists`
    },
    columns() {
      let qInfo = {}
      if (this.reportData && this.reportData.questionInfo) qInfo = this.reportData.questionInfo
      return [{ name: 'title', label: 'Group', field: 'title', sortable: true }, { name: 'count', label: '#', field: 'count' }, ...qInfo]
    },
    visibleColumns() {
      return this.columns.reduce((cols, column) => {
        if (column.name === 'tree') {
          if (this.configChecklist.treeId) cols.push(column.name)
        } else if (!column.name.endsWith('comment') && !column.name.endsWith('images')) {
          cols.push(column.name)
        } else if (!column.name.startsWith('~Q')) {
          cols.push(column.name)
        }
        // if (!column.name.startsWith('~Q') && (column.name === 'tree' && this.configChecklist.treeId) || (!column.name.endsWith('comment') && !column.name.endsWith('images')))
        //   cols.push(column.name)
        return cols
      }, [])
    },
    list() {
      if (!this.reportData) return
      if (this.groupBy === 'User') return this.reportData.groupByUser
      switch (this.levelGroup) {
        case 1:
          return this.reportData.groupByLocation1
        case 2:
          return this.reportData.groupByLocation2
        case 3:
          return this.reportData.groupByLocation3
        case 4:
          return this.reportData.groupByLocation4
        case 5:
          return this.reportData.groupByLocation5
        default:
          return null
      }
    },
  },
}
</script>

<style lang="css"></style>
