<template lang="html">
  <q-card class="q-px-sm q-py-sm row justify-between">
    {{ checklists }}
  </q-card>
</template>

<script>
export default {
  name: 'RawDataReport',
  props: ['checklists'],
  data() {
    return {}
  },
  mounted() {
    // this.checkArea()
  },
  methods: {
    // goAreas() {
    //   let name = 'GlobalSpecAreas'
    //   if (this.forProjectId) name = 'ProjectSpecsAreas'
    //   this.$router.push({ name, params: { specId: this.spec.id } })
    // },
    // goList() {
    //   let name = 'GlobalSpecBuildings'
    //   if (this.forProjectId) name = 'ProjectSpecs'
    //   this.$router.push({ name })
    // },
    // async checkArea() {
    //   if (this.buildingId && this.areaId) {
    //     this.area = this.spec.areas.find(area => {
    //       return area.id === parseInt(this.areaId)
    //     })
    //   } else this.area = null
    // }
  },
  computed: {
    // specName() {
    //   return this.spec && this.spec.name
    // },
    // buildingId() {
    //   return parseInt(this.$route.params.specId)
    // },
    // areaId() {
    //   return parseInt(this.$route.params.areaId)
    // }
  },
}
</script>

<style lang="sass"></style>
