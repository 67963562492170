<template>
  <div class="fit column justify-start">
    <div class="bg-white shadow-1 shadow-up-1 q-pa-lg" style="width: 100%; height: 96px">
      <img src="../../assets/simpleCheckBanner.svg" class="" style="cursor: pointer" width="323px" height="48px" @click="openMarketing" />
    </div>
    <div class="q-mt-xl full-width row inline wrap justify-center items-center">
      <img class="lightbulb" src="../../assets/lightbulb.png" width="600px" style="min-width: 350px; margin-bottom: -225px; margin-left: -250px" />
      <div class="fit row wrap justify-center items-center content-start">
        <q-card class="q-pa-lg q-mx-lg" style="min-width: 350px">
          <q-item-label header class="q-pa-none q-mb-lg">Account Information</q-item-label>
          <div v-if="!hasCompany">
            <q-input class="input" maxlength="50" v-model="companyName" placeholder="Company Name" dense outlined />
          </div>
          <div v-else-if="company">
            <q-item-label header class="text-center text-wrap">This company already has subscription information.</q-item-label>
            <q-item-label caption class="text-center"> {{ (isUpgrading ? 'Changing plans for ' : 'Purchasing Subscription for ') + company.name }}</q-item-label>
          </div>
          <div v-if="!isUser">
            <q-input class="input" style="margin-top: 15px" maxlength="50" v-model="fullName" placeholder="Full Name" dense outlined />
            <q-input autocapitalize="off" class="input" style="margin-top: 15px" maxlength="50" v-model="email" placeholder="Primary Account Email" dense outlined />
            <q-input autocapitalize="off" type="password" style="margin-top: 15px" maxlength="50" class="input" v-model="password" placeholder="Password" dense outlined></q-input>
            <q-input
              autocapitalize="off"
              type="password"
              style="margin-top: 15px"
              maxlength="50"
              class="input"
              v-model="confirmPassword"
              placeholder="Confirm Password"
              dense
              outlined
            ></q-input>
          </div>
          <div v-else>
            <q-item-label caption class="text-center q-mt-sm">
              {{ (isUpgrading ? 'Changing plans as ' : 'Purchasing Subscription as ') + $store.state.user.data.email }}</q-item-label
            >
          </div>
          <q-btn v-if="!isUpgrading" :disable="!canAdvance" no-caps unelevated class="buy-button q-mt-md" style="height: 40px; width: 100%" @click="nextIfPasswordMatch(true)"
            >Continue to the Setup Wizard</q-btn
          >
          <q-btn v-if="isUpgrading" no-caps unelevated class="buy-button q-mt-md" style="height: 40px; width: 100%" @click="changePlan">Change Plans</q-btn>
          <q-btn v-if="isUpgrading" no-caps unelevated class="q-mt-md text-accent" style="height: 40px; width: 100%" @click="returnToHome">Return to Simple Check</q-btn>
        </q-card>

        <q-card flat class="q-py-md q-mx-lg col-3 bg-transparent" style="min-width: 350px">
          <q-item-label class="text-bold text-h5 q-pb-md">Try It Free for 30 Days</q-item-label>
          <q-item-label class="text-subtitle1">No Credit Card.</q-item-label>
          <q-item-label class="text-subtitle1">No Commitment.</q-item-label>
          <q-item-label class="text-subtitle1">No Hassle.</q-item-label>
          <q-item-label class="text-italic text-subtitle1">Just Simple.</q-item-label>
          <q-item-label class="q-pt-md text-subtitle1" text-wrap>After 30 days, you won't be billed unless you choose to move forward with a plan that works for you.</q-item-label>
        </q-card>
      </div>
      <!-- bottom spacing -->
      <div class="bg-transparent" style="width: 100%; height: 100px"></div>
    </div>
  </div>
</template>

<script>
import { loadStripe } from '@stripe/stripe-js'

export default {
  name: 'AccountSetup',
  data() {
    return {
      redirecting: false,
      loading: true,
      companyName: '',
      fullName: '',
      email: '',
      password: '',
      confirmPassword: '',
      product: {},
      price: null,
      company: null,
      isUpgrading: false,
    }
  },
  computed: {
    isUser() {
      return this.$store.state.user.loggedIn && this.$store.state.user.data
    },
    hasCompany() {
      return this.$store.state.user.data && this.$store.state.user.data.companies && this.$store.state.user.data.companies.length > 0
    },
    canAdvance() {
      const userComplete = (this.fullName !== '' && this.email !== '' && this.password !== '' && this.confirmPassword !== '') || this.isUser
      const companyComplete = this.companyName !== '' || this.hasCompany
      return userComplete && companyComplete && this.product && this.price
    },
  },
  mounted() {
    if (this.$route.query.setupQuit) {
      this.$gtm.trackEvent({
        event: 'interaction',
        category: 'Purchase',
        action: 'Cancel',
        label: 'Stripe Purchase Canceled',
        value: 0,
        noninteraction: false, // Optional
      })
    }
    this.getProduct()
    if (this.hasCompany) this.getCompany()
  },
  methods: {
    openMarketing() {
      window.open('https://www.mysimplecheck.com/', '_self')
    },
    goToPlans() {
      window.open('https://mysimplecheck.com/pricing', '_self')
    },
    getCompany() {
      this.$firestore
        .collection('companies')
        .doc(this.$store.state.user.data.companies[0])
        .get()
        .then((doc) => {
          this.company = doc.data()
          this.company.companyId = doc.id
          this.getCompanySubscriptions()
        })
    },
    getCompanySubscriptions() {
      this.$firestore
        .collection('companies')
        .doc(this.company.companyId)
        .collection('subscriptions')
        .where('status', 'in', ['trialing', 'active'])
        .get()
        .then(async (snapshot) => {
          if (!snapshot.empty) {
            this.isUpgrading = true
          }
        })
    },
    getProduct() {
      if (this.$route.query.productId) this.getSpecificProduct()
      else this.getBaseProduct()
    },
    async getBaseProduct() {
      // a specific product was not asked for, so get all the products, then find the smallest one
      const productQuerySnapshot = await this.$firestore.collection('products').orderBy('stripe_metadata_checklistLimit', 'asc').limit(1).get()
      if (productQuerySnapshot.empty) {
        this.$log('error defining product, query failed')
      }
      this.$log(productQuerySnapshot)
      this.product = productQuerySnapshot.docs[0].data()
      this.loadPrice(productQuerySnapshot.docs[0].ref)
    },
    getSpecificProduct() {
      const product = this.$firestore.collection('products').doc(this.$route.query.productId)
      product
        .get()
        .then((doc) => {
          this.product = doc.data()
          return this.loadPrice(product)
        })
        .catch(() => {
          this.loading = false
        })
    },
    loadPrice(productDocReference) {
      return productDocReference
        .collection('prices')
        .where('active', '==', true)
        .where('interval', '==', 'month')
        .limit(1)
        .get()
        .then((snapshot) => {
          this.price = snapshot.docs[0].data()
          this.$log('got a price, ready to go ' + JSON.stringify(this.price))
          this.price.id = snapshot.docs[0].id
          this.loading = false
        })
        .catch((err) => {
          this.$log('no price, never will work: ' + err)
          this.loading = false
        })
    },
    returnToHome() {
      this.$router.replace({ name: 'Home' })
    },
    async changePlan() {
      this.redirecting = true
      this.$eventBus.$emit('spinner', true)
      const functionRef = this.$firebase.app().functions('us-central1').httpsCallable('stripe-createUpgradeNowLink')
      const { data } = await functionRef({ companyId: this.company.companyId, returnUrl: window.location.origin + '/' + this.$router.resolve(`/Home?reload=true`).href })
      if (data) window.location.assign(data.url)
      this.$eventBus.$emit('spinner', false)
      this.redirecting = false
    },
    async nextIfPasswordMatch(cardless) {
      if (!!this.password && this.password === this.confirmPassword) {
        this.next(cardless)
      } else if (!!this.password || !!this.confirmPassword) {
        this.$q.notify({
          type: 'negative',
          message: 'Passwords do not match',
        })
      } else {
        // both are blank
        this.$q.notify({
          type: 'negative',
          message: 'Please enter a password',
        })
      }
    },
    async next(cardless) {
      this.redirecting = true
      this.$eventBus.$emit('spinner', true)
      if (!this.price || !this.price.id) return this.$q.notify({ type: 'negative', message: 'No price loaded, checkout cannot proceed.' })
      const user = this.isUser ? this.$store.state.user.data : await this.createUser()
      const company = this.hasCompany ? this.company : await this.createCompany(user)

      if (user && company) {
        this.$log(`User and company -> checkout`)
        this.$log(user)
        this.$log(company)
        this.$eventBus.$emit('spinner', true)
        if (cardless) {
          this.$gtm.trackEvent({
            event: 'sign_up',
            category: 'Purchase Without Card',
            action: 'Continue Without Card',
            label: 'Successful User and Company',
            value: this.price.unit_amount,
            noninteraction: false, // Optional
          })
          try {
            this.cardlessCheckout(company, user)
          } catch (err) {
            this.redirecting = false
          }
        } else {
          this.$gtm.trackEvent({
            event: 'sign_up',
            category: 'Purchase',
            action: 'Continue',
            label: 'Successful User and Company',
            value: this.price.unit_amount,
            noninteraction: false, // Optional
          })
          try {
            this.checkout(user.userId, company) // finish this.redirecting here
          } catch (err) {
            this.redirecting = false
          }
        }
      } else {
        this.redirecting = false
        this.$eventBus.$emit('spinner', false)
        this.$q.notify({
          type: 'error',
          message: 'Error while generating account information. Try again or contact support help@mysmartinspectcom',
          timeout: 5000,
        })
        this.$gtm.trackEvent({
          event: 'interaction',
          category: 'Purchase',
          action: 'Continue',
          label: 'Error Account Info',
          value: this.price.unit_amount,
          noninteraction: false, // Optional
        })
      }
    },
    async createUser() {
      return new Promise((resolve, reject) => {
        var newUser = {
          name: this.fullName,
          email: this.email,
          createdAt: this.$fieldValue.serverTimestamp(),
        }

        this.$firebase
          .auth()
          .createUserWithEmailAndPassword(this.email, this.password)
          .then((user) => {
            this.$log('user: ' + user)
            newUser.userId = user.user.uid
            return this.$firestore
              .collection('users')
              .doc(user.user.uid)
              .set(newUser)
              .then(() => {
                // The set was successful, so this is good
                return resolve(newUser)
              })
          })
          .catch((e) => {
            this.$log('Error creating new user: ' + e)
            if (e.message.includes('already in use')) {
              // this user email already exists. Let's just try to log them in!
              this.$firebase
                .auth()
                .signInWithEmailAndPassword(this.email, this.password)
                .then(async ({ user }) => {
                  await this.$store.dispatch('fetchUser', user)

                  newUser.name = this.$store.state.user.data.displayName
                  newUser.email = this.$store.state.user.data.email
                  newUser.userId = this.$store.state.user.data.id
                  newUser.roles = this.$store.state.user.data.roles
                  newUser.companies = this.$store.state.user.data.companies
                  //however, if the current user is already an account owner, cancel the sign up and just log them in.
                  let isAccountCompany = false
                  if (this.$store.state.user.data && this.$store.state.user.data.roles) {
                    for (let comId in this.$store.state.user.data.roles) {
                      if (this.$store.state.user.data.roles[comId] === 'account') {
                        isAccountCompany = true
                        break
                      }
                    }
                  }
                  if (isAccountCompany) {
                    // sorry, you cannot create more than one account/trial with this email address.
                    this.$q.notify({ type: 'positive', message: 'An account already exists on this email. Logging you in.', timeout: 4000 })
                    reject(new Error('cannot create, already has an account'))
                    return this.$router.replace({ name: 'Home' })
                  }
                  return resolve(newUser)
                })
                .catch((err) => {
                  this.$eventBus.$emit('spinner', false)
                  this.$q.notify({ type: 'negative', message: err.message, timeout: 5000 })
                  reject(err)
                  this.redirecting = false
                })
            } else {
              // unsure what the error was. Just quit and show a message to the user
              this.$eventBus.$emit('spinner', false)
              this.$q.notify({
                type: 'negative',
                message: e.message,
                timeout: 50000,
              })
              reject(e)
              this.redirecting = false
            }
          })
      })
    },
    createCompany(user) {
      this.$log('Creating company with user: ' + JSON.stringify(user, null, 2))
      return new Promise((resolve, reject) => {
        // Generate the company
        var newCompany = {
          name: this.companyName,
          accountEmail: user.email,
          billingInfo: {
            createdAt: this.$fieldValue.serverTimestamp(),
          },
          joinCode: this.$joinCode(),
          active: true, // Useless
          limits: {
            limitReached: false,
            limitReachedOn: null,
            checklistLimit: 1000,
            completedChecklists: 0,
          },
        }

        newCompany.users = [user.userId || user.id]
        newCompany.primaryUser = user.userId || user.id

        var ref = this.$firestore.collection('companies').doc()

        if (user.companies && user.companies.length > 0) user.companies.push(ref.id)
        else user.companies = [ref.id]

        newCompany.companyId = ref.id

        var batch = this.$firestore.batch()

        this.$log('batch setting company: ' + JSON.stringify(newCompany, null, 2))
        batch.set(ref, newCompany)
        this.$log('batch set company')

        var tasks = [
          {
            taskId: 'completeChecklist',
            title: 'Complete & Submit Checklist',
            description: 'After creating your first checklist, complete and submit that checklist using the mobile app.',
            detailLabel: 'Waiting for submission...',
            actionLabel: '',
            status: 'pending',
          },
          {
            taskId: 'createChecklist',
            title: 'Create First Checklist',
            description: 'Create your first checklist from scratch or by using one of our templates.',
            actionLabel: 'Create Checklist',
            status: 'pending',
          },
          {
            taskId: 'invite',
            title: 'Invite Users',
            description: 'Invite users to join your company via a join code.',
            actionLabel: 'Invite Users',
            status: 'pending',
          },
        ]

        tasks.forEach((task) => {
          batch.set(this.$firestore.collection('companies').doc(ref.id).collection('tasks').doc(task.taskId), task)
        })

        if (user.roles) user.roles[ref.id] = 'account'
        else {
          user.roles = {}
          user.roles[ref.id] = 'account'
        }

        batch.set(this.$firestore.collection('users').doc(user.userId || user.id), user)

        batch
          .commit()
          .then(() => {
            resolve(newCompany)
          })
          .catch((e) => {
            this.$log('Error creating the company: ' + e)
            reject(e)
          })
      })
    },
    async checkout(primaryUserId, newCompany) {
      const docRef = await this.$firestore
        .collection('companies')
        .doc(newCompany.companyId)
        .collection('checkout_sessions')
        .add({
          price: this.price.id,
          allow_promotion_codes: true,
          success_url: window.location.origin + '/' + this.$router.resolve(`/Enrolled?setupSuccess=${this.price.unit_amount}`).href,
          cancel_url: window.location.origin + '/' + this.$router.resolve(`/AccountSetup?productId=${this.$route.query.productId}&setupQuit=true`).href,
          metadata: {
            companyName: newCompany.name,
            companyId: newCompany.companyId,
          },
        })
      // Wait for the CheckoutSession to get attached by the extension
      docRef.onSnapshot(async (snap) => {
        const { error, sessionId } = snap.data()
        if (error) {
          alert(`An error occured: ${error.message}`)
          this.redirecting = false
        }
        if (sessionId) {
          // We have a session, let's redirect to Checkout
          // Init Stripe
          const stripe = await loadStripe(process.env.VUE_APP_STRIPE_KEY_PUBLIC)
          stripe.redirectToCheckout({ sessionId })
        }
      })
    },
    async cardlessCheckout(company, newUser) {
      const functionRef = this.$firebase.app().functions('us-central1').httpsCallable('stripe-createCardlessCustomer')
      functionRef({ accountEmail: company.accountEmail, companyId: company.companyId, priceId: this.price.id })
        .then(async (subscription) => {
          this.$log('cardlessCustomer created. Subscription:')
          this.$log(subscription)
          this.$eventBus.$emit('spinner', false)
          this.redirecting = false
          // make sure the user is logged in as an account owner before changing pages
          if (!newUser.uid) newUser.uid = newUser.userId // this makes the object like an auth call when fetching
          this.$log('fetching user')
          await this.$store.dispatch('fetchUser', newUser)
          const user = JSON.parse(JSON.stringify(newUser))
          if (!user.companies) user.companies = []
          if (!user.companies.includes(company.companyId)) user.companies.push(company.companyId)
          this.$store.commit('setUser', user)
          this.$log('fetching copany')
          await this.$store.dispatch('fetchCompany', company.companyId)
          // this is needed separately than in fetch company because stripe might not be done yet (takes 5-10 seconds...)
          // a Page refresh later will pull from Firestore
          this.$store.commit('setSubscription', subscription)
          this.$router.replace('Enrolled')
        })
        .catch((error) => {
          this.$log('Error creating cardless customer and subscription: ' + error)
          this.$eventBus.$emit('spinner', false)
          this.redirecting = false
          this.$q.notify({
            type: 'negative',
            message: error.message,
            timeout: 50000,
          })
        })
    },
  },
  components: {},
}
</script>

<style lang="css" scoped>
.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.input {
  width: 100%;
  height: 40px;
  background-color: white;
  /* margin-top: 30px; */
  font-size: 15px;
}

.full-width {
  width: 100%;
}
.text-muted {
  color: gray;
}

.product-description {
  background-color: rgb(8, 40, 76);
  color: white;
  border-radius: 0;
}

.product-price {
  background-color: rgb(252, 251, 252);
  color: rgb(45, 45, 45);
  border-radius: 0;
}

.product {
  background-color: rgb(252, 251, 252);
  border-radius: 0;
  width: 350px;
  border: 1px solid lightgray;
}

.buy-button {
  background-color: rgb(250, 173, 58);
  color: rgb(10, 35, 66);
  border-radius: 20px;
  font-weight: 600;
}

.lined {
  display: flex;
  flex-direction: row;
}

.lined:before,
.lined:after {
  content: '';
  flex: 1 1;
  border-bottom: 1px solid lightgray;
  margin: auto;
}

@media screen and (max-width: 800px) {
  .lightbulb {
    display: none !important;
  }
}
</style>
