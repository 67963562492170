<template lang="html">
  <div class="flex column self-center items-center" style="min-width: 300px">
    <q-table flat no-data-label="No Recent Checklists Found" :data="recentChecklists" :columns="columns" row-key="checklistId" hide-pagination @row-click="viewChecklist" />
  </div>
</template>

<script>
export default {
  name: 'UserRecentlyCompleted',
  data() {
    return {
      columns: [
        { name: 'name', align: 'left', label: 'Checklist', field: 'name', sortable: true, headerClasses: 'text-primary' },
        { name: 'location', align: 'left', label: 'Location', field: 'readableTreeString', sortable: false },
        { name: 'completedAt', align: 'left', label: 'Completed At', field: 'completedAt', sortable: true, headerClasses: 'text-primary' },
      ],
    }
  },
  computed: {
    currentUser() {
      return this.$store.state.user.data
    },
    recentChecklists() {
      if (!this.currentUser) return []
      var returnChecklist = []
      const historyIn = this.currentUser.history || []
      historyIn.forEach((item) => {
        var checklist = []
        checklist.name = item.name
        checklist.completedAt = this.$options.filters.readableDate(item.completedAt)
        checklist.readableTreeString = item.readableTreeString === '' ? 'No Location' : item.readableTreeString
        checklist.link = window.location.origin + '/' + this.$router.resolve(`/completed/${item.checklistId}`).href
        returnChecklist.push(checklist)
      })
      return returnChecklist
    },
  },
  mounted() {},
  methods: {
    viewChecklist(event, row) {
      this.$log('View Checklist: ' + JSON.stringify(row, null, 2))
      window.open(row.link, '_blank')
    },
  },
}
</script>

<style lang="css"></style>
