<script>
export default {
  methods: {
    uploadFile(file, childPath) {
      return new Promise((resolve, reject) => {
        var uploadTask = this.$firebase.storage().ref().child(childPath).put(file)
        // Listen for state changes, errors, and completion of the upload.
        uploadTask.on(
          'state_changed',
          function (snapshot) {
            // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
            var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100
            console.log('Upload is ' + progress + '% done')
            switch (snapshot.state) {
              case 'paused':
                console.log('Upload is paused')
                break
              case 'running':
                console.log('Upload is running')
                break
            }
          },
          function (error) {
            // A full list of error codes is available at
            // https://firebase.google.com/docs/storage/web/handle-errors
            switch (error.code) {
              case 'storage/unauthorized':
                // User doesn't have permission to access the object
                reject('Unauthorized')
                break

              case 'storage/canceled':
                // User canceled the upload
                reject('Cancelled')
                break

              case 'storage/unknown':
                // Unknown error occurred, inspect error.serverResponse
                reject('Unknown')
                break
            }
          },
          function () {
            // Upload completed successfully, now we can get the download URL
            uploadTask.snapshot.ref.getDownloadURL().then(function (downloadURL) {
              console.log('File available at: ' + downloadURL)
              resolve(downloadURL)
            })
          }
        )
      })
    },
  },
}
</script>
