<template>
  <q-dialog v-model="showing">
    <q-card>
      <q-card-section class="row items-center">
        <span class="q-ml-sm">{{ text }}</span>
      </q-card-section>
      <q-card-section v-if="verificationWord" class="row items-center">
        <span class="q-ml-sm">Type '{{ verificationWord }}' to confirm.<br /></span>
      </q-card-section>
      <q-card-section v-if="verificationWord" class="row items-center">
        <q-input dense flat no-caps v-model="verificationText" autofocus />
      </q-card-section>
      <q-card-actions align="right">
        <q-btn rounded flat no-caps class="text-accent text-bold" :label="cancelText" @click="$emit('cancel')" />
        <q-btn
          :disabled="verificationWord && verificationWord.toLowerCase() !== verificationText.toLowerCase()"
          :label="confirmText"
          rounded
          unelevated
          class="bg-secondary text-primary text-bold"
          no-caps
          @click="$emit('confirm')"
        />
      </q-card-actions>
    </q-card>
  </q-dialog>
</template>

<script>
export default {
  props: {
    text: {
      type: String,
      default: 'Are you sure?',
      required: false,
    },
    confirmText: {
      type: String,
      default: 'Remove',
      required: false,
    },
    cancelText: {
      type: String,
      default: 'Cancel',
      required: false,
    },
    verificationWord: {
      type: String,
      default: null,
      required: false,
    },
  },
  data() {
    return {
      verificationText: '',
      showing: false,
    }
  },
  methods: {
    toggle() {
      this.showing = !this.showing
      if (!this.showing) this.verificationText = ''
    },
  },
}
</script>
