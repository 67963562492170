import { render, staticRenderFns } from "./OptionsEditor.vue?vue&type=template&id=f7668e50&scoped=true&"
import script from "./OptionsEditor.vue?vue&type=script&lang=js&"
export * from "./OptionsEditor.vue?vue&type=script&lang=js&"
import style0 from "./OptionsEditor.vue?vue&type=style&index=0&id=f7668e50&lang=sass&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f7668e50",
  null
  
)

export default component.exports
import QCard from 'quasar/src/components/card/QCard.js';
import QItemLabel from 'quasar/src/components/item/QItemLabel.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QIcon from 'quasar/src/components/icon/QIcon.js';
import QInput from 'quasar/src/components/input/QInput.js';
import QSpace from 'quasar/src/components/space/QSpace.js';
import QCheckbox from 'quasar/src/components/checkbox/QCheckbox.js';
import qInstall from "../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(component, 'components', {QCard,QItemLabel,QBtn,QIcon,QInput,QSpace,QCheckbox});
