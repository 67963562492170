<template lang="html">
  <div class="flex column items-center q-pa-md">
    <!-- template can only contain one element -->
    <q-item-label header class="text-center text-primary text-bold">Download the app to start using Simple Check.</q-item-label>
    <div class="flex column items-center justify q-mt-lg" style="max-width: 270">
      <a :href="$appleDownloadLink">
        <img width="125px;" alt="Download on the App Store" src="../../assets/appstore.svg" />
      </a>
      <a :href="$androidDownloadLink">
        <img
          style="background-color: transparent; width: 150px"
          alt="Get it on Google Play"
          src="https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png"
        />
      </a>
    </div>
  </div>
</template>

<script>
export default {
  name: 'MobileAppBox',
  props: [],
  components: {},
  data() {
    return {}
  },
  computed: {},
  mounted() {},
  methods: {},
}
</script>

<style lang="css"></style>
