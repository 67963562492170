<template>
  <q-page class="flex column q-gutter-y-md q-pa-md">
    <!-- Account Information -->
    <q-card class="q-pa-md flex row justify-between" v-if="company">
      <div class="q-gutter-y-sm col-5">
        <q-toolbar-title class="text-primary">Account Information</q-toolbar-title>
        <q-input outlined v-model="company.name" label="Company Name" stack-label dense />
        <q-input outlined v-model="currentUser.displayName" label="Display Name" stack-label dense />
        <q-input outlined disable v-model="currentUser.email" label="Email" stack-label dense />
        <q-btn unelevated no-caps class="q-mr-sm bg-negative text-white text-bold" rounded label="Logout" @click="$refs.confirmDialog.toggle()" />
        <q-btn unelevated no-caps class="q-mr-sm bg-secondary text-primary text-bold" rounded label="Reset Password" />
      </div>
      <div>
        <JoinLink :company="company" :joinUrl="joinUrl"></JoinLink>
      </div>
    </q-card>

    <!-- Manage Location Hierarchies -->
    <q-card class="col-grow flex column scroll q-gutter-y-md q-pa-md" v-if="company">
      <location-hierarchy-selector :showDelete="true"></location-hierarchy-selector>
    </q-card>

    <!--  Subscription & Usage -->
    <q-card class="q-gutter-y-sm q-pa-md flex row space-between col-6" v-if="subscription && product">
      <div class="col">
        <q-item-label caption>Subscription Plan</q-item-label>
        <q-item-label class="text-h5 text-primary">{{ product && product.name }}</q-item-label>
        <q-item-label caption>{{ product.description }}</q-item-label>

        <q-list separator>
          <q-item class="q-pa-none line-item" v-if="price">
            <q-item-section>Cost</q-item-section>
            <q-item-section side>{{ $options.filters.price(price.unit_amount) }}/{{ price.interval }}</q-item-section>
          </q-item>
          <q-item class="q-pa-none line-item">
            <q-item-section>Status</q-item-section>
            <q-item-section side>{{ capitalizeFirstLetter(subscription.status) }}</q-item-section>
          </q-item>
          <q-item class="q-pa-none line-item" v-if="subscription.trial_end">
            <q-item-section>Free Trial End Date</q-item-section>
            <q-item-section side>{{ $options.filters.readableDate(subscription.trial_end, true) }}</q-item-section>
          </q-item>
          <q-item class="q-pa-none line-item">
            <q-item-section>Next Billing Date</q-item-section>
            <q-item-section side>{{ $options.filters.readableDate(subscription.current_period_end, true) }}</q-item-section>
          </q-item>
          <q-item class="q-pa-none line-item">
            <q-item-section>Subscription Created</q-item-section>
            <q-item-section side>{{ $options.filters.readableDate(subscription.created, true) }}</q-item-section>
          </q-item>
        </q-list>
        <q-btn
          v-if="subscription.status !== 'trialing' && subscription.status !== 'active'"
          unelevated
          no-caps
          rounded
          class="float-left bg-secondary text-primary text-bold"
          label="Activate New Subscription"
          @click="reactivateSubscription"
        >
          <q-tooltip max-width="350px"> Reactivate your plan. Clicking this button will redirect to our billing platform to manage your subscription. </q-tooltip>
        </q-btn>
        <q-btn v-else unelevated no-caps rounded class="float-left bg-secondary text-primary text-bold" label="Manage Subscription" @click="manageSubscription">
          <q-tooltip max-width="350px">
            Change plan, payment method, cancel subscription, or view past invoices. Clicking this button will redirect to our billing platform to manage your subscription.
          </q-tooltip>
        </q-btn>
      </div>
      <div class="col-1"></div>
      <UsageMeter class="col-auto" :company="company"></UsageMeter>
    </q-card>
    <q-card class="q-pa-md flex column" v-if="!subscription">
      <q-item-label header class="text-center">No subscription found. Purchase a subscription in order to view reports.</q-item-label>
      <q-btn unelevated no-caps rounded class="q-mt-sm text-primary text-bold bg-secondary" size="md" label="View Plans" @click="viewPlans"></q-btn>
    </q-card>
    <sc-confirm ref="confirmDialog" @cancel="$refs.confirmDialog.toggle()" @confirm="logout" text="Are you sure you would like to logout?" confirmText="Logout"></sc-confirm>
  </q-page>
</template>
<script>
import { loadStripe } from '@stripe/stripe-js'
import UsageMeter from '../blocks/UsageMeter.vue'
import JoinLink from '../blocks/JoinLink.vue'
import { mapGetters } from 'vuex'
import LocationHierarchySelector from '../Tree/LocationHierarchySelector.vue'
export default {
  name: 'Account',
  props: ['company'],
  components: { UsageMeter, JoinLink, LocationHierarchySelector },
  data() {
    return {}
  },
  computed: {
    joinUrl() {
      return window.location.origin + '/' + this.$router.resolve(`/Login?newSignup=true&joinCode=${this.company.joinCode}`).href
    },
    currentUser() {
      return this.$store.state.user.data
    },
    ...mapGetters(['subscription', 'product', 'price']),
  },
  mounted() {},
  methods: {
    getSubscription() {
      this.$firestore
        .collection('companies')
        .doc(this.$store.getters.accountCompanies[0])
        .collection('subscriptions')
        .where('status', 'in', ['trialing', 'active'])
        .get()
        .then(async (snapshot) => {
          if (snapshot.empty) {
            this.$log(`Subscription was empty for company ${this.$store.getters.accountCompanies[0]}. Setting to null.`)
            this.subscription = null
          } else {
            // In this implementation we only expect one active or trialing subscription to exist.
            const subscription = snapshot.docs[0].data()
            this.$log(subscription)
            this.subscription = subscription
            this.getProduct()
          }
        })
    },
    async getProduct() {
      this.product = (await this.subscription.product.get()).data()
      this.price = (await this.subscription.price.get()).data()
    },
    async manageSubscription() {
      this.$q.loading.show({
        backgroundColor: 'white',
        spinnerColor: 'primary',
      })
      const functionRef = this.$firebase.app().functions('us-central1').httpsCallable('stripe-createPortalLink')
      const { data } = await functionRef({ companyId: this.company.companyId, returnUrl: window.location.origin + '/' + this.$router.resolve(`/Home?reload=true`).href })
      this.$q.loading.hide()
      if (data) window.location.assign(data.url)
    },
    async reactivateSubscription() {
      this.$q.loading.show({
        backgroundColor: 'white',
        spinnerColor: 'primary',
      })
      // it's possible the price of the previous account was not active. Find the base price again
      // a specific product was not asked for, so get all the products, then find the smallest one
      const productQuerySnapshot = await this.$firestore.collection('products').orderBy('stripe_metadata_checklistLimit', 'asc').limit(1).get()
      if (productQuerySnapshot.empty) {
        this.$log('error defining product, query failed')
      }
      this.$log(productQuerySnapshot)
      this.$log('got a price, ready to go ' + JSON.stringify(this.price))
      let pricesSnapshot = await productQuerySnapshot.docs[0].ref.collection('prices').where('active', '==', true).where('interval', '==', 'month').limit(1).get()
      let priceId = pricesSnapshot.docs[0].id

      const docRef = await this.$firestore
        .collection('companies')
        .doc(this.company.companyId)
        .collection('checkout_sessions')
        .add({
          price: priceId,
          allow_promotion_codes: false,
          success_url: window.location.origin + '/' + this.$router.resolve(`/Account`).href,
          cancel_url: window.location.origin + '/' + this.$router.resolve(`/Account`).href,
          metadata: {
            companyName: this.company.name,
            companyId: this.company.companyId,
            isRenew: true,
          },
        })
      // Wait for the CheckoutSession to get attached by the extension
      docRef.onSnapshot(async (snap) => {
        const { error, sessionId } = snap.data()
        if (error) {
          alert(`An error occured: ${error.message}`)
          this.redirecting = false
        }
        if (sessionId) {
          // We have a session, let's redirect to Checkout
          // Init Stripe
          const stripe = await loadStripe(process.env.VUE_APP_STRIPE_KEY_PUBLIC)
          stripe.redirectToCheckout({ sessionId })
        }
      })
      this.$q.loading.hide()
    },
    logout() {
      this.$firebase
        .auth()
        .signOut()
        .then(() => {
          this.$router.replace({ name: 'Login' })
          this.$q.notify({
            type: 'positive',
            message: 'Logged Out Successfully',
          })
        })
    },
    capitalizeFirstLetter(string) {
      return string.charAt(0).toUpperCase() + string.slice(1)
    },
    viewPlans() {
      this.$log('view plans')
      window.open('https://mysimplecheck.com/pricing/', '_self')
    },
  },
}
</script>
<style lang="css">
.line-item {
  max-height: 25px;
}
</style>
