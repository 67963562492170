<template>
  <q-dialog v-model="showing" @hide="$emit('close', tree.id)">
    <tree-editor style="max-height: 90vh; height: 90vh; min-width: 50vw; width: 85vw" v-if="showing" :selectedTree="tree" @save="saveCurrentTree"></tree-editor>
  </q-dialog>
</template>

<style></style>

<script>
import TreeEditor from './TreeEditor'
export default {
  name: 'TreeModal',
  components: { TreeEditor },
  props: {
    tree: {
      type: Object,
      required: false,
    },
  },
  data() {
    return {
      showing: false,
      locationType: 'all',
    }
  },
  computed: {},
  mounted() {},
  methods: {
    async saveCurrentTree() {
      this.$log('saving current tree')
      this.$eventBus.$emit('spinner')
      await this.$firestore
        .collection('trees')
        .doc(this.tree.id)
        .set(
          {
            root: JSON.stringify(this.tree.root),
            name: this.tree.name,
          },
          { merge: true }
        )
      this.$eventBus.$emit('spinner', false)
    },
    toggle() {
      this.showing = !this.showing
    },
  },
}
</script>
<style lang="sass">
.checklist-card
  width: 600px
  height: 750px
</style>
