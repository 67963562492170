<script>
export default {
  data() {
    return {
      originalChecklist: null,
      checklist: null,
    }
  },
  methods: {
    async getChecklist(checklistId, collectionName = 'checklists') {
      if (!checklistId) checklistId = this.$route.params.checklistId
      const doc = await this.$firestore.collection(collectionName).doc(checklistId).get()
      if (doc.exists) {
        this.checklist = doc.data()
        this.checklist.id = checklistId
        this.originalChecklist = JSON.parse(JSON.stringify(this.checklist))
      } else {
        this.$q.notify({
          type: 'negative',
          message: 'Checklist Not Found',
        })
      }
    },
    updateChecklist() {
      this.$firestore
        .collection('configChecklists')
        .doc(this.checklist.id)
        .update(this.checklist)
        .then(() => {
          this.originalChecklist = this.checklist
          this.$q.notify({
            type: 'positive',
            message: 'Checklist Updated',
          })
        })
        .catch((error) => {
          this.$log('error updating checklist: ' + error)
          this.$q.notify({
            type: 'negative',
            message: 'Checklist failed to update, please try again. ' + error,
          })
        })
    },
  },
  computed: {
    hasEdits() {
      return JSON.stringify(this.originalChecklist) !== JSON.stringify(this.checklist)
    },
    summary() {
      return this.projectSummary(this.clonedProject)
    },
  },
}
</script>
