<template>
  <q-page class="flex flex-center">
    <q-card
      flat
      class="q-gutter-y-sm flex column flex-center justify-between"
      :class="{ 'q-pa-xl': !$q.platform.is.mobile, 'q-pa-xs': $q.platform.is.mobile }"
      style="height: 100%"
    >
      <div v-if="!$q.platform.is.mobile" class="text-h5">Thanks for enrolling in Simple Check, let's get you started in just 4 easy steps.</div>
      <div v-else class="text-subtitle1 q-mx-sm">Thanks for enrolling in Simple Check, let's get you started in just 4 easy steps.</div>
      <div class="q-pa-md">
        <q-stepper v-model="step" ref="stepper" color="primary" animated header-nav :vertical="$q.platform.is.mobile">
          <!-- Step 1 -->
          <q-step :name="1" title="Create a Checklist" icon="fa fa-plus" :done="step > 1" :header-nav="step > 1">
            <q-item-label class="q-mb-md"> Create a new checklist by selecting one of the templates below. Don't worry, you can modify it or create your own later. </q-item-label>
            <!-- Select template -->
            <div class="flex justify-center q-my-xl" :class="{ column: $q.platform.is.mobile, 'q-gutter-y-sm': $q.platform.is.mobile }">
              <q-select
                dense
                style="width: 75%"
                outlined
                :value="selectedTemplate ? selectedTemplate.label : ''"
                @input="selectedTemplate = $event"
                :options="templates"
                label="Checklist Template"
                map-options
                v-if="templates && !firstChecklistCreated"
              >
                <template v-slot:option="scope">
                  <q-item v-bind="scope.itemProps" v-on="scope.itemEvents">
                    <q-item-section>
                      <q-item-label v-html="scope.opt.label" />
                      <q-item-label caption>{{ scope.opt.description }}</q-item-label>
                    </q-item-section>
                  </q-item>
                </template>
              </q-select>
              <q-btn
                v-if="!firstChecklistCreated"
                no-caps
                rounded
                unelevated
                class="bg-secondary text-primary text-bold q-ml-md"
                label="Create Checklist"
                @click="createChecklist"
                :disable="!selectedTemplate.value"
              />
              <q-chip v-if="firstChecklistCreated" color="green" text-color="white"> Checklist Created Succesfully! </q-chip>
            </div>
          </q-step>

          <!-- Step 2 -->
          <q-step :name="2" title="Download the App" icon="fa fa-download" :done="step > 2" :header-nav="step > 2">
            <q-card v-if="$q.platform.is.mobile" class="q-pa-md flex row items-center justify-center" style="height: 340px">
              <q-item-label class="q-ma-md text-center" text-wrap>
                Use Simple Check on your smart phone by downloading the app. Just tap the button below to download.
              </q-item-label>
              <a :href="dynamicLoginLink" target="_blank">
                <q-btn no-caps rounded unelevated class="bg-secondary text-primary text-bold q-ml-md" label="Download" />
              </a>
            </q-card>
            <div v-else>
              <q-item-label class="q-mb-md">
                Get the most out of Simple Check by downloading the app on your smart phone. Just scan your personal QR code below with your mobile device's camera to download the
                app and be automatically logged in.
              </q-item-label>
              <div class="flex justify-center q-mt-md">
                <img style="height: 300px" src="../../assets/download.png" />
                <div class="flex column q-ml-md">
                  <QRCodeBox size="200" v-if="dynamicLoginLink" :url="dynamicLoginLink"></QRCodeBox>
                  <label caption style="width: 200px" text-wrap class="text-muted text-center text-h7 q-mt-md">This is a personal QR code, do not share it with others.</label>
                </div>
              </div>
            </div>
          </q-step>

          <!-- Step 3 -->
          <q-step :name="3" title="Complete Your First Checklist" icon="fa fa-tasks" :done="step > 3" :header-nav="step > 3">
            Perform your newly created {{ selectedTemplate.label }} checklist on your device by scanning this QR code with the app, or by tapping the Start Checklist button in the
            app. We'll wait for you!
            <div class="flex row justify-center q-my-xl">
              <QRCodeBox v-if="newChecklistId" style="width: 200px; height: 200px" size="200" ref="qrCodeBox" :url="newUrl"></QRCodeBox>
              <q-card class="q-pa-lg q-ml-md flex column justify-center items-center">
                <q-linear-progress v-if="!checklistWasPerformed" indeterminate color="primary" />
                <q-chip v-else color="green" text-color="white"> Checklist Completed Succesfully! </q-chip>
                <q-item-label class="q-my-md" caption>{{ checklistStatus }}</q-item-label>
                <q-btn
                  v-if="checklistWasPerformed"
                  no-caps
                  rounded
                  unelevated
                  class="bg-secondary text-primary text-bold q-ml-md"
                  label="View Completed Checklist"
                  @click="viewTutorialChecklist"
                />
              </q-card>
            </div>
          </q-step>

          <!-- Step 4 -->
          <q-step :name="4" title="Invite Users" icon="fa fa-users" :header-nav="step > 4">
            Optionally invite members of your team to your company at no additional cost simply by sending them the join link using the share buttons below.
            <div class="flex row justify-center q-my-xl">
              <q-card v-if="company && company.joinCode" class="q-pa-md" flex row style="height: 340px">
                <JoinLink :company="company" :joinUrl="joinUrl" isTutorial="true"></JoinLink>
              </q-card>
            </div>
          </q-step>

          <!-- Stepper Navigation -->
          <template v-slot:navigation>
            <q-stepper-navigation class="flex justify-end">
              <q-btn v-if="step > 1" flat color="primary" @click="$refs.stepper.previous()" label="Back" class="q-mr-sm" no-caps />
              <q-btn
                no-caps
                rounded
                unelevated
                class="bg-secondary text-primary text-bold"
                :disable="isNextButtonDisabled"
                :label="step === 4 ? 'Done' : 'Next'"
                @click="nextClicked"
              />
            </q-stepper-navigation>
          </template>
        </q-stepper>
      </div>
    </q-card>
  </q-page>
</template>

<style></style>

<script>
import CopyConfigChecklistMixin from '../mixins/CopyConfigChecklistMixin.vue'
import JoinLink from '../blocks/JoinLink.vue'
import QRCodeBox from '../Checklists/blocks/QRCodeBox.vue'
import QrMixin from '../mixins/QrMixin.vue'
import DynamicLinks from '../mixins/DynamicLinks.vue'

export default {
  name: 'Enrolled',
  mixins: [CopyConfigChecklistMixin, QrMixin, DynamicLinks],
  props: ['company'],
  components: { JoinLink, QRCodeBox },
  data() {
    return {
      items: [],
      step: 1,
      options: [],
      selectedTemplate: {},
      firstChecklistCreated: false,
      checklistStatus: '',
      tutorialChecklist: null,
      newChecklistId: null,
      dynamicLoginLink: null,
    }
  },
  computed: {
    templates() {
      const temps = this.$store.state.templates.checklists || []
      return temps.map((temp) => {
        return {
          label: temp.name,
          description: temp.description,
          value: temp.id,
          icon: '',
        }
      })
    },
    isNextButtonDisabled() {
      if (this.step === 1) return !this.firstChecklistCreated
      if (this.step === 2) return false
      if (this.step === 3) return !this.checklistWasPerformed
      if (this.step === 4) return false
      return false
    },
    checklistWasPerformed() {
      return this.checklistStatus === 'You have completed the checklist!'
    },
    joinUrl() {
      return window.location.origin + '/' + this.$router.resolve(`/Login?newSignup=true&joinCode=${this.company.joinCode}`).href
    },
    newUrl() {
      return this.genUrl(this.newChecklistId, '')
    },
  },
  async mounted() {
    await this.$store.dispatch('loadTemplateChecklists')
    let checklists = await this.$firestore.collection('configChecklists').where('companyId', 'in', this.$store.getters.accountCompanies).where('deletedAt', '==', false).get()
    if (checklists.size) {
      let defaultChecklist = checklists.docs[0].data()
      this.newChecklistId = checklists.docs[0].id
      this.firstChecklistCreated = true
      this.checklistStatus = 'Waiting for you to start the ' + defaultChecklist.name + ' checklist...'
    }
    if (this.$route.query.setupSuccess) {
      this.$gtm.trackEvent({
        event: 'purchase',
        category: 'Purchase',
        action: 'Complete',
        label: 'Stripe Purchase Complete',
        value: this.$route.query.setupSuccess,
        noninteraction: false, // Optional
      })
      setTimeout(() => {
        location.replace(window.location.origin + '/' + this.$router.resolve(`/Home`).href)
        location.reload()
      }, 5000)
    }
  },
  methods: {
    async nextClicked() {
      if (this.step === 1) this.dynamicLoginLink = await this.getDynamicLoginLink()
      if (this.step === 4) this.done()
      else this.$refs.stepper.next()
    },
    async createChecklist() {
      this.$eventBus.$emit('spinner')
      this.newChecklistId = await this.copyConfigChecklist(this.selectedTemplate.value)
      this.$firestore.collection('companies').doc(this.$store.state.user.data.companies[0]).collection('tasks').doc('createChecklist').update({ status: 'complete' })
      this.$eventBus.$emit('spinner', false)
      this.firstChecklistCreated = true
      this.checklistStatus = 'Waiting for you to start the ' + this.selectedTemplate.label + ' checklist...'
      this.startWatchingChecklist()
    },
    startWatchingChecklist() {
      this.$firestore
        .collection('checklists')
        .where('configChecklistId', '==', this.newChecklistId)
        .onSnapshot((qs) => {
          qs.docChanges().forEach((change) => {
            if (change.type === 'added') {
              this.checklistStatus = 'Waiting for the completion of the checklist...'
            }
            if (change.type === 'modified') {
              const checklist = change.doc.data()
              if (checklist.status === 'complete') {
                this.checklistStatus = 'You have completed the checklist!'
                this.tutorialChecklist = checklist
              }
            }
          })
        })
    },
    viewTutorialChecklist() {
      const url = window.location.origin + '/' + this.$router.resolve(`/completed/${this.tutorialChecklist.checklistId}`).href
      window.open(url, '_blank')
    },
    done() {
      this.$router.replace({ name: 'Home' })
    },
  },
}
</script>
