<template>
  <q-page class="flex flex-start">
    <div class="q-pa-lg" style="width: 100%; height: 100%">
      <div class="q-ma-md">These users have accepted your join code, which can be found in the Account page.</div>
      <q-table binary-state-sort class="table" :data="usersToShow" :columns="columns" row-key="id" :selected.sync="selected" hide-bottom :pagination="pagination">
        <!--selection="multiple"-->
        <template v-slot:top>
          <div class="full-width">
            <div class="flex justify-between">
              <sc-search style="width: 40%" myPlaceHolder="Search Users" @termUpdated="searchUpdated($event)"></sc-search>
              <!-- <div class="q-gutter-x-sm">
                <q-btn unelevated no-caps color="negative" v-if="selected.length !== 0" size="md" label="Delete Selected" @click="$refs.deleteDialog.toggle()" />
                <q-btn unelevated no-caps class="q-mr-sm" color="primary" size="md" label="New Checklist" @click="$refs.checklistModal.toggle()" />
              </div> -->
            </div>
          </div>
        </template>
        <template v-slot:body-cell-actions="props">
          <q-td :props="props">
            <q-btn v-if="notPrimary(props.row)" flat class="self-center text-grey-7" style="cursor: pointer; font-size: 13px; width: 40px" icon="fas fa-ellipsis-v" @click.stop>
              <q-menu transition-show="jump-down" transition-hide="jump-up">
                <q-list style="min-width: 100px">
                  <q-item clickable v-if="!isAccount(props.row)">
                    <q-item-section @click="promoteUser(props.row.userId)">Promote to Account</q-item-section>
                    <q-tooltip>Grant this user access to the entire account. Create and edit checklists, view reports, manage users. </q-tooltip>
                  </q-item>
                  <q-item clickable v-if="isAccount(props.row)">
                    <q-item-section @click="demoteUser(props.row.userId)">Remove Account Access</q-item-section>
                    <q-tooltip>User will no longer have acess to edit checklists or view reports. Checklists will still listed in their mobile app.</q-tooltip>
                  </q-item>
                  <q-separator />
                  <q-item clickable>
                    <q-item-section style="color: darkred" @click="removeUserFromCompany(props.row.userId)">Remove from company</q-item-section>
                    <q-tooltip>This user will no longer see this company's checklists listed in their mobile app.</q-tooltip>
                  </q-item>
                </q-list>
              </q-menu>
            </q-btn>
            <div v-else>Owner</div>
          </q-td>
        </template>
        <!-- <template v-slot:body-cell="props">
          <q-td :key="props.row.name">
            <label class="cursor-pointer" @click="viewChecklist(props.row.id)">{{ props.row.name }}</label>
          </q-td>
        </template> -->
        <template v-slot:no-data>
          <label>No Users Available</label>
        </template>
      </q-table>
      <sc-confirm
        ref="deleteDialog"
        verificationWord="Delete"
        confirmText="Delete"
        @cancel="$refs.deleteDialog.toggle()"
        @confirm="deleteChecklists()"
        :text="
          'Are you sure you would like to delete the selected ' + (selected.length === 1 ? 'checklist' : 'checklists') + '? This cannot be undone. Type \'Delete\' to confirm.'
        "
      ></sc-confirm>
    </div>
  </q-page>
</template>

<script>
export default {
  name: 'Users',
  components: {},
  props: ['company'],
  data() {
    return {
      users: [],
      columns: [
        { name: 'name', align: 'left', label: 'Display Name', field: 'name', sortable: true },
        { name: 'email', align: 'left', label: 'Email', field: 'email', sortable: true },
        { name: 'Role', align: 'left', label: 'Role', field: (row) => (row.roles && row.roles[this.company.id] === 'account' ? 'Account' : '---') },
        { name: 'actions', align: 'right', label: 'Actions', field: 'notdisplayedhere' },
      ],
      selected: [],
      searchTerm: '',
      usersMatchingTerm: [],
      pagination: {
        rowsPerPage: 50,
        sortBy: 'name',
        descending: false,
      },
    }
  },
  computed: {
    usersToShow() {
      return !this.searchTerm || this.searchTerm.length === 0 ? this.users : this.usersMatchingTerm
    },
  },
  mounted() {
    // company will be guanteed by mounted
    this.getUsers()
  },
  methods: {
    getUsers() {
      this.$log(`getting users for companyId ${this.company.id}`)
      this.company.users.forEach(this.upsertUser)
    },
    async upsertUser(userId) {
      let userSnap = await this.$firestore.collection('users').doc(userId).get()
      let user = userSnap.data()
      user.id = userSnap.id
      // gets the user and inserts into users array, removing previous extra first if present
      let idx = this.users.findIndex((u) => u.id === userId)
      if (idx > -1) this.users.splice(idx, 1)
      this.users.push(user)
    },
    isAccount(user) {
      return user.roles && user.roles[this.company.id] === 'account'
    },
    notPrimary(user) {
      // returns true if given user is not the company primaryUser
      return user.userId !== this.company.primaryUser
    },
    async promoteUser(userId) {
      this.$eventBus.$emit('spinner')
      var addMessage = this.$functions.httpsCallable('promoteUserInCompany')
      this.$log(`trying to promote ${userId} in company ${this.company.id}`)
      let {
        data: { success, message },
      } = await addMessage(JSON.stringify({ userId: userId, companyId: this.company.id }))
      this.$q.notify({ type: success ? 'positive' : 'negative', message })
      this.$log(`success='${success}' message='${message}'`)
      await this.upsertUser(userId)
      this.$eventBus.$emit('spinner', false)
    },
    async demoteUser(userId) {
      this.$eventBus.$emit('spinner')
      this.$log(`trying to demote ${userId} in company ${this.company.id}`)
      var addMessage = this.$functions.httpsCallable('demoteUserInCompany')
      let {
        data: { success, message },
      } = await addMessage(JSON.stringify({ userId: userId, companyId: this.company.id }))
      this.$q.notify({ type: success ? 'positive' : 'negative', message })
      this.$log(`success='${success}' message='${message}'`)
      await this.upsertUser(userId)
      this.$eventBus.$emit('spinner', false)
    },
    async removeUserFromCompany(userId) {
      this.$eventBus.$emit('spinner')
      this.$log(`trying to remove ${userId} from company ${this.company.id}`)
      var addMessage = this.$functions.httpsCallable('removeUserFromCompany')
      let {
        data: { success, message },
      } = await addMessage(JSON.stringify({ userId: userId, companyId: this.company.id }))
      this.$q.notify({ type: success ? 'positive' : 'negative', message })
      this.$log(`success='${success}' message='${message}'`)
      if (success) {
        let idx = this.users.findIndex((u) => u.id === userId)
        if (idx > -1) this.users.splice(idx, 1)
      }
      this.$eventBus.$emit('spinner', false)
    },
    searchUpdated(term) {
      this.usersMatchingTerm = []
      this.searchTerm = term
      const search = this.searchTerm ? this.searchTerm.toLowerCase() : ''
      this.users.forEach((u) => {
        if (u.name.toLowerCase().includes(search) || u.email.toLowerCase().includes(search)) {
          this.usersMatchingTerm.push(u)
        }
      })
    },
  },
}
</script>
