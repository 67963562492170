<template>
  <q-dialog v-model="showing" :persistent="hasEdits">
    <q-card class="checklist-card" v-if="showing">
      <q-card-section class="q-gutter-md">
        <div class="col text-h6">
          {{ title }}
        </div>
        <q-input v-model="newTree.name" placeholder="Location Hierarchy Name" dense maxlength="40" />
        <q-input v-model="newTree.description" placeholder="Description" dense maxlength="120" />
      </q-card-section>
      <q-card-actions class="absolute-bottom" align="right">
        <q-btn rounded flat no-caps color="negative" label="Cancel" @click="confirmCancel" />
        <q-btn rounded unelevated no-caps class="bg-secondary text-primary text-bold" :disabled="!hasEdits" label="Create Tree" @click="createTree" />
      </q-card-actions>
    </q-card>
    <sc-confirm
      ref="confirmDialog"
      @cancel="$refs.confirmDialog.toggle()"
      @confirm="cancel"
      text="Are you sure you would like to discard your changes?"
      cancelText="Back"
      confirmText="Discard Changes"
    ></sc-confirm>
  </q-dialog>
</template>

<style></style>

<script>
export default {
  name: 'TreeModal',
  props: {},
  data() {
    return {
      showing: false,
      originalTree: {},
      newTree: {},
      locationType: 'all',
      isEditing: false,
    }
  },
  computed: {
    title() {
      return this.isEditing ? 'Edit - ' + this.originalChecklist.name : 'New Location Hierarchy'
    },
    hasEdits() {
      return JSON.stringify(this.originalTree) !== JSON.stringify(this.newTree)
    },
  },
  mounted() {},
  methods: {
    toggle() {
      this.isEditing = false
      this.newTree = {
        companyId: this.$store.getters.accountCompanies[0],
        root: JSON.stringify([]),
      }

      this.originalTree = JSON.parse(JSON.stringify(this.newTree))
      this.showing = !this.showing
    },
    createTree() {
      this.$log('New tree:' + JSON.stringify(this.newTree, null, 2))
      const id = this.$firestore.collection('trees').doc().id
      this.$firestore
        .collection('trees')
        .doc(id)
        .set(this.newTree)
        .then(() => {
          this.$refs.confirmDialog.toggle()
          this.showing = false
          this.$emit('close', id)
          this.$q.notify({
            type: 'positive',
            message: this.newTree.name + ' Created',
          })
        })
    },
    confirmCancel() {
      if (this.hasEdits) this.$refs.confirmDialog.toggle()
      else this.showing = false
    },
    cancel() {
      this.$refs.confirmDialog.toggle()
      this.showing = false
    },
  },
}
</script>
<style lang="sass">
.checklist-card
  width: 600px
  height: 750px
</style>
