<template>
  <q-card flat>
    <q-card-section class="flex column" :style="{ padding: condensed ? '0px' : '' }">
      <QRCodeBox v-if="!readOnly && !condensed" style="width: 200px; height: 200px" size="200" ref="qrCodeBox" :url="newUrl"></QRCodeBox>
      <div class="flex column">
        <q-item-label caption class="q-my-sm" v-if="!readOnly && !condensed && treeNodes.length">'Generate a QR code with specific Location information'</q-item-label>
        <div class="flex row no-wrap">
          <q-breadcrumbs :activeColor="readOnly ? 'black' : 'blue'" :color="readOnly ? 'black' : 'blue'" :gutter="condensed ? 'none' : 'sm'">
            <div class="flex row" slot="default" v-for="(node, idx) in treeNodes" :key="node.id" @click="reduceTo(node.id)">
              <q-card flat :bordered="!readOnly" :class="{ flex: true, 'q-px-sm': condensed, 'q-px-md': !condensed }" style="height: 40px">
                <q-item-label :class="{ 'tree-chooser-link': idx !== treeNodes.length - 1 && !readOnly }" class="self-center">{{ node.label || node.name }}</q-item-label>
              </q-card>
              <q-icon
                v-if="(node.children && !readOnly) || idx !== treeNodes.length - 1 || (node.root && !readOnly)"
                name="fas fa-chevron-right"
                color="grey"
                :class="{ 'self-center': true, 'q-mx-sm': !condensed }"
              ></q-icon>
            </div>
          </q-breadcrumbs>
          <q-select
            style="width: 200px"
            v-if="!readOnly && options && options.length"
            outlined
            option-label="label"
            option-value="id"
            :value="nextId"
            @input="selectedNextNode($event)"
            :options="options"
            label="Next"
            map-options
            dense
            :class="{ 'q-ml-sm': !condensed }"
          >
            <template v-slot:option="scope">
              <q-item v-bind="scope.itemProps" v-on="scope.itemEvents">
                <div class="flex row full-width justify-between">
                  <q-item-label class="self-center" v-html="scope.opt.label" />
                  <q-icon class="self-center" v-if="scope.opt.children" name="fas fa-chevron-right" color="grey"></q-icon>
                </div>
              </q-item>
            </template>
          </q-select>
        </div>
      </div>
    </q-card-section>
  </q-card>
</template>
<script>
const SEPARATOR = '~~~'
import QRCodeBox from './QRCodeBox.vue'
import QrMixin from '../../mixins/QrMixin.vue'
export default {
  name: 'TreeChooser',
  components: { QRCodeBox },
  mixins: [QrMixin],
  props: {
    tree: {
      type: Object,
      required: true,
    },
    value: {
      type: String,
      required: true,
    },
    readOnly: {
      type: Boolean,
      required: false,
      default: false,
    },
    condensed: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      nextId: null,
      treePath: '',
    }
  },
  methods: {
    selectedNextNode(nextNode) {
      // this.$log(`selectedNextNode ${JSON.stringify(nextNode)}`)
      let treepath = this.treeNodes
        .slice(1)
        .map((node) => node.id)
        .join(SEPARATOR)
      if (this.treeNodes.length > 1) treepath += SEPARATOR
      treepath += nextNode.id
      this.treePath = treepath
      // this.$log(`treepath='${treepath}' selectedNextNode ${JSON.stringify(nextNode)}`)
      this.$emit('input', treepath)
    },
    reduceTo(reduceToId) {
      let reduceIdx = this.treeNodes.findIndex((node) => node.id === reduceToId)
      // this.$log(`looking for ${reduceToId} in treeNodes and found index ${reduceIdx}`)
      if (reduceIdx === -1) {
        console.error(`Could not reduce current tree to ${reduceToId}, it was not in the current value`)
        return
      }
      let treepath = this.treeNodes
        .slice(0, reduceIdx + 1) //slice end is before 2nd index, and we want it included
        .slice(1) //always drop the root
        .map((node) => node.id)
        .join(SEPARATOR)
      this.treePath = treepath
      this.$emit('input', treepath)
    },
  },
  computed: {
    newUrl() {
      return this.genUrl(this.$route.params.checklistId, this.treePath)
    },
    nodeIds() {
      return this.value.split(SEPARATOR)
    },
    treeNodes() {
      if (!this.tree.root) return []
      let nodes = [this.tree]
      let currentNode = this.tree
      this.nodeIds.every((id) => {
        let children = currentNode.root || currentNode.children
        if (!children) return
        let node = children.find((c) => c.id === id)
        if (node) {
          nodes.push(node)
          currentNode = node
          return true
        }
      })
      return nodes
    },
    currentNode() {
      if (!this.treeNodes.length) return
      let last = this.treeNodes[this.treeNodes.length - 1]
      return last
    },
    options() {
      if (!this.currentNode) return []
      return this.currentNode.root || this.currentNode.children
    },
  },
}
</script>
<style>
.tree-chooser-link {
  cursor: pointer;
  color: #378fe7;
}
</style>
