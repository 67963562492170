<template>
  <div class="fit column justify-center items-center">
    <div class="bg-white flex row justify-center q-pa-lg q-mb-lg" style="width: 100%; height: 96px">
      <img src="../../assets/simpleCheckBanner.svg" class="self-center" style="cursor: pointer" width="323px" height="48px" @click="goHome" />
    </div>
    <q-card v-if="$q.platform.is.mobile || !dynamicLoginLink" class="q-pa-md flex column justify-center items-center" style="width: 90%">
      <q-item-label class="text-center q-mb-md">Thanks for signing up {{ $store.state.user.data.displayName }}, you are now a user of Simple Check.</q-item-label>
      <q-separator />
      <q-item-label class="q-ma-md text-center" text-wrap> Use Simple Check on your smart phone by downloading the app. Just tap the button below to download. </q-item-label>
      <img style="height: auto; width: 300px" src="../../assets/download.png" />
      <q-btn no-caps rounded unelevated class="bg-secondary text-primary text-bold q-ml-md" label="Download the Simple Check App" @click="downloadApp" />
    </q-card>
    <q-card v-else class="q-pa-md flex column justify-center items-center" style="min-width: 300px; width: 50%">
      <q-item-label class="text-center q-mb-md">Thanks for signing up {{ $store.state.user.data.displayName }}, you are now a user of Simple Check.</q-item-label>
      <q-separator />
      <q-item-label class="q-ma-md text-center" text-wrap>
        Get the most out of Simple Check by downloading the app on your smart phone. Just scan your personal QR code below with your mobile device's camera to download the app and
        be automatically logged in.
      </q-item-label>
      <div class="flex justify-center q-mt-md">
        <img style="height: 300px" src="../../assets/download.png" />
        <div class="flex column q-ml-md">
          <QRCodeBox size="200" v-if="dynamicLoginLink" :url="dynamicLoginLink"></QRCodeBox>
          <label v-if="dynamicLoginLink" caption style="width: 200px" text-wrap class="text-muted text-center text-h7 q-mt-md"
            >This is a personal QR code, do not share it with others.</label
          >
        </div>
      </div>
    </q-card>
  </div>
</template>

<script>
import DynamicLinks from '../mixins/DynamicLinks.vue'
import QRCodeBox from '../Checklists/blocks/QRCodeBox.vue'
export default {
  name: 'Welcome',
  //props: ['company'],
  mixins: [DynamicLinks],
  components: { QRCodeBox },
  data() {
    return {
      waiting: false,
      dynamicLoginLink: null,
      company: { name: 'Company' },
      isLoggedIn: false,
    }
  },
  async mounted() {
    this.$log(`Welcome.mounted() company=${JSON.stringify(this.company)}`)
    if (!this.$q.platform.is.mobile) {
      // if not on mobile, check for auth every 100 ms.
      let interval = setInterval(async () => {
        // once authed, set loggedIn. If logged in, stop doing this check so the link is only generated once!
        if (this.isLoggedIn) {
          // this.$log('no more, clear the interval')
          return clearInterval(interval)
        }
        if (this.$firebase.auth().currentUser) {
          this.isLoggedIn = true
          // this.$log(`generating the link`)
          this.dynamicLoginLink = await this.getDynamicLoginLink()
        }
      }, 100)
    }
  },
  methods: {
    goHome() {
      this.$router.replace({ name: 'Home' })
    },
    downloadApp() {
      if (this.waiting) return
      this.waiting = true
      this.$eventBus.$emit('spinner', true)

      this.getDynamicLoginLink()
        .then((url) => {
          this.$eventBus.$emit('spinner', false)
          this.waiting = false
          // Helps bypass mobile safari popup blocker
          const a = document.createElement('a')
          a.setAttribute('href', url)
          a.setAttribute('target', '_blank')
          a.click()
        })
        .catch((error) => {
          this.$log('Error generating dynamic link, fallback to basic url: ' + error)
          this.waiting = false
          this.$eventBus.$emit('spinner', false)
          // Fall back to basic download url
          window.open(this.$isIOS ? this.$appleDownloadLink : this.$androidDownloadLink, '_self')
        })
    },
  },
}
</script>

<style lang="css" scoped>
.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.input {
  height: 40px;
  background-color: white;
  font-size: 15px;
}

.full-width {
  width: 100%;
}
.text-muted {
  color: gray;
}
</style>
