<!-- ListPageView

  This component is used for two purposes.
  1) It's a standard q-page with a col-3 list on the left and a content area on the right (via Slot)
  2) The slot "view" is only shown when an item is selected, and is keyed with that item! So it will re-draw, allowing
     for mounted()/destroyed() calls on the view components to run.

-->
<template>
  <q-page class="row justify-start">
    <q-card class="col-2 q-ma-sm">
      <q-card-section>
        <div v-if="title" class="text-h6 q-mb-sm">{{ title }}</div>
        <slot name="header"></slot>
      </q-card-section>

      <q-separator />

      <q-list bordered separator>
        <q-item
          clickable
          v-ripple
          v-for="item in list"
          :key="item[idProp]"
          @click="$emit('itemSelected', item[idProp])"
          :class="selectedItem && selectedItem[idProp] === item[idProp] ? 'bg-grey-2 border-active' : ''"
        >
          <q-item-section>
            <q-item-label>{{ item[nameProp] }}</q-item-label>
            <q-item-label caption>{{ item[descriptionProp] }}</q-item-label>
          </q-item-section>
        </q-item>
      </q-list>
    </q-card>
    <q-card class="q-ma-sm col" v-if="selectedItem" :key="selectedItem[idProp]">
      <slot name="view"></slot>
    </q-card>
    <q-card class="q-ma-sm col" v-else key="the-empty-slot">
      <slot name="empty"></slot>
    </q-card>
  </q-page>
</template>

<script>
export default {
  name: 'ListView',
  props: {
    list: { type: Array, required: true },
    selectedItem: { type: Object, required: false, default: null },
    title: { type: String, required: false },
    idProp: { type: String, required: false, default: 'id' },
    nameProp: { type: String, required: false, default: 'name' },
    descriptionProp: { type: String, required: false, default: 'description' },
  },
  data() {
    return {}
  },
}
</script>
