import * as firebase from 'firebase'

const state = {
  checklists: [],
}
const TEMPLATE_COMPANY_ID = 'NexdWKbNTYZHBazjNKBq' // this may not work outside of staging?
const getters = {
  configChecklistsTemplates: (state) => {
    return state.check
  },
}
const actions = {
  async loadTemplateChecklists({ commit, state }) {
    if (state.checklists.length) return // if we have every gotten templates before, require a page refresh
    let configChecklistRefs = await firebase.firestore().collection('configChecklists').where('companyId', '==', TEMPLATE_COMPANY_ID).where('deletedAt', '==', false).get()
    let configChecklists = configChecklistRefs.docs.map((doc) => {
      var data = doc.data() // items are not filled in
      data.id = doc.id
      return data
    })
    commit('setTemplateChecklists', configChecklists)
  },
}
const mutations = {
  setTemplateChecklists(state, checklists) {
    state.checklists.push(...checklists)
  },
  setUser(state, data) {
    state.data = data
  },
}
export default {
  state,
  getters,
  actions,
  mutations,
}
