import { render, staticRenderFns } from "./ItemModal.vue?vue&type=template&id=0b160938&scoped=true&"
import script from "./ItemModal.vue?vue&type=script&lang=js&"
export * from "./ItemModal.vue?vue&type=script&lang=js&"
import style1 from "./ItemModal.vue?vue&type=style&index=1&id=0b160938&lang=sass&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0b160938",
  null
  
)

export default component.exports
import QDialog from 'quasar/src/components/dialog/QDialog.js';
import QCard from 'quasar/src/components/card/QCard.js';
import QCardSection from 'quasar/src/components/card/QCardSection.js';
import QInput from 'quasar/src/components/input/QInput.js';
import QBtnToggle from 'quasar/src/components/btn-toggle/QBtnToggle.js';
import QSeparator from 'quasar/src/components/separator/QSeparator.js';
import QItemLabel from 'quasar/src/components/item/QItemLabel.js';
import QToggle from 'quasar/src/components/toggle/QToggle.js';
import QUploader from 'quasar/src/components/uploader/QUploader.js';
import QFile from 'quasar/src/components/file/QFile.js';
import QIcon from 'quasar/src/components/icon/QIcon.js';
import QCardActions from 'quasar/src/components/card/QCardActions.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import qInstall from "../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(component, 'components', {QDialog,QCard,QCardSection,QInput,QBtnToggle,QSeparator,QItemLabel,QToggle,QUploader,QFile,QIcon,QCardActions,QBtn});
