<template>
  <q-layout view="hHh lpR fFf">
    <q-page-container>
      <q-page class="flex flex-center row">
        <div class="col-md-2 col-sm-1 col-xs-1"></div>
        <div v-if="!startNow" class="q-pa-md col-md-8 col-sm-10 col-xs-12" style="min-height: 80vh">
          <q-card class="q-gutter-y-sm q-py-md flex flex-center justify-between" style="height: 100%">
            <q-card-section class="flex column flex-center no-wrap" style="width: 100%">
              <q-toolbar-title class="q-mb-md">{{ checklistTitle }}</q-toolbar-title>
              <q-separator class="q-ma-sm" v-if="lastCompleted && lastCompleted.readableTreeString" />
              <q-card flat class="q-mt-sm" v-if="lastCompleted && lastCompleted.readableTreeString">
                <q-card-section>
                  {{ lastCompleted.readableTreeString }}
                </q-card-section>
              </q-card>
              <q-separator class="q-ma-sm" />
              <q-card-section v-if="lastCompleted && lastCompleted.history">
                <u>This checklist was last completed:</u>
                <table>
                  <tr v-for="rec in lastCompleted.history" :key="rec.checklistId">
                    <td>{{ rec.completedAt | readableDate }}</td>
                    <td v-if="rec.by">by {{ rec.by.split(' ')[0] }}</td>
                  </tr>
                </table>
              </q-card-section>
              <q-expansion-item v-if="recentChecklistId" switch-toggle-side label="View most recent" style="width: 100%" header-style="background: #F5F5F5;">
                <completed-checklist :checklistId="recentChecklistId" :itemOrder="checklist ? checklist.itemOrder : []" hide-header first-only />
              </q-expansion-item>

              <div v-if="noHistory" style="font-size: larger; font-weight: bold">
                This checklist does not have a history<span v-if="this.$route.query.treepath"> at this Location.</span>
              </div>
            </q-card-section>
          </q-card>
        </div>
        <div v-else class="q-pa-md col-6">
          <q-stepper v-model="step" vertical color="primary" animated active-icon="fas fa-spinner fa-spin">
            <q-step :name="1" :title="checklistTitle" icon="fas fa-circle-notch" :done="step > 1"> Retrieving checklist from the database. </q-step>

            <q-step :name="2" :title="username" icon="fas fa-circle-notch" :done="step > 2">
              User credentials may be required, or need to be selected. <br />
              TODO: Allow user to sign into their account. <br />
              TODO: Anonymous checklists can skip this step.
            </q-step>

            <q-step :name="3" :title="catalog" icon="fas fa-circle-notch" :done="step > 3">
              If not already included, catalog where this is being performed. <br />
              TODO: Load the tree TODO: If not a leaf, select down to a leaf
            </q-step>

            <q-step :name="4" :title="initializingTitle" icon="fas fa-circle-notch">
              This checklist is ready to start. Should use click the button? Only if they clicked something else?
              <q-stepper-navigation>
                <q-btn color="primary" label="Start" :disabled="!activeChecklistId" @click="gotoUrl" />
              </q-stepper-navigation>
            </q-step>
          </q-stepper>
        </div>
        <div class="col-md-2 col-sm-1"></div>
      </q-page>
    </q-page-container>
    <q-footer>
      <q-card-section class="flex column flex-center"
        ><!--q-mt-xl-->
        <!-- <q-separator class="q-ma-sm" /> -->
        A verified user of Simple Check can start this checklist in the mobile app.
        <!-- <q-btn
          flat
          no-caps
          color="primary"
          label="Start Now"
          @click="
            startNow = true
            authChecklist()
          "
        /> -->
      </q-card-section>
    </q-footer>
  </q-layout>
</template>

<style></style>

<script>
import ChecklistMixin from '../mixins/ChecklistMixin.vue'
import CompletedChecklist from '../blocks/CompletedChecklist.vue'
export default {
  name: 'StartChecklist',
  mixins: [ChecklistMixin],
  components: { CompletedChecklist },
  data() {
    return {
      step: 1,
      username: 'Authorization...',
      catalog: 'Loading Catalog...',
      activeChecklistId: null,
      startNow: false,
      lastCompleted: null,
      recentChecklistId: null,
      noHistory: false,
    }
  },
  computed: {
    checklistTitle() {
      if (this.checklist) return this.checklist.name
      if (this.lastCompleted) return this.lastCompleted.name
      return 'Loading Checklist...'
    },
    initializingTitle() {
      if (!this.activeChecklistId) return 'Checklist Initializing...'
      return 'Checklist Ready to Start'
    },
    newUrl() {
      return window.location.origin + '/' + this.$router.resolve(`/activechecklist/${this.activeChecklistId}`).href
    },
  },
  mounted() {
    this.loadChecklist()
  },
  methods: {
    gotoUrl() {
      if (!this.activeChecklistId) {
        this.$q.notify({
          type: 'warning',
          message: 'The new checklist is not ready yet.',
        })
      } else {
        window.location.replace(this.newUrl)
      }
    },
    async loadChecklist() {
      // We have a config checklist id. Check if we have a treePath as a query param
      let treePath = this.$route.query.treepath || ''
      // Check if there is a lastCompleted for the treePath or empty string
      let lastCompSnap = await this.$firestore.collection('lastCompleted').where('configChecklistId', '==', this.$route.params.checklistId).where('treePath', '==', treePath).get()
      this.$log(`Found ${lastCompSnap.size} lastCompleted records for checklistId ${this.$route.params.checklistId} and treePath=${treePath}`)
      // if not, load the config checklist and it's tree as "normal"
      if (lastCompSnap.empty) {
        await this.getChecklist(null, 'configChecklists')
        if (!this.checklist) return
        if (this.checklist.deletedAt) {
          this.$q.notify({
            type: 'negative',
            message: 'This Checklist has been deleted. Please contact your account owner.',
          })
          return
        }
        this.noHistory = true
        return
      }
      // if we have a lastCompleted, sort by the dates and use the first as the most recent to show
      this.lastCompleted = lastCompSnap.docs[0].data()
      this.lastCompleted.history.sort((a, b) => {
        if (a.completedAt < b.completedAt) return 1
        if (a.completedAt > b.completedAt) return -1
        return 0
      })
      if (this.lastCompleted.history.length) this.recentChecklistId = this.lastCompleted.history[0].checklistId
      else this.noHistory = true

      this.step = 2
    },
    async authChecklist() {
      // TODO: Show company pin if PIN is required
      // TODO: If Checklist requires authentication
      //   1) Check auth locally (maybe already logged in, re-use functions for this to avoid duplicate login-flow).
      //   2) Show login box to sign in
      //   Then set username
      // TODO: If User List is provided for checklist (and not logged in), select from dropdown here (with search)
      //   Then set username
      if (this.$store.state.user.data && this.$store.state.user.data.id) {
        //this is a real user, they are already logged in on this device. Go ahead.
        setTimeout(() => {
          this.username = 'Authorization Complete'
          this.step = 3
          this.loadTree()
        }, 4000)
      } else {
        setTimeout(() => {
          this.username = 'Not Authorized'
          this.$q.notify({
            type: 'negative',
            message: 'You must be logged in to perform this checklist.',
          })
        }, 2000)
      }
    },
    async loadTree() {
      // TODO: Tree Reference on Checklist must be loaded here.
      // TODO: Examine given treeIds (or whatever variable) and make sure they are leaves. If no leaves, do some selection UI.
      let treeIds = ['Core Office Park', 'Suite 1']
      setTimeout(() => {
        this.catalog = treeIds.join(' - ')
        this.step = 4
        this.createActiveChecklist()
      }, 4000)
    },
    async createActiveChecklist() {
      var addMessage = this.$functions.httpsCallable('copyChecklist')
      const { data: newChecklistId } = await addMessage(JSON.stringify({ checklistId: this.checklist.id, userId: this.$store.state.user.data.id, tree: [] }))
      // TODO: If logged in, included user ID referice. Or use Authorization "username" field somewhere?
      this.$log(`newChecklistId=${newChecklistId}`)
      // a checklist is created. Let's go to the new site
      this.activeChecklistId = newChecklistId
    },
  },
}
</script>
