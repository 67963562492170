<template>
  <!-- goes inside a q-card, q-card-section, etc -->
  <div>
    <div class="flex row">
      <div class="text-h6">Location Hierarchy</div>
      <div class="q-pa-sm q-gutter-sm"><q-btn icon="fas fa-info" class="q-ml-sm" size="xs" style="color: #999" dense round @click="info.i1 = true" /></div>
      <q-dialog v-model="info.i1">
        <q-card>
          <q-card-section> When users start a checklist with a Location Hierarchy, they will be prompted to select the current Location in an expandable menu. </q-card-section>
          <q-card-section> The selected Location will be included in the completed checklist record. </q-card-section>
        </q-card>
      </q-dialog>
      <q-dialog v-model="info.i2">
        <q-card>
          <q-card-section>
            This Location Hierarchy must be unlinked from all Checklists before it can be deleted. Review the Location settings for these Checklists in the Checklists menu:
          </q-card-section>
          <q-card-section v-if="this.checklists.length > 0">
            <div v-for="checklist in this.checklists" :key="checklist.name">
              {{ checklist.name }}
            </div>
          </q-card-section>
        </q-card>
      </q-dialog>
    </div>
    <div class="flex row items-center q-mt-md">
      <q-select
        class="col-6 q-mr-md"
        outlined
        option-label="name"
        option-value="id"
        :value="selectedTree.id"
        @input="assignTree($event)"
        :options="trees"
        label="Location Hierarchy"
        map-options
      />
      <create-tree-modal ref="createTreeModal" @close="switchToEdit($event)"></create-tree-modal>
      <edit-tree-modal ref="editTreeModal" :tree="selectedTree" @close="getTrees($event)"></edit-tree-modal>
      <q-btn
        v-if="!selectedTree.id"
        rounded
        unelevated
        no-caps
        class="q-mr-sm bg-secondary text-primary text-bold"
        size="md"
        label="Create New"
        @click="$refs.createTreeModal.toggle()"
        style="height: 40px"
      />
      <q-btn
        v-else
        rounded
        unelevated
        no-caps
        class="q-mr-sm bg-secondary text-primary text-bold"
        style="height: 40px"
        size="md"
        label="Edit Location Hierarchy"
        @click="$refs.editTreeModal.toggle(selectedTree)"
      />
      <q-btn
        v-if="selectedTree.id && showDelete"
        unelevated
        no-caps
        class="q-mr-sm bg-negative text-white text-bold"
        rounded
        label="Delete Location Hierarchy"
        @click="deleteIfLocationHierarchyNotInUse()"
      />
      <sc-confirm
        ref="deleteTreeDialog"
        @cancel="$refs.deleteTreeDialog.toggle()"
        @confirm="deleteLocationHierarchy()"
        text="Are you sure you would like to delete this Location Hierarchy? This cannot be undone."
        cancelText="Cancel"
        confirmText="Delete"
        verificationWord="Delete"
      ></sc-confirm>
    </div>
  </div>
</template>

<script>
import EditTreeModal from '../Tree/EditTreeModal.vue'
import CreateTreeModal from '../Tree/CreateTreeModal.vue'

export default {
  name: 'LocationHierarchySelector',
  components: { EditTreeModal, CreateTreeModal },
  props: ['treeId', 'showDelete'],
  data() {
    return {
      checklists: [],
      selectedTree: { id: null, name: 'No Location' },
      info: { i1: false, i2: false },
      trees: [],
    }
  },
  async mounted() {
    await this.getTrees(this.treeId)
    await this.locationHierarchyInUse() // to set checklists.length
  },
  methods: {
    switchToEdit(treeId) {
      this.getTrees(treeId).then(() => {
        this.$nextTick(() => {
          this.$refs.editTreeModal.toggle(this.selectedTree)
        })
      })
    },
    async getTrees(treeId) {
      this.$log('[LHS] calling get trees with treeId ' + treeId)
      this.$eventBus.$emit('spinner')
      this.trees.splice(0, this.trees.length)
      this.trees.push({ id: null, name: 'None' })
      const trees = await this.$firestore.collection('trees').where('companyId', 'in', this.$store.getters.accountCompanies).get()
      trees.forEach((t) => {
        // these are DocumentSnapshots
        const tree = t.data()
        tree.id = t.id
        tree.root = JSON.parse(tree.root)
        this.trees.push(tree)
      })
      this.$eventBus.$emit('spinner', false)
      if (treeId) {
        // if a treeId is given, assign to this checklist
        await this.assignTree(this.trees.find((t) => t.id === treeId))
      }
    },
    async assignTree(tree) {
      this.selectedTree = tree
      this.$emit('treeSelected', tree.id)
      await this.locationHierarchyInUse()
    },
    async locationHierarchyInUse() {
      // get list of checklists that use this tree
      await this.$firestore
        .collection('configChecklists')
        .where('companyId', 'in', this.$store.getters.accountCompanies)
        .where('deletedAt', '==', false)
        .where('treeId', '==', this.selectedTree.id)
        .get()
        .then((snapshot) => {
          this.checklists = snapshot.docs.map((doc) => {
            var data = doc.data()
            data.id = doc.id
            return data
          })
        })
      let ret = this.checklists.length > 0
      return ret
    },
    deleteIfLocationHierarchyNotInUse() {
      if (this.checklists.length > 0) {
        // in use, don't delete, show warning
        this.info.i2 = true
      } else {
        this.$refs.deleteTreeDialog.toggle()
      }
    },
    async deleteLocationHierarchy() {
      // delete from both Firebase and local representation
      let treeIdToDelete = this.selectedTree.id
      await this.$firestore.collection('trees').doc(treeIdToDelete).delete()
      let localDeleteTreeIndex = this.trees.findIndex((tr) => tr.id === treeIdToDelete)
      if (localDeleteTreeIndex > -1) {
        this.trees.splice(localDeleteTreeIndex, 1)
      }
      await this.assignTree(this.trees.find((t) => t.id === null))
      this.$refs.deleteTreeDialog.toggle()
    },
  },
}
</script>
