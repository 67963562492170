import { render, staticRenderFns } from "./CompletedChecklist.vue?vue&type=template&id=59ca7fda&"
import script from "./CompletedChecklist.vue?vue&type=script&lang=js&"
export * from "./CompletedChecklist.vue?vue&type=script&lang=js&"
import style1 from "./CompletedChecklist.vue?vue&type=style&index=1&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports
import QCard from 'quasar/src/components/card/QCard.js';
import QCardSection from 'quasar/src/components/card/QCardSection.js';
import QSeparator from 'quasar/src/components/separator/QSeparator.js';
import QImg from 'quasar/src/components/img/QImg.js';
import QChip from 'quasar/src/components/chip/QChip.js';
import QBadge from 'quasar/src/components/badge/QBadge.js';
import qInstall from "../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(component, 'components', {QCard,QCardSection,QSeparator,QImg,QChip,QBadge});
