<template>
  <!-- <div class="flex justify-center"> -->
  <!-- <q-banner class="bg-negative text-white text-bold">
      You're nearing your checklist limit. Consider upgrading now to avoid reporting halts.
      <template v-slot:action>
        <q-btn flat color="white" no-caps label="Dismiss" />
        <q-btn flat color="white" no-caps label="Upgrade Now" />
      </template>
    </q-banner> -->

  <q-page class="q-pa-lg flex column items-center q-gutter-x-md">
    <div class="row q-mb-lg">
      <q-card class="q-pa-md col-10 flex column items-center" style="width: 100%; background-color: #092342">
        <q-item-label header class="text-center text-h4 text-white q-pl-sm">You have not completed your company setup!</q-item-label>
        <q-item-label header class="text-center text-white text-h5">Purchase a subscription to unlock all the features of Simple Check.</q-item-label>
        <q-btn unelevated no-caps rounded class="q-my-md self-center bg-secondary text-primary text-bold" size="md" label="View Plans" @click="viewPlans"></q-btn>
      </q-card>
    </div>
    <div class="row">
      <div class="col-xs-12 col-sm-6 q-px-md">
        <q-item-label header class="q-pl-sm">Mobile App</q-item-label>
        <q-card class="q-pa-md flex row items-center" style="height: 340px">
          <MobileAppBox></MobileAppBox>
        </q-card>
      </div>

      <!-- Support -->
      <div class="col-xs-12 q-px-md col-sm-6">
        <q-item-label header class="q-pl-sm">Support</q-item-label>
        <q-card class="q-pa-md flex row items-center" style="height: 340px">
          <SupportBox></SupportBox>
        </q-card>
      </div>
    </div>
  </q-page>
  <!-- </div> -->
</template>

<style></style>

<script>
import SupportBox from './blocks/SupportBox.vue'
import MobileAppBox from './blocks/MobileAppBox.vue'

export default {
  name: 'Home',
  props: ['company'],
  mounted() {},
  computed: {},
  methods: {
    viewPlans() {
      this.$log('view plans')
      window.open('https://mysimplecheck.com/pricing/', '_self')
    },
  },
  components: { SupportBox, MobileAppBox },
}
</script>
