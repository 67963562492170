<script>
const request = require('request-promise')
export default {
  data() {
    return {}
  },
  methods: {
    async getDynamicLoginLink() {
      return new Promise((resolve, reject) => {
        this.$firebase
          .auth()
          .currentUser.getIdToken(true)
          .then((token) => {
            return this.$functions.httpsCallable('generateCustomAuthToken')(JSON.stringify({ token: token }))
          })
          .then((response) => {
            const token = response.data.token
            const options = {
              method: 'POST',
              uri: `https://firebasedynamiclinks.googleapis.com/v1/shortLinks?key=${process.env.VUE_APP_FIREBASE_API_KEY}`,
              body: {
                dynamicLinkInfo: {
                  domainUriPrefix: process.env.VUE_APP_DYNAMIC_LINK,
                  link: window.location.origin + '/#/login?token=' + encodeURIComponent(token),
                  androidInfo: {
                    androidPackageName: 'com.cms.simplecheck',
                    androidMinPackageVersionCode: '11306',
                  },
                  iosInfo: {
                    iosBundleId: 'com.cms.simplecheck',
                    iosIpadBundleId: 'com.cms.simplecheck',
                    iosAppStoreId: '1542613005',
                  },
                  navigationInfo: {
                    enableForcedRedirect: true,
                  },
                },
              },
              json: true,
            }
            return request(options)
          })
          .then(function (parsedBody) {
            resolve(parsedBody.shortLink)
          })
          .catch((err) => {
            this.$log('Error generating dynamic login link:' + err)
            reject(err)
          })
      })
    },
    async getJoinCodeLink(joinCode) {
      this.$log('link domain: ' + process.env.VUE_APP_DYNAMIC_LINK)
      return new Promise((resolve, reject) => {
        const options = {
          method: 'POST',
          uri: `https://firebasedynamiclinks.googleapis.com/v1/shortLinks?key=${process.env.VUE_APP_FIREBASE_API_KEY}`,
          body: {
            dynamicLinkInfo: {
              domainUriPrefix: process.env.VUE_APP_DYNAMIC_LINK,
              link: window.location.origin + '/#/login?joinCode=' + joinCode,
              androidInfo: {
                androidPackageName: 'com.cms.simplecheck',
                androidMinPackageVersionCode: '11306',
              },
              iosInfo: {
                iosBundleId: 'com.cms.simplecheck',
                iosIpadBundleId: 'com.cms.simplecheck',
                iosAppStoreId: '1542613005',
              },
              navigationInfo: {
                enableForcedRedirect: true,
              },
            },
            suffix: {
              option: 'SHORT',
            },
          },
          json: true,
        }
        request(options)
          .then(function (parsedBody) {
            resolve(parsedBody.shortLink)
          })
          .catch((err) => {
            this.$log('Error generating dynamic join code link:' + err)
            reject(err)
          })
      })
    },
  },
}
</script>
