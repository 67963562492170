<template>
  <q-page class="flex flex-start">
    <div class="q-pa-lg" style="width: 100%; height: 100%">
      <q-table
        :pagination.sync="pagination"
        binary-state-sort
        class="table"
        :data="checklistsToShow"
        :columns="columns"
        row-key="id"
        selection="multiple"
        :selected.sync="selected"
        hide-bottom
      >
        <template v-slot:top>
          <div class="full-width">
            <div class="flex justify-between">
              <sc-search style="width: 40%" myPlaceHolder="Search Checklists" @termUpdated="searchUpdated($event)"></sc-search>
              <div class="q-gutter-x-sm">
                <q-btn rounded unelevated no-caps color="negative" v-if="selected.length !== 0" size="md" label="Delete Selected" @click="$refs.deleteDialog.toggle()" />
                <q-btn rounded unelevated no-caps class="q-mr-sm bg-secondary text-primary text-bold" size="md" label="New Checklist" @click="$refs.checklistModal.toggle()" />
              </div>
            </div>
          </div>
        </template>
        <template v-slot:body-cell="props">
          <q-td :key="props.row.name">
            <label class="cursor-pointer" @click="viewChecklist(props.row.id)">{{ props.row.name }}</label>
          </q-td>
        </template>
        <template v-slot:no-data>
          <label>No Checklists Available</label>
        </template>
      </q-table>
      <ChecklistModal ref="checklistModal" @close="viewChecklist($event)"></ChecklistModal>
      <sc-confirm
        ref="deleteDialog"
        verificationWord="Delete"
        confirmText="Delete"
        @cancel="$refs.deleteDialog.toggle()"
        @confirm="deleteChecklists()"
        :text="
          'Are you sure you would like to delete the selected '
          + (selected.length === 1 ? 'checklist' : 'checklists') + '? This cannot be undone. '
          + 'Data and reports associated with '
          + (selected.length === 1 ? 'this checklist' : 'these checklists') + ' will be inaccessible.'
        "
      ></sc-confirm>
    </div>
  </q-page>
</template>

<script>
import ChecklistModal from './ChecklistModal.vue'
export default {
  name: 'Checklists',
  components: { ChecklistModal },
  data() {
    return {
      checklists: [],
      columns: [{ name: 'name', align: 'left', label: 'Name', field: 'name', sortable: true }],
      selected: [],
      searchTerm: '',
      checklistsMatchingTerm: [],
      pagination: {
        rowsPerPage: 50,
        sortBy: 'name',
        descending: false,
      },
    }
  },
  computed: {
    checklistsToShow() {
      return !this.searchTerm || this.searchTerm.length === 0 ? this.checklists : this.checklistsMatchingTerm
    },
  },
  mounted() {
    this.getChecklists()
  },
  methods: {
    getChecklists() {
      this.$firestore
        .collection('configChecklists')
        .where('companyId', 'in', this.$store.getters.accountCompanies)
        .where('deletedAt', '==', false)
        .get()
        .then((snapshot) => {
          this.checklists = snapshot.docs.map((doc) => {
            var data = doc.data()
            data.id = doc.id
            return data
          })
        })
    },
    viewChecklist(id) {
      this.$router.push({ name: 'Checklist', params: { checklistId: id } })
    },
    deleteChecklists() {
      var batch = this.$firestore.batch()
      this.selected.forEach((checklist) => {
        // soft delete of checklists. These references are used other places later?
        batch.set(this.$firestore.collection('configChecklists').doc(checklist.id), { deletedAt: new Date() }, { merge: true })
        //batch.delete(this.$firestore.collection('configChecklists').doc(checklist.id))
      })
      batch.commit().then(() => {
        this.$q.notify({
          type: 'positive',
          message: 'Checklist(s) Deleted',
        })
        this.selected = []
        this.$refs.deleteDialog.toggle()
        this.getChecklists()
      })
    },
    searchUpdated(term) {
      this.checklistsMatchingTerm = []
      this.searchTerm = term
      const search = this.searchTerm ? this.searchTerm.toLowerCase() : ''
      this.checklists.forEach((list) => {
        if (list.name.toLowerCase().includes(search)) {
          this.checklistsMatchingTerm.push(list)
        }
      })
    },
  },
}
</script>
