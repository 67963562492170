import * as firebase from 'firebase'
import Vue from 'vue'

const state = {
  loggedIn: false,
  data: null,
  isAdmin: false,
}
const getters = {
  accountCompanies: (state) => {
    let companies = []
    if (state.data && state.data.roles) {
      for (let comid in state.data.roles) {
        if (state.data.roles[comid] === 'account') companies.push(comid)
      }
    }
    return companies
  },
}
const actions = {
  async fetchUser({ commit }, user) {
    commit('setLoggedIn', user !== null)
    // TODO: get the user document
    if (user) {
      const ourUser = (await firebase.firestore().collection('users').doc(user.uid).get()).data()
      let companies = []
      if (ourUser.roles) {
        for (let comid in ourUser.roles) {
          if (ourUser.roles[comid] === 'account') companies.push(comid)
        }
      }
      commit('setUser', {
        displayName: user.displayName || (ourUser ? ourUser.name : 'Anonymous'),
        email: user.email,
        id: user.uid,
        roles: ourUser.roles || {},
        companies: companies,
        history: ourUser.history || [],
      })
      firebase
        .auth()
        .currentUser.getIdTokenResult()
        .then((idTokenResult) => {
          // Confirm the user is an Admin.
          if (idTokenResult.claims.admin) commit('confirmAdmin')
        })
    } else {
      commit('setUser', null)
    }
  },
}
const mutations = {
  setLoggedIn(state, value) {
    state.loggedIn = value
    state.isAdmin = false
  },
  setUser(state, data) {
    state.data = data
  },
  confirmAdmin(state) {
    state.isAdmin = true
  },
  bashInAdmin(state, companyId) {
    let roles = {}
    roles[companyId] = 'account'
    Vue.set(state.data, 'roles', roles)
  },
}
export default {
  state,
  getters,
  actions,
  mutations,
}
