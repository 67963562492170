<template>
  <q-page class="flex flex-start q-pa-sm">
    <div class="q-px-lg q-py-sm q-gutter-y-xs" style="width: 100%; height: 100%">
      <q-btn flat color="accent" no-caps icon="fas fa-chevron-left" padding="5px 3px" @click="$router.go(-1)">Checklists</q-btn>
      <q-card class="q-pa-none">
        <div class="flex column full-width">
          <div class="flex row justify-start q-pa-md">
            <label style="font-size: 21px; height: 35px">{{ checklist.name }}</label>
          </div>

          <q-tabs v-model="tab" class="text-grey" active-color="primary" indicator-color="primary" align="justify" stretch no-caps>
            <q-tab :ripple="false" name="items" :label="'Items (' + orderedItems.length + ')'" />
            <q-tab :ripple="false" name="qr" label="Location & QR Codes" />
            <q-tab :ripple="false" name="settings" label="Settings" />
          </q-tabs>

          <q-Separtor row />

          <q-tab-panels v-model="tab" animated>
            <!-- Items -->
            <q-tab-panel class="q-pa-none flex row justify-between" name="items" style="height: 65vh">
              <div v-if="!orderedItems.length && !itemsLoading" class="text-h5 q-pa-lg" style="height: calc(100% - 70px)">
                Add items to this checklist by clicking "New Item" below.
              </div>
              <draggable v-else v-model="orderedItems" @change="doneDragging" class="full-width" style="height: calc(100% - 70px); overflow-y: scroll">
                <div class="item q-pa-md flex row q-gutter-x-sm" v-for="(item, index) in orderedItems" :key="item.itemId + index" @click="editItem(item)">
                  <q-icon class="self-center text-grey-7 q-mr-sm" style="cursor: grab" name="fas fa-grip-lines"></q-icon>

                  <div class="flex column col-8 self-center">
                    <label class="item-label q-mb-xs text-weight-bold" v-if="item.name"
                      >{{ item.name }}<span v-if="item.required" style="color: red; font-size: large"> *</span></label
                    >
                    <label class="item-label" dense borderless autogrow v-if="item.description"
                      >{{ item.description }}<span v-if="item.required && !item.name" style="color: red; font-size: large"> *</span></label
                    >
                  </div>

                  <q-space />
                  <q-btn flat class="self-center text-grey-7" style="cursor: pointer; font-size: 13px; width: 40px" icon="fas fa-ellipsis-v" @click.stop>
                    <q-menu transition-show="jump-down" transition-hide="jump-up">
                      <q-list style="min-width: 100px">
                        <q-item clickable>
                          <q-item-section @click="duplicateItem(item)">Duplicate Item</q-item-section>
                        </q-item>
                        <q-item clickable>
                          <q-item-section @click="makeItemOptionsTemplate(item)">
                            <q-item-label>Set as Default Options</q-item-label>
                            <q-item-label caption>New items will use these options.</q-item-label>
                          </q-item-section>
                        </q-item>
                        <q-Separtor row />
                        <q-item clickable>
                          <q-item-section style="color: darkred" @click="deleteItem(item)">Delete Item</q-item-section>
                        </q-item>
                      </q-list>
                    </q-menu>
                  </q-btn>
                </div>
              </draggable>
              <div class="flex justify-between q-pa-md full-width a" style="border-top: 1px solid lightgray; max-height: 70px; position: bottom">
                <q-btn rounded unelevated no-caps class="q-mr-sm bg-secondary text-primary text-bold" size="md" label="New Item" @click="$refs.itemModal.toggle(null, checklist)" />
              </div>
            </q-tab-panel>

            <!-- Tree & QR Codes -->
            <q-tab-panel name="qr">
              <q-card class="col-grow flex column scroll" flat>
                <q-card-section>
                  <location-hierarchy-selector :treeId="checklistTree.id" :showDelete="false" @treeSelected="getSingleTree($event)"> </location-hierarchy-selector>
                </q-card-section>
                <q-Separtor row />

                <q-card-section>
                  <div class="flex row">
                    <div class="text-h6">QR Code</div>
                    <div class="q-pa-sm q-gutter-sm"><q-btn icon="fas fa-info" class="q-ml-sm" size="xs" style="color: #999" dense round @click="info.i2 = true" /></div>
                    <q-dialog v-model="info.i2">
                      <q-card>
                        <q-card-section>
                          This QR Code can be scanned in the Simple Check app to start a new checklist. Anyone else can scan the QR code to view a report of the last time this
                          checklist was completed.
                        </q-card-section>
                        <q-card-section>
                          If a Location is selected below, scanning this QR Code will start a new checklist at the selected Location in the Simple Check app, or allow anyone else
                          to view a report of the last time this checklist was completed at that Location.
                        </q-card-section>
                        <q-card-section>
                          Click on the QR Code to open a menu, to copy the URL or see what a non-Simple Check user will see when scanning the QR Code without the mobile app.
                        </q-card-section>
                      </q-card>
                    </q-dialog>
                  </div>
                </q-card-section>
                <tree-chooser style="margin-top: -15px" v-if="checklistTree" v-model="treepath" :checklistId="$route.params.checklistId" :tree="checklistTree" />
                <q-Separtor row />
                <div>
                  <div class="flex row q-my-md">
                    <q-btn
                      icon-right="fas fa-download"
                      label="Export Single QR Code"
                      rounded
                      unelevated
                      no-caps
                      class="q-mr-sm bg-secondary text-primary text-bold q-mx-md"
                      @click="startExport(true)"
                    />
                    <q-btn
                      v-if="checklistTree.id"
                      icon-right="fas fa-download"
                      label="Export All QR Codes"
                      rounded
                      unelevated
                      no-caps
                      class="q-mr-sm bg-secondary text-primary text-bold q-ml-lg"
                      @click="startExport()"
                    />
                    <div v-if="checklistTree.id" class="q-pa-sm q-gutter-sm">
                      <q-btn icon="fas fa-info" class="q-ml-sm" size="xs" style="color: #999" dense round @click="info.i3 = true" />
                    </div>
                    <q-dialog v-model="info.i3">
                      <q-card>
                        <q-card-section> QR Codes can be generated for all possible Location combinations, or for a sub-selection. </q-card-section>
                      </q-card>
                    </q-dialog>

                    <q-dialog v-model="exportModal">
                      <q-card style="width: 50vw">
                        <q-card-section class="">
                          <div class="col text-h6">Export: Location QR Codes</div>
                          <div>
                            QR Codes will be exported for checked Locations. If "Exclude Parent Locations" is selected, only checked Locations that do not contain any other
                            Locations will be exported.
                          </div>
                        </q-card-section>
                        <q-card-section class="row items-center scroll" style="max-height: 70vh; height: 70vh">
                          <q-tree
                            ref="exportQTree"
                            class="col-sm-8 q-pa-sm"
                            node-key="id"
                            :nodes="checklistTree.root"
                            tick-strategy="leaf"
                            default-expand-all
                            :ticked.sync="exportTicked"
                          ></q-tree>
                        </q-card-section>
                        <q-card-actions align="between">
                          <div class="row justify-end">
                            <q-toggle v-model="exportOnlyLeaves" color="primary" />
                            <div class="text-right" style="line-height: 40px">Exclude Parent Locations</div>
                          </div>
                          <div>
                            <q-btn flat label="Cancel" v-close-popup />
                            <q-btn no-caps rounded unelevated class="bg-secondary text-primary text-bold q-ml-sm" size="md" label="Export" @click="exportTree()" />
                            <q-btn
                              no-caps
                              rounded
                              unelevated
                              class="bg-secondary text-primary text-bold q-ml-sm"
                              size="md"
                              label="Export (Avery 2x2 Labels)"
                              @click="exportTreeAvery()"
                            />
                          </div>
                        </q-card-actions>
                      </q-card>
                    </q-dialog>
                  </div>
                </div>
              </q-card>
            </q-tab-panel>

            <!-- Setttings -->
            <q-tab-panel name="settings" class="flex column">
              <div class="flex row justify-between">
                <q-card flat class="q-gutter-sm col-6">
                  <q-input outlined v-model="checklist.name" label="Name" />
                  <q-input outlined v-model="checklist.description" label="Description" autogrow />
                  <q-toggle v-model="checklist.allowAnonymous" label="Allow checklist to be completed without a user login" /><br />
                  <q-btn
                    no-caps
                    rounded
                    unelevated
                    class="bg-secondary text-primary text-bold"
                    :disabled="!hasEdits && !sopDocument"
                    label="Save"
                    @click="uploadAndUpdateChecklist"
                  />
                </q-card>
                <q-card class="col-4 q-pa-md">
                  <q-img v-if="checklist.resource" :src="checklist.resource" spinner-color="gray" style="height: 200px; cursor: pointer" contain @click="openResource">
                    <template v-slot:error>
                      <div class="absolute-full flex column flex-center bg-gray-6 text-white">
                        <q-icon style="font-size: 20px; cursor: pointer" name="fas fa-file-pdf" />
                        <label style="cursor: pointer">Click to view PDF</label>
                      </div>
                    </template>
                  </q-img>
                  <q-file
                    class="q-mt-sm"
                    outlined
                    v-model="sopDocument"
                    :label="checklist.resource ? 'Replace File' : 'Add File'"
                    hint="SOP Resource (PDF or image file, 10mb max)"
                    accept=".pdf, image/*"
                    @rejected="fileRejected"
                    :max-file-size="maxFileSize"
                  >
                    <template v-slot:append>
                      <q-icon name="fas fa-paperclip" />
                    </template>
                  </q-file>
                </q-card>
              </div>
            </q-tab-panel>
          </q-tab-panels>
        </div>
      </q-card>

      <ChecklistModal ref="checklistModal" @close="prepChecklist"></ChecklistModal>
      <ItemModal ref="itemModal" @close="prepChecklist"></ItemModal>
    </div>
  </q-page>
</template>

<script>
import * as QRCode from 'easyqrcodejs'
import draggable from 'vuedraggable'
import TreeChooser from './blocks/TreeChooser.vue'
import ChecklistModal from './ChecklistModal.vue'
import ItemModal from './ItemModal.vue'
import ChecklistMixin from '../mixins/ChecklistMixin.vue'
import QrMixin from '../mixins/QrMixin.vue'
import LocationHierarchySelector from '../Tree/LocationHierarchySelector.vue'
import { copyToClipboard } from 'quasar'
import firebaseUpload from '../mixins/firebaseUpload.vue'
import {
  Document,
  Packer,
  Paragraph,
  TableRow,
  HeightRule,
  SectionType,
  AlignmentType,
  TextRun,
  TableCell,
  Table,
  WidthType,
  ImageRun,
  BorderStyle,
  VerticalAlign,
  ExternalHyperlink,
} from 'docx'
import { saveAs } from 'file-saver'

export default {
  name: 'Checklists',
  components: { ChecklistModal, ItemModal, draggable, TreeChooser, LocationHierarchySelector },
  mixins: [ChecklistMixin, firebaseUpload, QrMixin],
  data() {
    return {
      tab: 'items',
      showQR: false,
      qrCodeObject: null,
      checklist: {},
      info: { i1: false, i2: false, i3: false, i4: false, i5: false },
      orderedItems: [],
      pagination: { rowsPerPage: 0, sortBy: 'startedAt', descending: true },
      checklistTree: { id: null, name: 'No Location' },
      treepath: '',
      columns: [{ name: 'name', align: 'left', label: 'Name', field: 'name', sortable: true }],
      selected: [],
      searchTerm: '',
      itemsMatchingTerm: [],
      itemsLoading: true,
      exportModal: false,
      exportOnlyLeaves: false,
      exportTicked: [],
      sopDocument: null,
      maxFileSize: 1024 * 1024 * 10, // ~10mb
    }
  },
  computed: {
    itemsToShow() {
      return !this.searchTerm || this.searchTerm.length === 0 ? this.orderedItems : this.itemsMatchingTerm
    },
    treeName() {
      if (this.checklistTree) return this.checklistTree.name
      return null
    },
    checklistUrl() {
      let routeObj = { path: `/startchecklist/${this.$route.params.checklistId}` }
      return window.location.origin + '/' + this.$router.resolve(routeObj).href
    },
  },
  async mounted() {
    await this.prepChecklist()
    this.getSingleTree(this.checklist.treeId)
  },
  methods: {
    fileRejected() {
      this.$q.notify({
        type: 'error',
        message: 'Invalid SOP Document, ensure the file is an acceptable type and is smaller than 10 MB.',
      })
    },
    startExport(isSingle) {
      if (!isSingle) {
        // this could be all. Show the selector and allow for export
        this.exportModal = true
        this.$nextTick(() => {
          // set all the nodes as ticked
          let tickNodes = (nodes) => {
            for (let node of nodes) {
              if (node.children && node.children.length > 0) {
                tickNodes(node.children)
              }
              this.exportTicked.push(node.id)
            }
          }
          tickNodes(this.checklistTree.root)
        })
      } else if (this.treepath) {
        // this is a single, get the treepath and export immediately
        this.exportTree(this.treepath)
      } else {
        //there is no treepath, so maybe just a root.
        this.exportTree('I-AM-ROOT')
      }
    },
    mapLeaves(nodes, current, singleTreepath) {
      let children = []
      // creates an array of objects with readable and treepath properties
      for (let node of nodes) {
        let nodeState = {
          readable: current.readable + (current.readable ? ' > ' : '') + node.label,
          treepath: current.treepath + (current.treepath ? '~~~' : '') + node.id,
        }
        if (singleTreepath && nodeState.treepath === singleTreepath) {
          return [nodeState]
        }
        if (node.children && node.children.length > 0) {
          if (!singleTreepath && !this.exportOnlyLeaves && this.exportTicked.includes(node.id)) children.push(nodeState)
          // there must be some children, this is not a leaf! recursively call into children to find more
          children.push(...this.mapLeaves(node.children, nodeState, singleTreepath))
        } else {
          if (!singleTreepath && this.exportTicked.includes(node.id)) children.push(nodeState)
          nodeState.isLeaf = true
        }
      }
      children.sort((a, b) => a.readable > b.readable)
      return children
    },
    exportTree(singleTreepath) {
      // This function creates a lot of QR Codes and puts them into work documents.
      // Also, the DOCX library is awesome and creates true OpenXML standard documents, but it's general code style
      //   is an object heavy indentation nightmare! This code is kept in-line so the function is not 500 lines long! (sorry that sacrifices code readability)
      this.$eventBus.$emit('spinner')
      try {
        const fullWidth = { size: 100, type: WidthType.PERCENTAGE }
        const tableRows = []
        // Loop through all the leaves of this.checklistTree and create a MS Word document with QR codes and information (this checklist + Location Path)

        let leaves = this.checklistTree.id ? this.mapLeaves(this.checklistTree.root, { readable: '', treepath: '' }, singleTreepath) : []

        if (singleTreepath === 'I-AM-ROOT' || (!singleTreepath && !this.exportOnlyLeaves)) {
          leaves.unshift({ treepath: '', readable: '' })
        }

        // this.$log(leaves)
        if (!leaves.length) {
          this.$q.notify({ type: 'error', message: 'There are no Locations, Export cancelled.' })
          throw new Error('No Leaves')
        }

        let elements = []
        for (let i = 0; i < leaves.length; i++) {
          let leaf = leaves[i]
          let el = document.createElement('div')
          el.id = 'qr-export-' + i
          elements.push(el)
          let onRenderingEnd = (options, dataURL) => {
            let leftColumn = [new Paragraph({ children: [new ImageRun({ data: dataURL, transformation: { width: 200, height: 200 } })], alignment: AlignmentType.CENTER })]
            let rightColumn = [new Paragraph(`Checklist: ${this.checklist.name}`)]
            if (leaf.readable) rightColumn.push(new Paragraph(`Location: ${leaf.readable}`))
            rightColumn.push(
              new Paragraph({
                children: [new ExternalHyperlink({ child: new TextRun({ text: 'Link', style: 'Hyperlink' }), link: `${this.genUrl(this.checklist.id, leaf.treepath)}` })],
              })
            )
            if (!leaf.isLeaf && leaf.readable) {
              rightColumn.push(
                new Paragraph(
                  `This Location has sub-Locations. Users starting this checklist with this QR Code will select more sub-Locations. The Last Completed times of a checklist are only tracked with the final Location information (not this QR Code)`
                )
              )
            }
            tableRows.push(
              new TableRow({
                children: [
                  new TableCell({ children: leftColumn, margins: { top: 100, bottom: 100, left: 0, right: 0 } }),
                  new TableCell({ verticalAlign: VerticalAlign.CENTER, margins: { top: 0, bottom: 0, left: 200, right: 0 }, children: rightColumn }),
                ],
                cantSplit: true,
              })
            )
          } // end onRenderingEnd
          new QRCode(el, {
            text: this.genUrl(this.checklist.id, leaf.treepath),
            width: 400,
            height: 400,
            correctLevel: QRCode.CorrectLevel.L,
            onRenderingEnd,
            quietZone: 12,
            colorDark: '#000000', // Dark color
            colorLight: '#ffffff', // Light color
          }) // end new QRCdoe
        } // end for-loop of leaves

        //delay to give everything a chance to finish
        setTimeout(() => {
          // create the sections of the docuemnt and create the document
          // Create a structure for a Docx file, 2x4 tables
          const doc = new Document({
            creator: 'Simple Check',
            description: `Location QR Codes for the checklist '${this.checklist.name}'`,
            title: 'Location QRs',
            sections: [
              {
                children: [
                  new Paragraph({ children: [new TextRun('Simple Check')] }),
                  new Paragraph({ children: [new TextRun(''), new Table({ rows: tableRows, width: fullWidth })] }),
                ],
              },
            ],
          })

          // Package and save with file saver
          Packer.toBlob(doc).then((blob) => {
            // saveAs from FileSaver will download the file
            saveAs(blob, `QR Codes for ${this.checklist.name}.docx`)
            this.exportModal = false
            this.$eventBus.$emit('spinner', false)
          })
        }, 300)
      } catch (e) {
        console.error(e)
        this.$eventBus.$emit('spinner', false)
      }

      // loop through the tree (recursively?), pairing readableTreeStrings to treePaths
      // let rightColumn = [new Paragraph('Checklist name'), new Paragraph('Readable Tree String')]
      // loop through the pairs creating dom elements that render the QR code, save image file using dom-to-image and putting in a docx ImageRun
      //  - store each of these in a Paragraph
    },

    async exportTreeAvery(singleTreepath) {
      // This function creates a lot of QR Codes and puts them into word documents formatted for Avery 2x2 labels.
      this.$eventBus.$emit('spinner')
      const timer = (ms) => new Promise((res) => setTimeout(res, ms))
      try {
        // No Borders
        const cellBordersNone = {
          top: {
            style: BorderStyle.NIL,
            size: 0,
          },
          bottom: {
            style: BorderStyle.NIL,
            size: 0,
          },
          left: {
            style: BorderStyle.NIL,
            size: 0,
          },
          right: {
            style: BorderStyle.NIL,
            size: 0,
          },
        }

        //Spacing between row cells
        const spaceColumnCell = new TableCell({
          width: {
            size: 907.2,
            type: WidthType.DXA,
          },
          borders: cellBordersNone,
          children: [],
        })

        // Loop through all the leaves of this.checklistTree and create a MS Word document with QR codes and information (this checklist + Location Path)
        let leaves = this.checklistTree.id ? this.mapLeaves(this.checklistTree.root, { readable: '', treepath: '' }, singleTreepath) : []

        if (singleTreepath === 'I-AM-ROOT' || (!singleTreepath && !this.exportOnlyLeaves)) {
          leaves.unshift({ treepath: '', readable: '' })
        }

        // this.$log(leaves)
        if (!leaves.length) {
          this.$q.notify({ type: 'error', message: 'There are no Locations, Export cancelled.' })
          throw new Error('No Leaves')
        }

        //Give each leaf a true index
        leaves.forEach((l, i) => (l.trueIndex = i + 1))

        let groupByN = (n, data) => {
          let result = []
          for (let i = 0; i < data.length; i += n) result.push(data.slice(i, i + n))
          return result
        }

        let groups = groupByN(3, leaves)
        let elements = []
        var cells = []
        var tableRows = []

        for (let i = 0; i < groups.length; i++) {
          //Page row
          const group = groups[i]
          for (let i = 0; i < group.length; i++) {
            //Leaves for row (3 per row)
            let leaf = group[i]
            let el = document.createElement('div')
            el.id = 'qr-export-' + i
            elements.push(el)

            let onRenderingEnd = (options, dataURL) => {
              cells.push(
                new TableCell({
                  width: {
                    size: 2880,
                    type: WidthType.DXA,
                  },
                  borders: cellBordersNone,
                  children: [
                    new Paragraph({
                      children: [new ImageRun({ data: dataURL, transformation: { width: 192, height: 192 } })],
                      alignment: AlignmentType.CENTER,
                    }),
                  ],
                  margins: { top: 0, bottom: 0, left: 0, right: 0 },
                })
              )
            }

            new QRCode(el, {
              text: this.genUrl(this.checklist.id, leaf.treepath),
              width: 155 * 2, // 2x for higher quality when scaled down
              height: 175 * 2,
              correctLevel: QRCode.CorrectLevel.L,
              onRenderingEnd,
              quietZone: 20,
              colorDark: '#000000', // Dark color
              colorLight: '#ffffff', // Light color
              title: 'Simple Check #' + leaf.trueIndex, // Title
              titleFont: 'normal normal bold 28px Arial', // Title font
              titleColor: '#505050', // Title Color
              titleHeight: 20, // Title height, include subTitle
              titleTop: 10, // Title draw position,
            }) // end new QRCdoe

            await timer(50) //Allow time for render (50ms)
          }

          let rowChildren = []
          cells.forEach((c, i) => {
            rowChildren.push(c)
            if (i === 0 || i === 1) rowChildren.push(spaceColumnCell)
          })

          let row = new TableRow({
            children: rowChildren,
            height: {
              rule: HeightRule.EXACT,
              value: 2880, //2880
            },
          })

          tableRows.push(row) //Add the row then optionally add spacing row
          if (i % 4 != 3) {
            //Do not add spacing for last row on page
            tableRows.push(
              new TableRow({
                height: {
                  rule: HeightRule.EXACT,
                  value: 835.2,
                },
                children: [
                  new TableCell({
                    width: {
                      size: 2880,
                      type: WidthType.DXA,
                    },
                    borders: cellBordersNone,
                    children: [new Paragraph('')],
                  }),
                ],
              })
            )
          }
          cells = []
        }

        //delay to give everything a chance to finish
        setTimeout(() => {
          // create the sections of the docuemnt and create the document
          // Create a structure for a Docx file,
          //https://www.avery.com/blank/labels/94107 3x4 grid of 2"x2" square labels
          const doc = new Document({
            creator: 'Simple Check',
            description: `Location QR Codes for the checklist '${this.checklist.name}'. Get labels: https://www.avery.com/blank/labels/94107`,
            title: 'QR 2x2 Labels',
            sections: [
              {
                properties: {
                  type: SectionType.NEW_PAGE,
                  titlePage: true,
                  page: {
                    size: {
                      width: 12240, //8.5"
                      height: 15840, //11"
                    },
                    margin: {
                      top: -800, //Negative to fight default word setting not changeable by docx. Do not change.
                      right: 690, //Do not change.
                      bottom: 0, // Do not change.
                      left: 790, // Do not change.
                    },
                  },
                },
                children: [new Paragraph({ children: [new TextRun(''), new Table({ rows: tableRows })] })],
              },
            ],
          })

          //Key Doc - Match QR # to location
          const keyChildren = [new Paragraph({ children: [new TextRun({ text: 'Simple Check QR Code Label Key', bold: true })] }), new Paragraph('')]
          leaves[0].readable = 'Checklist - ' + this.checklist.name
          leaves.forEach((l) => {
            keyChildren.push(new Paragraph('#' + l.trueIndex + ' - ' + l.readable))
            keyChildren.push(new Paragraph(''))
          })

          const keyDoc = new Document({
            creator: 'Simple Check',
            description: `Location QR Code Key for the checklist '${this.checklist.name}'.`,
            title: 'Key - QR 2x2 Labels',
            sections: [
              {
                properties: {
                  type: SectionType.NEW_PAGE,
                  titlePage: true,
                  page: {
                    size: {
                      width: 12240, //8.5"
                      height: 15840, //11"
                    },
                  },
                },
                children: keyChildren,
              },
            ],
          })

          // Package and save with file saver
          Packer.toBlob(doc).then((blob) => {
            // saveAs from FileSaver will download the file
            saveAs(blob, `QR Labels for ${this.checklist.name}.docx`)
            Packer.toBlob(keyDoc).then((blob) => {
              // saveAs from FileSaver will download the file
              saveAs(blob, `Key - QR Labels for ${this.checklist.name}.docx`)
              this.exportModal = false
              this.$eventBus.$emit('spinner', false)
            })
          })
        }, 300)
      } catch (e) {
        console.error(e)
        this.$eventBus.$emit('spinner', false)
      }
    },

    async uploadAndUpdateChecklist() {
      if (this.sopDocument) {
        await this.uploadFile(this.sopDocument, 'resources/' + this.$route.params.checklistId + new Date().getMilliseconds())
          .then((url) => {
            this.checklist.resource = url
            this.updateChecklist()
            this.sopDocument = null
          })
          .catch(() => {
            this.$q.notify({
              type: 'error',
              message: 'Error uploading SOP Document. Please try again.',
            })
          })
      } else {
        this.updateChecklist()
      }
    },
    openResource() {
      window.open(this.checklist.resource, '_blank')
    },
    getItem(itemId) {
      return this.items.find((item) => item.itemId === itemId)
    },
    doneDragging() {
      const itemOrder = this.orderedItems.map((item) => item.itemId)
      this.$firestore.collection('configChecklists').doc(this.$route.params.checklistId).update({ itemOrder: itemOrder })
    },
    gotoUrl() {
      window.open(this.newUrl, '_blank')
    },
    copyUrl() {
      copyToClipboard(this.newUrl)
        .then(() => {
          this.$q.notify({
            type: 'positive',
            message: 'Link Copied to Clipboard',
          })
        })
        .catch(() => {
          this.$q.notify({
            type: 'danger',
            message: 'Something went wrong, the link could not be copied.',
          })
        })
    },
    async prepChecklist() {
      await this.getChecklist(null, 'configChecklists')
      this.getItems()
      // this.prepQrCode()
    },
    getItems() {
      this.$firestore
        .collection('configChecklists')
        .doc(this.$route.params.checklistId)
        .collection('items')
        .get()
        .then((snapshot) => {
          this.orderedItems = []
          const unorderedItems = snapshot.docs.map((doc) => doc.data())
          if (this.checklist.itemOrder) {
            this.checklist.itemOrder.forEach((itemId) => {
              const item = unorderedItems.find((item) => item.itemId === itemId)
              if (item) this.orderedItems.push(item)
            })
          } else {
            // For existing checklists with no order set yet
            this.orderedItems = unorderedItems
            const initialOrder = this.orderedItems.map((item) => item.itemId)
            this.$firestore.collection('configChecklists').doc(this.$route.params.checklistId).update({ itemOrder: initialOrder })
          }
          this.itemsLoading = false
        })
    },
    editItem(item) {
      this.$refs.itemModal.toggle(JSON.parse(JSON.stringify(item)))
    },
    deleteItem(itemToDelete) {
      const checklistRef = this.$firestore.collection('configChecklists').doc(this.$route.params.checklistId)
      const newItemOrder = this.checklist.itemOrder.filter((itemId) => itemId !== itemToDelete.itemId)

      var batch = this.$firestore.batch()
      batch.update(checklistRef, { itemOrder: newItemOrder })
      batch.delete(checklistRef.collection('items').doc(itemToDelete.itemId))
      batch.commit().then(() => {
        this.$q.notify({
          type: 'positive',
          message: itemToDelete.name || 'Item' + ' Deleted',
        })
        this.getItems()
      })
    },
    duplicateItem(item) {
      const checklistRef = this.$firestore.collection('configChecklists').doc(this.$route.params.checklistId)
      var newItem = checklistRef.collection('items').doc()
      let itemToDupe = JSON.parse(JSON.stringify(item))
      itemToDupe.itemId = newItem.id
      itemToDupe.name = '(duplicate of) ' + itemToDupe.name
      newItem
        .set(itemToDupe)
        .then(() => {
          return checklistRef.update({ itemOrder: this.$fieldValue.arrayUnion(itemToDupe.itemId) })
        })
        .then(() => {
          this.prepChecklist()
          this.$q.notify({ type: 'positive', message: (itemToDupe.name || 'Item') + ' Created' })
        })
    },
    makeItemOptionsTemplate(item) {
      const checklistRef = this.$firestore.collection('configChecklists').doc(this.$route.params.checklistId)
      let newOptions = item.options.map((o) => {
        let newO = JSON.parse(JSON.stringify(o))
        delete newO.id
        return newO
      })
      checklistRef.update({ defaultOptions: newOptions }).then(() => {
        this.checklist.defaultOptions = newOptions
        this.$q.notify({ type: 'positive', message: 'Options set as checklist defaults' })
      })
    },
    editChecklist() {
      this.$refs.checklistModal.toggle(JSON.parse(JSON.stringify(this.checklist)))
    },
    searchUpdated(term) {
      this.itemsMatchingTerm = []
      this.searchTerm = term
      const search = this.searchTerm ? this.searchTerm.toLowerCase() : ''
      this.orderedItems.forEach((item) => {
        if (item.name.toLowerCase().includes(search)) {
          this.itemsMatchingTerm.push(item)
        }
      })
    },
    async getSingleTree(treeId) {
      let tree = null
      this.$eventBus.$emit('spinner')
      if (treeId) {
        const treeRef = await this.$firestore.collection('trees').doc(treeId).get()
        tree = treeRef.data()
        tree.id = treeRef.id
        tree.root = JSON.parse(tree.root)
        this.checklistTree = tree
      } else {
        this.checklistTree = { id: null, name: 'No Location' }
        this.$set(this.checklist, 'treeId', null)
      }
      if (this.checklist.treeId !== treeId) {
        await this.$firestore.collection('configChecklists').doc(this.$route.params.checklistId).set({ treeId: tree.id }, { merge: true })
      }
      this.treepath = ''
      this.$eventBus.$emit('spinner', false)
    },
  },
}
</script>
<style lang="css">
.item {
  border-bottom: 1px solid lightgray;
}
.item:hover {
  background-color: rgb(250, 250, 250);
  cursor: pointer;
}
.item:last-child {
  border-bottom: 0;
}
.item-label {
  cursor: pointer;
}
/*
.inline-edit {
}
*/
.header {
  background-color: gray;
}
</style>
