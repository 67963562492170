<template>
  <div>
    <div class="q-pa-md fixed-center full-width" style="max-width: 400px">
      <div class="full-width flex row items-start q-mb-md" style="justify-content: center">
        <img src="../../assets/simpleCheckBanner.svg" width="300px" />
      </div>
      <div v-if="newSignup">
        <q-card flat class="q-pa-sm q-gutter-y-md bg-transparent">
          <q-input class="input" v-model="fullName" placeholder="Full Name" outlined dense />
          <q-input autocapitalize="off" class="input" v-model="email" placeholder="Email" outlined dense />
          <q-input autocapitalize="off" type="password" class="input" v-model="password" placeholder="Password" outlined dense></q-input>
          <q-input autocapitalize="off" type="password" class="input" v-model="confirmPassword" placeholder="Confirm Password" outlined dense></q-input>
          <q-input autocapitalize="off" class="input col-10" v-model="joinCode" placeholder="Company Join Code" outlined dense>
            <template v-slot:append>
              <q-btn flat dense round class="" icon="fas fa-info-circle" @click="showJoinCodeInfo = true" />
            </template>
          </q-input>
          <div class="row" style="height: 40px">
            <q-checkbox v-model="agrees" color="accent" />
            <div class="col-10 q-ml-sm flex justify-center">
              <label class="full-width text-muted"
                >I agree to the <a style="text-decoration: none" class="text-accent" href="https://policy.mysimplecheck.com/terms" target="_blank">Terms of Service</a> and
                <a class="text-accent" style="text-decoration: none" href="https://policy.mysimplecheck.com" target="_blank">Privacy Policy</a></label
              >
            </div>
          </div>
          <q-btn
            :disable="fullName === '' || email === '' || password === '' || confirmPassword === '' || !agrees || joinCode === ''"
            no-caps
            unelevated
            rounded
            class="bg-secondary text-primary text-bold"
            style="height: 40px; width: 100%"
            @click="getStarted()"
            >Sign Up</q-btn
          >
          <q-btn no-caps flat rounded class="text-accent" style="width: 100%; text-align: right; font-size: 12px" @click="newSignup = false">Already have an account? Login</q-btn>
        </q-card>
      </div>
      <div class="q-gutter-y-md" v-else>
        <q-card flat class="q-pa-sm q-gutter-y-md bg-transparent">
          <q-input autocapitalize="off" class="input" v-model="email" placeholder="Email" outlined dense />
          <q-input
            autocapitalize="off"
            :type="showPassword ? 'text' : 'password'"
            style="margin-top: 15px"
            class="input"
            v-model="password"
            placeholder="Password"
            dense
            outlined
            key="login-pass"
            @keyup.enter="login()"
          >
            <template v-slot:append>
              <q-avatar>
                <q-icon style="font-size: 16px" :name="showPassword ? 'fas fa-eye-slash' : 'fas fa-eye'" @click="showPassword = !showPassword" />
              </q-avatar>
            </template>
          </q-input>
          <q-btn no-caps flat style="width: 100%; text-align: left; font-size: 12px; color: gray" @click="forgot">Forgot Password?</q-btn>
          <q-btn
            :disable="email === '' || password === ''"
            no-caps
            unelevated
            rounded
            class="bg-secondary text-primary text-bold"
            style="height: 40px; width: 100%"
            @click="login()"
            >Login</q-btn
          >
          <q-btn no-caps flat color="primary" class="text-accent" style="width: 100%; text-align: right; font-size: 12px" @click="newSignup = true"
            >Don't have an account? Sign Up</q-btn
          >
        </q-card>
      </div>
    </div>
    <div class="flex fixed-bottom q-pa-sm">
      <label class="full-width text-muted" style="text-align: center; margin-top: 10px">© {{ currentYear }} Core America</label>
      <q-btn class="full-width" no-caps flat color="accent" label="Contact Support" type="a" href="mailto:help@mysimplecheck.com?subject=Simple Check" />
    </div>
    <q-dialog v-model="confirmForgotPassword">
      <q-card>
        <q-card-section class="row items-center">
          <span class="q-ml-sm">Send a password reset email to {{ email }}?</span>
        </q-card-section>
        <q-card-actions align="right">
          <q-btn no-caps flat label="Cancel" color="negative" v-close-popup />
          <q-btn no-caps flat label="Send Email" color="primary" v-close-popup @click="sendForgotEmail" />
        </q-card-actions>
      </q-card>
    </q-dialog>
    <q-dialog v-model="showJoinCodeInfo">
      <q-card>
        <q-card-section>
          <div class="text-h6">Company Join Code</div>
        </q-card-section>

        <q-card-section class="q-pt-none">
          The Company Join Code is a unique identifier that allows new users to join an existing Simple Check company account. The code can be found in an invite email or by
          contacting your company's account owner.
        </q-card-section>
        <q-card-section class="q-pt-none">
          Don't have a Simple Check company account yet? Visit
          <a href="https://mysimplecheck.com/" class="text-accent" style="text-decoration: none">mysimplecheck.com</a> to browse plans and get started.
        </q-card-section>

        <q-card-actions align="right">
          <q-btn label="Okay" rounded unelevated class="bg-secondary text-primary text-bold" no-caps @click="showJoinCodeInfo = false" />
        </q-card-actions>
      </q-card>
    </q-dialog>
  </div>
</template>

<script>
export default {
  name: 'Login',
  data() {
    return {
      newSignup: false,
      email: '',
      password: '',
      showPassword: false,
      confirmForgotPassword: false,
      fullName: '',
      confirmPassword: '',
      joinCode: null,
      agrees: false,
      companyId: null,
      showJoinCodeInfo: false,
    }
  },
  mounted() {
    if (this.$route.query.joinCode) {
      this.joinCode = this.$route.query.joinCode
      this.newSignup = true
    }
    if (this.$route.query.newSignup) this.newSignup = this.$route.query.newSignup
  },
  computed: {
    currentYear() {
      const d = new Date()
      return d.getFullYear()
    },
  },
  methods: {
    login() {
      this.$eventBus.$emit('spinner')
      this.$firebase
        .auth()
        .signInWithEmailAndPassword(this.email, this.password)
        .then(async ({ user }) => {
          await this.$store.dispatch('fetchUser', user)
          this.success()
        })
        .catch((err) => {
          this.$eventBus.$emit('spinner', false)
          this.$q.notify({ type: 'negative', message: err.message, timeout: 500 })
        })
    },
    forgot() {
      if (this.email) {
        this.confirmForgotPassword = true
      } else {
        this.$q.notify({
          type: 'warning',
          message: 'Enter the email associated with the account in order to send a password reset email.',
          timeout: 5000,
        })
      }
    },
    sendForgotEmail() {
      this.$firebase
        .auth()
        .sendPasswordResetEmail(this.email)
        .then(() => {
          this.$q.notify({ type: 'positive', message: 'Password reset email sent!' })
        })
        .catch((error) => {
          this.$q.notify({ type: 'negative', message: 'Error sending password reset email: ' + error.message, timeout: 3000 })
        })
    },
    async getStarted() {
      if (this.password !== this.confirmPassword) {
        this.$q.notify({ type: 'negative', message: 'Passwords do not match.', timeout: 5000 })
        return
      }

      this.$eventBus.$emit('spinner', true)

      var newUser = { name: this.fullName, email: this.email, createdAt: this.$fieldValue.serverTimestamp(), analyticsEnabled: true }

      this.$functions
        .httpsCallable('validateJoinCode')(JSON.stringify({ joinCode: this.joinCode }))
        .then((response) => {
          if (response.data.companyId) {
            this.companyId = response.data.companyId
            this.$firebase
              .auth()
              .createUserWithEmailAndPassword(this.email, this.password)
              .then(async ({ user }) => {
                newUser.userId = user.uid

                await this.$firestore.collection('users').doc(newUser.userId).set(newUser)
                await this.$store.dispatch('fetchUser', user)
                await this.joinCompany(newUser)
                this.success()
              })
              .catch((err) => {
                this.$eventBus.$emit('spinner', false)
                this.$q.notify({ type: 'negative', message: err.message, timeout: 50000 })
              })
          } else {
            this.$eventBus.$emit('spinner', false)
            this.$q.notify({
              type: 'negative',
              message: response.data.message + ' Please check for errors or contact your account owner.',
            })
          }
        })
        .catch(() => {})
    },
    async joinCompany(newUser) {
      const functionRef = this.$functions.httpsCallable('joinCompany')
      const {
        data: { message, success },
      } = await functionRef(JSON.stringify({ companyId: this.companyId, userId: newUser.userId }))
      this.$q.notify({
        type: success ? 'positive' : 'negative',
        message,
      })
      if (success) {
        const user = JSON.parse(JSON.stringify(newUser))
        if (!user.companies) user.companies = []
        if (!user.companies.includes(this.companyId)) user.companies.push(this.companyId)
        if (user.roles === undefined) user.roles = {}
        this.$store.commit('setUser', user)
      } else {
        this.$q.notify({
          type: 'negative',
          message: message,
        })
      }
    },
    success() {
      this.$eventBus.$emit('spinner', false)
      let redirectEncoded = this.$route.query.redirect
      let redirect = decodeURI(redirectEncoded)
      this.$log(`Login.success() redirectEncoded=${redirectEncoded} redirect=${redirect}`)
      if (redirectEncoded && redirectEncoded) this.$router.replace({ path: redirect })
      else {
        let isAccountCompany = false
        if (this.$store.state.user.data && this.$store.state.user.data.roles) {
          for (let comId in this.$store.state.user.data.roles) {
            if (this.$store.state.user.data.roles[comId] === 'account') {
              isAccountCompany = true
              break
            }
          }
        }
        if (isAccountCompany) this.$router.replace({ name: 'Home' })
        else this.$router.replace({ name: 'Welcome' })
      }
      this.$eventBus.$emit('refresh')
    },
  },
  components: {},
}
</script>

<style lang="css" scoped>
.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.input {
  height: 40px;
  background-color: white;
  font-size: 15px;
}

.full-width {
  width: 100%;
}
.text-muted {
  color: gray;
}
</style>
