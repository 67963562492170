<template lang="html">
  <q-card class="q-pa-md">
    <q-table v-if="reportData" grid :title="titleText" :data="reportData.summaries" :columns="[]" hide-header hide-pagination :pagination.sync="pagination">
      <template v-slot:top-right>
        <q-btn rounded unelevated class="bg-secondary text-primary text-bold" label="Export to PDF" no-caps @click="$emit('export')" />
      </template>
      <template v-slot:item="props">
        <div class="q-pa-xs col-xs-12 col-sm-6 col-md-4">
          <q-card>
            <q-card-section class="text-center flex column">
              <q-item-label class="text-h6 row" v-if="props.row.title">{{ props.row.title }}</q-item-label>
              <q-item-label class="row" caption v-if="props.row.description">{{ props.row.description }}</q-item-label>
            </q-card-section>
            <q-separator />
            <q-card-section>
              <chart v-if="props.row.itemType === 'options'" type="pie" :data="props.row.data" :colors="props.row.colors"></chart>
              <chart-table v-if="props.row.itemType === 'input'" :data="props.row.data" :colors="props.row.colors"></chart-table>
            </q-card-section>
          </q-card>
        </div>
      </template>
    </q-table>
  </q-card>
</template>

<script>
import Chart from './blocks/Chart.vue'
import ChartTable from './blocks/ChartTable.vue'

export default {
  name: 'SummaryReport',
  props: ['configChecklist', 'reportData'],
  components: { Chart, ChartTable },
  mixins: [],
  data() {
    return {
      pagination: { rowsPerPage: 0, sortBy: 'startedAt', descending: true },
    }
  },
  mounted() {
    this.$log('SummaryReport mounted, building summaries ' + this.configChecklist.name)
  },
  computed: {
    titleText() {
      let count = this.reportData && this.reportData.numChecklists
      return `${count} Checklists`
    },
  },
}
</script>

<style lang="sass">
.table-sticky-header
  /* height or max-height is important */
  max-height: 100vh

  .q-table__top,
  thead tr:first-child th
    /* bg color is important for th; just specify one */
    background-color: white

  thead tr th
    position: sticky
    z-index: 1
  thead tr:first-child th
    top: 0

  /* this is when the loading indicator appears */
  &.q-table--loading thead tr:last-child th
    /* height of all previous header rows */
    top: 48px
</style>
