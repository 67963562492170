<template>
  <q-page class="flex flex-start column q-gutter-y-md q-pa-md" v-if="$store.state.user.isAdmin">
    <div class="row justify-end">
      <q-toggle v-model="showExpired" color="primary" />
      <div class="text-right" style="line-height: 40px">Show Expired Companies</div>
    </div>
    <q-table :data="showCompanies" :columns="columns" row-key="id" :pagination.sync="pagination" class="table-sticky-header">
      <template v-slot:body-cell="props">
        <q-td :props="props">
          <q-btn v-if="props.col.name === 'joinCode'" size="sm" color="primary" icon="fas fa-copy" @click="copyUrl(props.value, props.row.name)">
            <q-tooltip> Join {{ props.row.name }} </q-tooltip>
          </q-btn>
          <q-btn v-else-if="props.col.name === 'id'" size="sm" color="primary" icon="fas fa-external-link-square-alt" @click="switchTo(props.value)">
            <q-tooltip>
              Switch To {{ props.row.name }} <br />
              {{ props.row.id }}
            </q-tooltip>
          </q-btn>
          <div v-else-if="props.col.name === 'subscription' && props.row.subscription">
            <q-chip
              square
              dense
              :color="props.row.subscription.color"
              v-if="props.row.subscription.status === 'trialing'"
              @click="dialogTrial(props.row.subscription.id, props.row.name)"
              clickable
            >
              Trial ends&nbsp;{{ props.row.subscription.periodEnd }}
            </q-chip>
            <q-chip square dense :color="props.row.subscription.color" v-else-if="props.row.subscription.status === 'active'">
              Renews&nbsp;{{ props.row.subscription.periodEnd }}
            </q-chip>
            <q-chip square dense :color="props.row.subscription.color" v-else-if="props.row.subscription.status === 'past_due'">
              Renews&nbsp;{{ props.row.subscription.periodEnd }}
            </q-chip>
            <q-chip square v-else dense :color="props.row.subscription.color">
              {{ props.row.subscription.status[0].toUpperCase() + props.row.subscription.status.slice(1) }}
              <q-tooltip> {{ props.row.subscription.periodEnd }} </q-tooltip>
            </q-chip>
          </div>
          <div v-else-if="props.col.name === 'Limits'">
            <div v-if="props.value.text === 'Unknown'">Unknown</div>
            <q-btn v-else padding="none" flat size="md" :label="props.value.text" @click="dialogLimit(props.row.id, props.row.name)" />
            <q-linear-progress
              size="sm"
              :value="props.value.progress"
              :color="props.value.progress > 0.9 ? 'red' : props.value.progress > 0.75 ? 'warning' : 'primary'"
              class="w-50"
            />
          </div>
          <div v-else>{{ props.value }}</div>
        </q-td>
      </template>
    </q-table>
    <q-dialog v-model="editTrial">
      <q-card>
        <q-card-section class="row items-center">
          <span class="q-ml-sm">From today, when should the trial period end for '{{ editTrialName }}'?</span>
        </q-card-section>

        <q-card-actions align="right">
          <q-btn flat label="Cancel" v-close-popup />
          <q-btn flat label="10 days" @click="adjustTrial(10)" />
          <q-btn flat label="30 days" @click="adjustTrial(30)" />
          <q-btn flat label="60 days" @click="adjustTrial(60)" />
        </q-card-actions>
      </q-card>
    </q-dialog>
    <q-dialog v-model="editLimit">
      <q-card>
        <q-card-section class="row items-center">
          <span class="q-ml-sm">Increase this period limit for '{{ editLimitName }}' by:</span>
        </q-card-section>

        <q-card-actions align="right">
          <q-btn flat label="Cancel" v-close-popup />
          <q-btn flat label="50 more" @click="adjustLimit(50)" />
          <q-btn flat label="500 more" @click="adjustLimit(500)" />
        </q-card-actions>
      </q-card>
    </q-dialog>
  </q-page>
</template>
<script>
import { copyToClipboard } from 'quasar'
const validStatuses = ['trialing', 'active', 'past_due']
export default {
  name: 'Dashboard',
  mixins: [],
  data() {
    return {
      showExpired: false,
      companies: [],
      editTrial: false,
      editTrialName: null,
      editTrialId: null,
      editLimit: false,
      editLimitName: null,
      editLimitId: null,
      pagination: { rowsPerPage: 0, sortBy: 'name', descending: false },
      visibleColumns: ['name', 'createdAt', 'Limits', 'subscription', 'accountEmail', 'joinCode', 'id'],
    }
  },
  computed: {
    showCompanies() {
      return this.showExpired ? this.companies : this.companies.filter((c) => c.subscription && validStatuses.includes(c.subscription.status))
    },
    currentUser() {
      return this.$store.state.user.data
    },
    columns() {
      return [
        { name: 'name', label: 'Name', field: 'name', sortable: true, align: 'left' },
        { name: 'createdAt', label: 'Created', field: 'createdAt', sortable: true, align: 'left' },
        {
          name: 'Limits',
          label: 'Limits Used (this period)',
          field: (row) => {
            if (!row.limits) return { text: 'Unknown', progress: 0 }
            let { completedChecklists, checklistLimit } = row.limits
            return { text: `${completedChecklists} of ${checklistLimit}`, progress: completedChecklists / checklistLimit }
          },
          align: 'center',
        },
        { name: 'subscription', label: 'Subscription', field: 'subscription', align: 'left' },
        { name: 'accountEmail', label: 'Account Email', field: 'accountEmail', align: 'left' },
        { name: 'joinCode', label: 'Join Code', field: 'joinCode', align: 'center' },
        { name: 'id', label: 'Switch', field: 'id', align: 'center' },
      ]
    },
  },
  mounted() {
    if (!this.$store.state.user.isAdmin) {
      this.$q.notify({
        type: 'danger',
        message: 'Access denied for dashboard. Re-directing to Home',
      })
      this.$router.replace({ name: 'Home' })
    } else {
      this.getCompanies().then(() => {
        this.onlySubscribedCompanies()
      })
    }
  },
  methods: {
    dialogTrial(subId, companyName) {
      this.editTrialId = subId
      this.editTrialName = companyName
      this.editTrial = true
    },
    async adjustTrial(days) {
      this.$log('Adjusting trial on ' + this.editTrialId)
      this.$q.loading.show({
        backgroundColor: 'white',
        spinnerColor: 'primary',
      })
      try {
        const functionRef = this.$firebase.app().functions('us-central1').httpsCallable('stripe-extendTrial')
        const {
          data: { expire },
        } = await functionRef({ subId: this.editTrialId, days: days })
        let newExpireDate = new Date(expire)
        let companyToChange = this.companies.find((com) => com.subscription.id === this.editTrialId)
        this.$set(companyToChange.subscription, 'periodEnd', this.$moment(newExpireDate).format('MMM D'))
      } catch (error) {
        //log the raw error, this is an admin area and that will make it easier to debug.
        this.$log(`Could not extend trial for ${this.editTrialName} id=${this.editTrialId}`)
        console.error(error)
        this.$q.notify({
          type: 'danger',
          message: 'Something went wrong. ' + error.message,
        })
      }
      this.$q.loading.hide()
      this.editTrialId = null
      this.editTrialName = null
      this.editTrial = false
    },
    dialogLimit(companyId, companyName) {
      this.editLimitId = companyId
      this.editLimitName = companyName
      this.editLimit = true
    },
    async adjustLimit(count) {
      this.$q.loading.show({
        backgroundColor: 'white',
        spinnerColor: 'primary',
      })
      let company = this.companies.find((com) => com.id === this.editLimitId)
      try {
        let newLimit = company.limits.checklistLimit + count
        this.$log(`Adjusting limit on ${this.editLimitId} by ${count} to ${newLimit}`)
        await this.$firestore.collection('companies').doc(this.editLimitId).update('limits.checklistLimit', newLimit)
        this.$set(company.limits, 'checklistLimit', newLimit)
      } catch (error) {
        //log the raw error, this is an admin area and that will make it easier to debug.
        this.$log(`Could not adjust limit for ${this.editLimitName} id=${this.editLimitId}`)
        console.error(error)
        this.$q.notify({
          type: 'danger',
          message: 'Something went wrong. ' + error.message,
        })
      }
      this.$q.loading.hide()
      this.editLimitId = null
      this.editLimitName = null
      this.editLimit = false
    },
    switchTo(companyId) {
      //some Vuex magic. Make this user in Vuex think they have a role for this company.
      this.$store.commit('bashInAdmin', companyId)
      this.$eventBus.$emit('refresh')
      this.$router.push({ name: 'Home' })
    },
    copyUrl(joinCode, companyName) {
      let joinUrl = window.location.origin + '/' + this.$router.resolve(`/Login?newSignup=true&joinCode=${joinCode}`).href
      copyToClipboard(joinUrl)
        .then(() => {
          this.$q.notify({
            type: 'positive',
            message: `Join Code for ${companyName} Copied to Clipboard`,
          })
        })
        .catch(() => {
          this.$q.notify({
            type: 'danger',
            message: 'Something went wrong, the link could not be copied.',
          })
        })
    },
    async onlySubscribedCompanies() {
      await Promise.all(
        this.companies.map(async (com) => {
          //console.log(`looking at company ${com.name}`)
          let snapshot = await this.$firestore.collection('companies').doc(com.id).collection('subscriptions').where('status', 'in', validStatuses).get()
          if (snapshot.size > 0) {
            let sub = snapshot.docs[0].data()
            let period = sub.status === 'trialing' ? sub.trial_end : sub.current_period_end

            //console.log(`seeting subscriotoing ${sub.status}`)
            com.subscription = {
              color: sub.status === 'trialing' ? 'amber-3' : sub.status === 'past_due' ? 'red-5' : 'green-3',
              count: snapshot.size,
              status: sub.status,
              periodEnd: this.$moment.unix(period.seconds).format('MMM D'),
              id: sub.items && sub.items[0] && sub.items[0].subscription,
            }
          }
        })
      )
    },
    getCompanies() {
      return this.$firestore
        .collection('companies')
        .get()
        .then((snapshot) => {
          this.companies = snapshot.docs.map((doc) => {
            var data = doc.data()
            var company = {
              id: doc.id,
              name: data.name,
              numUsers: data.users && data.users.length,
              joinCode: data.joinCode,
              accountEmail: data.accountEmail,
              limits: data.limits,
              subscription: { status: 'unknown', count: 0, periodEnd: 'Expired', color: 'red-5' },
            }
            if (data.billingInfo && data.billingInfo.createdAt) {
              company.createdAt = this.$moment.unix(data.billingInfo.createdAt.seconds).format("MMM DD, 'YY")
            }

            return company
          })
        })
    },
  },
}
</script>
<style lang="sass" scoped>
.table-sticky-header
  /* height or max-height is important */
  max-height: 100vh

  .q-table__top,
  thead tr:first-child th
    /* bg color is important for th; just specify one */
    background-color: white

  thead tr th
    position: sticky
    z-index: 1
  thead tr:first-child th
    top: 0

  /* this is when the loading indicator appears */
  &.q-table--loading thead tr:last-child th
    /* height of all previous header rows */
    top: 48px
</style>
