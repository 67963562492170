<script>
export default {
  data() {
    return {}
  },
  methods: {
    async copyConfigChecklist(configChecklistId) {
      const doc = await this.$firestore.collection('configChecklists').doc(configChecklistId).get()
      if (doc.exists) {
        const newChecklist = JSON.parse(JSON.stringify(doc.data()))
        delete newChecklist.id
        delete newChecklist.checklistId
        delete newChecklist.treeId
        delete newChecklist.items
        newChecklist.companyId = this.$store.getters.accountCompanies[0] //checklists belong to a company
        newChecklist.deletedAt = false
        const newChecklistRef = await this.$firestore.collection('configChecklists').doc()
        newChecklist.configChecklistId = newChecklistRef.id
        await newChecklistRef.set(newChecklist)
        //we have a checklist assigned to the correct company. Now get and copy in the items

        const existingItemRefs = await this.$firestore.collection('configChecklists').doc(configChecklistId).collection('items').get()
        // this.$log(`copying the items now ${existingItemRefs.size}`)
        let orderMapping = {}
        existingItemRefs.forEach(async (doc) => {
          let item = JSON.parse(JSON.stringify(doc.data()))

          var newItemRef = this.$firestore.collection('configChecklists').doc(newChecklistRef.id).collection('items').doc()
          orderMapping[item.itemId] = newItemRef.id
          item.itemId = newItemRef.id
          item.id = newItemRef.id
          await newItemRef.set(item)
        })
        this.$log(`previous item order: ${newChecklist.itemOrder}`)
        let newItemOrder = newChecklist.itemOrder.map((id) => orderMapping[id])
        this.$log(`new item order: ${newItemOrder}`)
        await newChecklistRef.update('itemOrder', newItemOrder)
        return newChecklist.configChecklistId
      } else {
        this.$q.notify({
          type: 'negative',
          message: 'Error copying configuration checklists. Checklist Not Found',
        })
      }
    },
  },
}
</script>
