<template>
  <div id="printable-area">
    <report-header
      :type="type"
      :selectedConfigChecklistId="selectedConfigChecklist"
      :configChecklists="configChecklists"
      :performedByOptions="performedByOptions"
      :performedByFilter="performedByFilter"
      :checklistTree="checklistTree"
      @typeChange="changeReport($event)"
      @checklistChange="selectChecklist($event.id)"
      @dateChange="updateDateQuery($event)"
      @filterPerformedBy="updatePerformedBy($event)"
      @filterTree="updateTreePath($event)"
    ></report-header>
    <div class="q-pa-sm" v-if="selectedConfigChecklist">
      <q-card class="q-px-sm row justify-between">
        <router-view
          class="col"
          v-show="!!filteredChecklists.length"
          :checklists="filteredChecklists"
          :reportData="reportData"
          :configChecklist="selectedConfigChecklist"
          :dates="date"
          @export="generatePdf()"
        ></router-view>
        <div class="q-pa-md" v-show="!filteredChecklists.length">{{ loadingStatus }}</div>
      </q-card>
    </div>
  </div>
</template>
<script>
import ReportHeader from './blocks/ReportHeader.vue'
import Pdf from 'jspdf'
import Canvas from 'html2canvas'

export default {
  name: 'Report2',
  props: [],
  data() {
    return {
      checklists: [],
      performedByOptions: [],
      performedByFilter: null,
      type: null,
      key: 1,
      selectedConfigChecklistId: null,
      configChecklists: [],
      loadingStatus: 'Loading checklists...',
      date: { to: this.$moment().format('YYYY/MM/DD'), from: this.$moment().subtract(7, 'days').format('YYYY/MM/DD') },
      checklistTree: null,
      treePath: '',
      reportData: null,
    }
  },
  async mounted() {
    // this.$log(`Reports loaded with checklistId=${this.$route.params.checklistId}`)
    await this.getConfigChecklists()
    let currentChecklistId = this.$route.params.checklistId || this.configChecklists[0].id
    this.selectChecklist(currentChecklistId)
    // this.$log('Report Layout mounted, getting checklists')
    this.type = this.$route.name.split('Report')[0]
    // this.$log(`ReportLayout setting type to ${this.type} from ${this.$route.name.split('Report')}`)
    this.key++
  },
  computed: {
    selectedConfigChecklist() {
      if (!this.configChecklists.length) return null
      return this.configChecklists.find((t) => t.id === this.selectedConfigChecklistId)
    },
    filteredTreeIds() {
      if (!this.treePath || this.treePath === '') return []
      return this.treePath.split('~~~')
    },
    filteredChecklists() {
      if (!this.checklists || !this.checklists.length) return []
      let filteredLists = this.checklists.filter((c) => {
        if (this.performedByFilter) {
          return c.submittedByName === this.performedByFilter
        } else {
          return true
        }
      })
      if (this.filteredTreeIds.length) {
        // this.$log(`length=${this.filteredTreeIds.length} filtered based on trees too '${this.filteredTreeIds}'`)
        return filteredLists.filter((c) => {
          if (!c.tree) return false
          for (let i = 0; i < this.filteredTreeIds.length; i++) {
            if (!c.tree[i] || this.filteredTreeIds[i] !== c.tree[i].id) return false
          }
          return true // no reason this failed yet, so it's good!
        })
      } else {
        return filteredLists
      }
    },
  },
  methods: {
    selectChecklist(id) {
      //TODO: change the route
      this.selectedConfigChecklistId = id
      // this.$log(`selecting checklist with id ${id}, pushing route. At route ${this.$route.name}, going to ${this.$route.name}`)
      if (this.$route.params.checklistId !== id) this.$router.push({ name: this.$route.name, params: { checklistId: id } })
      this.loadingStatus = 'Loading checklists...'
      this.checklists = []
      this.getChecklists()
    },
    changeReport(type) {
      this.type = type
      this.$log(`changing report to type ${type}`)
      this.$router.push({ name: `${type}Report`, params: { checklistId: this.selectedConfigChecklistId } })
      this.key++
    },
    updateDateQuery(newDates) {
      this.date = newDates
      this.getChecklists()
    },
    updatePerformedBy(name) {
      this.performedByFilter = name
      this.getChecklists()
    },
    updateTreePath(treePath) {
      // this.$log(treePath)
      this.treePath = treePath
      this.getChecklists()
    },
    async getChecklists() {
      this.$eventBus.$emit('spinner')
      // fill the configChecklist items first
      // this.$log(`[Report2.getChecklists] for ${this.selectedConfigChecklist.name}`)
      console.time('getconfigchecklists-tree')

      this.checklistTree = null
      if (this.selectedConfigChecklist.treeId) {
        let treeRef = await this.$firestore.collection('trees').doc(this.selectedConfigChecklist.treeId).get()
        const tree = treeRef.data()
        tree.id = treeRef.id
        tree.root = JSON.parse(tree.root)
        this.checklistTree = tree
      }
      console.timeEnd('getconfigchecklists-tree')
      // get the collection of checklists, also with the filters
      console.time('queryReportData')
      const functionRef = this.$firebase.app().functions('us-central1').httpsCallable('reports-queryReportData')
      const { data: reportData } = await functionRef({
        filters: {
          date: { from: this.date.from, to: this.date.to },
          performedBy: this.performedByFilter,
          treeIds: this.filteredTreeIds,
        },
        configChecklistId: this.$route.params.checklistId,
        itemOrder: this.selectedConfigChecklist.itemOrder,
      })
      console.timeEnd('queryReportData')
      this.reportData = Object.freeze(reportData)
      this.$log(reportData)

      this.selectedConfigChecklist.items = reportData.configChecklistItems
      this.checklists.splice(0)
      this.checklists.push(...reportData.checklists)
      this.performedByOptions = reportData.allPerformedBy
      this.loadingStatus = 'No checklists were found that match that criteria.'

      this.$eventBus.$emit('spinner', false)
    },
    async getConfigChecklists() {
      console.time('getconfigchecklists')
      // this.$eventBus.$emit('spinner') no spin, this is kind of the fast one and we want the checklists to spin most of the time
      this.selectedConfigChecklistId = null
      this.configChecklists.splice(0, this.configChecklists.length)
      const configChecklists = await this.$firestore
        .collection('configChecklists')
        .where('deletedAt', '==', false)
        .where('companyId', 'in', this.$store.getters.accountCompanies)
        .get()
      configChecklists.forEach((c) => {
        // these are DocumentSnapshots
        const checklist = c.data()
        checklist.id = c.id
        checklist.items = null // this is a Firestore collection, so don't let Vue think it's there until we fill it after a query
        this.configChecklists.push(checklist)
      })
      // this.$eventBus.$emit('spinner', false) do not stop spinning, we want to spin for all checklist data too
      console.timeEnd('getconfigchecklists')
    },
    async generatePdf(orientationIn = 'portrait') {
      let page = new Pdf({ unit: 'in', format: 'letter', orientation: orientationIn })
      let filename = this.type + 'SimpleCheckReport'
      let target = this.$el
      let image = await Canvas(target, { scale: 2, logging: false })
      let originalWidth = target.clientWidth
      let originalHeight = target.clientHeight
      this.$log(`originalWidth: ${originalWidth}, originalHeight: ${originalHeight}`)
      let width = originalWidth
      let height = originalHeight
      if (width > 850) {
        height = (850 / width) * height
        width = 850
      }
      if (height > 1100) {
        width = (1100 / height) * width
        height = 1100
      }
      height /= 100
      width /= 100
      height = Number(height.toFixed(1))
      width = Number(width.toFixed(1))
      this.$log(`width: ${width}, height: ${height}`)
      // center image horizontally on the page
      const xCoordinate = Number(((8.5 - width) / 2).toFixed(1))
      page.addImage(image.toDataURL('image/jpeg'), 'JPEG', xCoordinate, 0, width, height, undefined, 'FAST')
      page.save(`${filename}.pdf`)
    },
  },
  components: {
    ReportHeader,
  },
}
</script>
<style></style>
