<template>
  <div>
    <div class="flex row reverse">
      <q-toggle v-model="tryMath" color="primary" />
      <div class="text-right" style="line-height: 40px">Sum Respenses</div>
    </div>
    <q-table v-if="!tryMath" dense :data="tableData" :columns="cols" row-key="response" :pagination.sync="pagination" />
    <div v-else class="full-width">
      <q-card class="q-mb-md">
        <q-card-section horizontal class="row">
          <div class="q-ma-sm col-6">Total responses:</div>
          <div class="q-ma-sm col-4 text-right">{{ mathData.total }}</div>
        </q-card-section>
      </q-card>
      <q-card class="q-mb-md">
        <q-card-section horizontal class="row">
          <div class="q-ma-sm col-6">Average:</div>
          <div class="q-ma-sm col-4 text-right">{{ mathData.average }}</div>
        </q-card-section>
      </q-card>
      <q-card class="q-mb-md">
        <q-card-section horizontal class="row">
          <div class="q-ma-sm col-6">Sum:</div>
          <div class="q-ma-sm col-4 text-right">{{ mathData.sum }}</div>
        </q-card-section>
      </q-card>
      <q-expansion-item v-if="mathData.nan.length" expand-separator label="Non-number Reponses" caption="These values could not be included.">
        <q-card>
          <q-card-section>
            {{ mathData.nan }}
          </q-card-section>
        </q-card>
      </q-expansion-item>
    </div>
  </div>
</template>
<script>
export default {
  props: ['data'],
  data() {
    return {
      tryMath: false,
      cols: [
        { name: 'response', label: 'Response', field: 'response', align: 'left', sortable: true },
        { name: 'count', label: 'Count', field: 'count', sortable: true },
      ],
      pagination: { rowsPerPage: 10, sortBy: 'count', descending: true },
    }
  },
  computed: {
    mathData() {
      // keys are the potential numbers, values are the number of those responses
      let result = { total: 0, sum: 0, average: 0, nan: [] }
      Object.keys(this.data).forEach((k) => {
        // see if this is a valid number. If so, include in the tally
        let num = parseFloat(k)
        if (isNaN(num)) result.nan.push(k)
        else {
          result.total += this.data[k] //increase for each occurrence
          result.sum += num * this.data[k]
        }
      })
      if (result.total > 0) result.average = (result.sum / result.total).toFixed(2)
      return result
    },
    tableData() {
      return Object.keys(this.data).map((row) => {
        return {
          response: row,
          count: this.data[row],
        }
      })
    },
  },
}
</script>
<style lang="css"></style>
