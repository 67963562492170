<template>
  <div>
    <router-view></router-view>
  </div>
</template>

<script>
import { QSpinnerFacebook } from 'quasar'
export default {
  created() {
    this.$eventBus.$on('spinner', (show = true) => {
      if (show) {
        return this.$q.loading.show({
          spinner: QSpinnerFacebook,
          backgroundColor: 'white',
          spinnerColor: 'primary',
        })
      }
      setTimeout(() => this.$q.loading.hide(), 500)
    })
  },
}
</script>
