<template lang="html">
  <div class="flex column justify-center items-center q-gutter-sm">
    <QRCodeBox size="120" v-if="dynamicJoinLink" :url="dynamicJoinLink"></QRCodeBox>
    <label class="text-primary text-center text-h4 q-mt-md">{{ company.joinCode }}</label>
    <label class="text-primary text-center text-h7 q-mt-sm">{{ dynamicJoinLink }}</label>

    <q-item-label caption :lines="3" style="max-width: 300px" class="text-center"
      >A new user can scan the QR code, enter the six digit code on the sign up screen, or follow the link above to join your company.</q-item-label
    >
    <q-card-actions>
      <q-btn unelevated no-caps rounded class="bg-secondary text-primary text-bold q-px-sm" @click="copyJoinLink">{{ isTutorial ? 'Copy' : 'Copy Link' }}</q-btn>
      <q-btn v-if="isTutorial" unelevated no-caps rounded class="bg-secondary text-primary text-bold q-px-sm" @click="emailJoinLink">Email</q-btn>
      <q-btn v-if="isTutorial" unelevated no-caps rounded class="bg-secondary text-primary text-bold q-px-sm" @click="smsJoinLink">SMS</q-btn>
      <q-btn v-if="!isTutorial" unelevated no-caps rounded class="bg-secondary text-primary text-bold" @click="$refs.codeDialog.toggle()">Regenerate Code</q-btn>
    </q-card-actions>
    <sc-confirm
      ref="codeDialog"
      @cancel="$refs.codeDialog.toggle()"
      @confirm="
        newCode()
        $refs.codeDialog.toggle()
      "
      text="Are you sure you would like to generate a new company join code? All previous links and QR codes for this company will be invalidated."
      confirmText="Generate New"
      cancelText="Cancel"
    ></sc-confirm>
  </div>
</template>

<script>
import { copyToClipboard } from 'quasar'
import QRCodeBox from '../Checklists/blocks/QRCodeBox.vue'
import DynamicLinks from '../mixins/DynamicLinks.vue'
export default {
  name: 'JoinLink',
  props: ['company', 'joinUrl', 'isTutorial'],
  components: { QRCodeBox },
  mixins: [DynamicLinks],
  data() {
    return {
      qrCodeObject: null,
      dynamicJoinLink: null,
    }
  },
  computed: {},
  async mounted() {
    this.dynamicJoinLink = await this.getJoinCodeLink(this.company.joinCode)
  },
  methods: {
    newCode() {
      let newcode = this.$joinCode()
      this.$firestore
        .collection('companies')
        .doc(this.company.id)
        .set({ joinCode: newcode }, { merge: true })
        .then(() => {
          this.$q.notify({ type: 'positive', message: 'Company Join Code Changed Successfully' })
          this.$set(this.company, 'joinCode', newcode)
        })
        .catch((error) => {
          this.$log('Error generating new company join code: ' + error)
          this.$q.notify({ type: 'danger', message: 'Something went wrong, the code was not changed.' })
        })
    },
    copyJoinCode() {
      copyToClipboard(this.company.joinCode)
        .then(() => {
          this.$q.notify({
            type: 'positive',
            message: 'Join Code Copied to Clipboard',
          })
        })
        .catch(() => {
          this.$q.notify({
            type: 'danger',
            message: 'Something went wrong, the link could not be copied.',
          })
        })
    },
    copyJoinLink() {
      copyToClipboard(this.dynamicJoinLink)
        .then(() => {
          this.$q.notify({
            type: 'positive',
            message: 'Join Link Copied to Clipboard',
          })
        })
        .catch(() => {
          this.$q.notify({
            type: 'danger',
            message: 'Something went wrong, the link could not be copied.',
          })
        })
    },
    emailJoinLink() {
      const subject = 'Simple Check Invite'
      const body = `Use this link to join ${this.company.name} on Simple Check. ${encodeURIComponent(this.dynamicJoinLink)}`
      window.open('mailto:?subject=' + subject + '&body=' + body)
    },
    smsJoinLink() {
      const body = `Use this link to join ${this.company.name} on Simple Check. ${encodeURIComponent(this.dynamicJoinLink)}`
      window.open('sms:?body=' + body)
    },
  },
}
</script>

<style lang="css"></style>
