<template>
  <div></div>
</template>
<script>
// import * as exceljs from 'exceljs'
import { exportFile } from 'quasar'
import ExcelJS from 'exceljs'
import sortBy from 'lodash/sortBy'
function wrapCsvValue(val, formatFn) {
  let formatted = formatFn !== void 0 ? formatFn(val) : val
  formatted = formatted === void 0 || formatted === null ? '' : formatted
  return formatted
}
export default {
  name: 'TableExcelExport',
  data() {
    return {}
  },
  methods: {
    async exportTable(title, dataProp = 'data', columProp = 'columns') {
      let workbook = new ExcelJS.Workbook()
      workbook.created = new Date()
      let worksheet = workbook.addWorksheet('log')
      worksheet.properties.defaultColWidth = 40
      worksheet.getCell('A1').value = title
      worksheet.getCell('A1').style = { font: { bold: true, size: 14, color: { argb: 'FF000000' }, name: 'Arial', family: 2 } }
      let wide = ['A', 'B', 'C', 'D']
      wide.forEach((col) => (worksheet.getColumn(col).width = 25))
      worksheet.getColumn('E').width = 10
      worksheet.getColumn()
      let headerrow = worksheet.getRow(3)
      let lesswide = []
      headerrow.values = this[columProp].map((col, colIdx) => {
        if (col.text && col.text.endsWith(' image')) lesswide.push(colIdx + 1)
        if (col.label && col.label.startsWith('~Q')) return col.text
        return col.label
      })
      lesswide.forEach((col) => (worksheet.getColumn(col).width = 15))
      headerrow.eachCell(function (cell) {
        cell.font = { bold: true, name: 'Times New Roman', size: 11 }
        cell.border = { bottom: { style: 'thin' } }
        cell.alignment = { wrapText: true }
      })
      let rowIdx = 4
      let sortedRows = sortBy(this[dataProp], 'startedAt').reverse()
      let hasData = {}
      let maxIdx = 0
      for (let data of sortedRows) {
        let row = worksheet.getRow(rowIdx)
        this[columProp].forEach((col, colIdx) => {
          if (colIdx > maxIdx) maxIdx = colIdx
          let value = wrapCsvValue(typeof col.field === 'function' ? col.field(data) : data[col.field === void 0 ? col.name : col.field], col.format)
          if (value.text && value.hyperlink) {
            row.getCell(colIdx + 1).value = { text: value.text, hyperlink: value.hyperlink }
            row.getCell(colIdx + 1).font = { name: 'Times New Roman', size: 11, underline: true, color: { argb: '000000EE' } }
          } else {
            row.getCell(colIdx + 1).value = value
          }
          if (value.text || (typeof value === 'string' && value.length > 0)) hasData[colIdx + 1] = true
        })
        rowIdx++
      }
      // this.$log(hasData)
      for (let i = 1; i <= maxIdx + 1; i++) {
        if (!hasData[i]) worksheet.getColumn(i).hidden = true
      }

      const status = exportFile(`${title.replace(' ', '')}-export.xlsx`, await workbook.xlsx.writeBuffer())

      if (status !== true) {
        this.$q.notify({
          message: 'Browser denied file download...',
          color: 'negative',
          icon: 'warning',
        })
      }
    },
  },
}
</script>
