<template>
  <div>
    <q-card class="qr-card q-my-xs" v-if="!checklist">
      <q-card-section>
        <div class="text-h5">Loading Checklist . . .</div>
      </q-card-section>
    </q-card>
    <div v-else>
      <q-card class="qr-card q-my-xs" v-if="!hideHeader">
        <q-card-section class="q-pb-none">
          <div class="text-h5">{{ checklist.name }}</div>
          <div class="text-subtitle2">Completed {{ checklist.completedAt | readableDate }}</div>
          <div class="text-subtitle2">Submitted by {{ firstName }}</div>
        </q-card-section>
        <q-card-section v-if="checklist.readableTreeString">
          <q-separator v-if="checklist.readableTreeString" />
          <div class="text-subtitle q-pt-md">{{ checklist.readableTreeString }}</div>
        </q-card-section>
      </q-card>

      <q-card class="qr-card q-my-sm" v-for="item in items" :key="item.id">
        <q-card-section>
          <div class="gt-sm text-h6">{{ item.name || item.description }}</div>
          <div class="lt-md text-subtitle">{{ item.name || item.description }}</div>
        </q-card-section>
        <q-separator inset />
        <q-card-section horizontal v-if="commentOrImages(item)">
          <q-card-section class="col-3" v-if="item.result.comment">{{ item.result.comment }}</q-card-section>
          <!-- <q-card-section v-for="image in item.result.images" :key="image.path" class="col-3" :src="image.url">{{ image }}</q-card-section> -->
          <a v-for="image in item.result.images" :key="image.path" class="col-3 q-ma-xs" :href="image.url" target="_blank">
            <q-img class="col-3 q-ma-xs" :src="image.url" />
          </a>
        </q-card-section>
        <q-separator inset v-if="commentOrImages(item)" />
        <q-card-section v-if="item.result.input !== null">
          <q-chip size="md">
            {{ item.result.input }}
          </q-chip>
        </q-card-section>
        <q-card-section v-else>
          <q-badge :style="{ 'background-color': item.result.selectedOption.color }" :label="item.result.selectedOption.name" />
        </q-card-section>
      </q-card>
      <q-card style="height: 400px">
        <Mapbox
          v-if="showingMap"
          class="inspection-map"
          ref="inspMap"
          :access-token="accessToken"
          :fullscreen-control="{ show: true, position: 'top-right' }"
          :map-options="{
            style: 'mapbox://styles/mapbox/streets-v9',
            center: [-96, 37.8],
            zoom: 3,
          }"
          @map-load="mapLoaded"
        />
      </q-card>
      <q-card class="qr-card q-my-sm">
        <q-card-section class="col-3" v-if="checklist && checklist.comment">{{ checklist.comment }}</q-card-section>
        <q-separator inset v-if="checklist && checklist.comment" />
        <img width="400" v-if="checklist && checklist.signature" :src="checklist.signature.url" />
      </q-card>
    </div>
  </div>
</template>

<style></style>

<script>
import Vue from 'vue'
import ChecklistMixin from '../mixins/ChecklistMixin.vue'
import Mapbox from 'mapbox-gl-vue'
const mapboxgl = require('mapbox-gl/dist/mapbox-gl')

import MapPopup from './MapPopup.vue'

export default {
  name: 'CompletedChecklist',
  components: { Mapbox },
  mixins: [ChecklistMixin],
  props: {
    checklistId: {
      type: String,
      required: false, // can be undefined, and will look at route params as per ChecklistMixin
    },
    hideHeader: {
      type: Boolean,
      required: false,
      default: false,
    },
    firstOnly: {
      type: Boolean,
      required: false,
      default: false,
    },
    itemOrder: {
      type: Array,
      required: false,
      default: () => [],
    },
  },
  data() {
    return {
      items: [],
      accessToken: process.env.VUE_APP_MAPBOX_KEY,
      mapStyle: 'mapbox://styles/mapbox/streets-v11',
      showingMap: false,
      showStatus: false,
    }
  },
  computed: {
    checklistTitle() {
      if (this.checklist) {
        return this.checklist.name
      }
      return 'Loading Checklist...'
    },
    firstName() {
      if (!this.checklist || !this.checklist.submittedByName) return null
      if (this.firstOnly) {
        let names = this.checklist.submittedByName.split(' ')
        return names[0]
      } else return this.checklist.submittedByName
    },
  },
  mounted() {
    this.loadChecklist()
  },
  methods: {
    commentOrImages(item) {
      return item.result && (item.result.comment || (item.result.images && item.result.images.length))
    },
    async loadChecklist() {
      await this.getChecklist(this.checklistId) // undefined will use the route
      if (!this.checklist) return
      if (this.checklist.deletedAt) {
        this.$q.notify({
          type: 'negative',
          message: 'This Checklist has been deleted. Please contact your account owner.',
        })
      }
      let order = this.itemOrder
      if (!this.itemOrder.length) {
        this.$log('item order not provided, need to check configChecklist')
        //if this was not provided, we need the configChecklist as well.
        //We cannot rely on the performed checklist's itemOrder becuase it might have been changed since
        const doc = await this.$firestore.collection('configChecklists').doc(this.checklist.configChecklistId).get()
        if (doc.exists) {
          order = doc.data().itemOrder
        }
      }
      this.getItems(order)
    },
    async getItems(order) {
      return this.$firestore
        .collection('checklists')
        .doc(this.checklist.id)
        .collection('items')
        .get()
        .then((snapshot) => {
          this.items = []
          if (order) {
            let unorderedItems = []
            snapshot.forEach((doc) => {
              unorderedItems.push(doc.data())
            })
            order.forEach((id) => {
              let matchItem = unorderedItems.find((item) => item.configItemId === id)
              if (matchItem) this.items.push(matchItem)
            })
          } else {
            snapshot.forEach((doc) => {
              this.items.push(doc.data())
            })
          }
          this.showMap()
        })
    },
    showMap() {
      this.showingMap = false
      setTimeout(() => {
        this.showingMap = true
      }, 250)
    },
    mapLoaded(map) {
      var points = []
      if (map) {
        let pins = []

        if (this.checklist.startLocation) {
          let pin = {
            title: 'Checklist Started',
            description: this.firstName,
            coordinates: [this.checklist.startLocation.longitude, this.checklist.startLocation.latitude],
            date: this.$options.filters.readableDate(this.checklist.startedAt),
            isMeta: true,
            color: '#009900',
          }
          pins.push(pin)
        }

        if (this.checklist.endLocation) {
          let pin = {
            title: 'Checklist Completed',
            description: this.firstName,
            coordinates: [this.checklist.endLocation.longitude, this.checklist.endLocation.latitude],
            date: this.$options.filters.readableDate(this.checklist.completedAt),
            isMeta: true,
            color: '#009900',
          }
          pins.push(pin)
        }

        this.items.forEach((item) => {
          if (item.result && item.result.location) {
            let pin = {
              title: item.name || item.description,
              description: item.result.input ? item.result.input : item.result.selectedOption.name,
              coordinates: [item.result.location.longitude, item.result.location.latitude],
              date: this.$options.filters.readableDate(item.result.markedAt),
              color: !item.result.input ? item.result.selectedOption.color || '#1976D2' : '#1976D2',
            }
            pins.push(pin)
          }
        })

        var bounds = new mapboxgl.LngLatBounds()
        pins.forEach((pin) => {
          const feat = {
            type: 'Feature',
            properties: pin,
            geometry: {
              type: 'Point',
              coordinates: pin.coordinates,
            },
          }
          points.push(feat)
          bounds.extend(pin.coordinates)
        })

        let itemCollection = {
          type: 'FeatureCollection',
          features: points,
        }
        map.addSource('items', {
          type: 'geojson',
          data: itemCollection,
        })

        // let color = ["case", ['!',['has', 'isMeta']], '#1976D2','#009900']
        // let size = ["case", ['!',['has', 'isMeta']], 5,8]

        map.addLayer({
          id: 'item-point',
          type: 'circle',
          source: 'items',
          paint: {
            'circle-color': ['get', 'color'],
            'circle-radius': 6,
            'circle-stroke-width': 0,
            'circle-stroke-color': '#fff',
          },
        })

        var openPopups = []
        map.on('mouseenter', 'item-point', function (e) {
          if (!e.features[0].properties.date) map.getCanvas().style.cursor = 'pointer'
          openPopups.push(
            new mapboxgl.Popup({
              closeButton: false,
              closeOnClick: false,
              maxWidth: 'none',
              offset: [0, -10],
            })
              .setHTML('<div id="vue-popup-content"></div>')
              .setLngLat(e.features[0].geometry.coordinates)
              .addTo(map)
          )
          var Popup = Vue.extend(MapPopup)
          new Popup({
            propsData: { pinData: e.features[0].properties },
          }).$mount('#vue-popup-content')
        })

        map.on('mouseleave', 'item-point', () => {
          map.getCanvas().style.cursor = ''
          openPopups.forEach((pop) => pop.remove())
        })

        map.fitBounds(bounds, { maxZoom: 18, padding: 100, duration: 2000 })
      }
    },
  },
}
</script>
<style lang="css">
.inspection-map {
  width: 100%;
  height: 100%;
  text-align: left;
}
</style>
