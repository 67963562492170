<template lang="html">
  <div class="q-pa-sm">
    <q-card class="q-px-sm q-py-sm row justify-between">
      <div class="flex col">
        <q-select
          style="width: 250px"
          outlined
          option-label="name"
          option-value="id"
          :value="selectedConfigChecklistId"
          @input="$emit('checklistChange', $event)"
          :options="configChecklists"
          label="Config Checklist"
          map-options
        />
        <q-select class="q-ml-md" style="width: 150px" outlined :value="type" @input="$emit('typeChange', $event)" :options="options" label="Report" emit-value />
      </div>
      <!-- <div class="flex column"> -->
      <div class="flex column col-auto items-end justify-center">
        <div class="flex items-center q-mx-xs">
          <q-chip v-if="date.from === date.to" square class="q-mx-sm">{{ moment(date.from).format('MM/DD/YYYY') }}</q-chip>
          <q-chip v-else square class="q-mx-sm">{{ moment(date.from).format('MM/DD/YYYY') }} - {{ moment(date.to).format('MM/DD/YYYY') }}</q-chip>
          <q-btn icon="fas fa-calendar-alt" class="q-mx-sm" round color="primary" size="sm">
            <q-popup-proxy @before-show="updateProxy" transition-show="scale" transition-hide="scale">
              <q-date v-model="proxyDate" range>
                <div class="row items-center justify-end q-gutter-sm">
                  <q-btn label="Cancel" color="primary" flat v-close-popup />
                  <q-btn label="OK" color="primary" @click="save" flat v-close-popup />
                </div>
              </q-date>
            </q-popup-proxy>
          </q-btn>
          <q-select
            style="min-width: 150px"
            outlined
            clearable
            dense
            :value="performedByFilter"
            @input="$emit('filterPerformedBy', $event)"
            :options="performedByOptions"
            label="Performed By"
          />

          <!-- </div> -->
        </div>
        <tree-chooser v-if="checklistTree" class="q-mt-sm" v-model="treePath" @input="$emit('filterTree', $event)" :tree="checklistTree" condensed />
      </div>
      <!-- <q-tabs v-model="tab" class="text-grey full-width q-mt-md" active-color="primary" indicator-color="primary" align="justify" stretch no-caps>
        <q-tab :ripple="false" name="details" label="Details" />
        <q-tab :ripple="false" name="qr" label="Summary" />
        <q-tab :ripple="false" name="settings" label="Completed Grid" />
        <q-tab :ripple="false" name="settings" label="Map" />
      </q-tabs> -->
    </q-card>
  </div>
</template>

<script>
import moment from 'moment'
import TreeChooser from '../../Checklists/blocks/TreeChooser'
export default {
  name: 'ReportHeader',
  props: ['type', 'configChecklists', 'selectedConfigChecklistId', 'performedByOptions', 'performedByFilter', 'checklistTree'],
  components: { TreeChooser },
  data() {
    return {
      moment,
      date: { to: moment().format('YYYY/MM/DD'), from: moment().subtract(7, 'days').format('YYYY/MM/DD') },
      proxyDate: { to: moment().format('YYYY/MM/DD'), from: moment().subtract(7, 'days').format('YYYY/MM/DD') },
      treePath: '',
      tab: 'details',
    }
  },
  watch: {
    $route(/*to, from*/) {
      this.$log('ReportHeader noticed a route change')
      // this.checkArea()
    },
  },
  mounted() {},
  methods: {
    updateProxy() {
      this.proxyDate = this.date
    },

    save() {
      // selecting a single date is a single string?
      if (!this.proxyDate.to && this.updateProxy) {
        this.date.to = this.proxyDate
        this.date.from = this.proxyDate
      } else {
        this.date = this.proxyDate
      }
      this.$emit('dateChange', this.date)
    },
    // goAreas() {
    //   let name = 'GlobalSpecAreas'
    //   if (this.forProjectId) name = 'ProjectSpecsAreas'
    //   this.$router.push({ name, params: { specId: this.spec.id } })
    // },
    // goList() {
    //   let name = 'GlobalSpecBuildings'
    //   if (this.forProjectId) name = 'ProjectSpecs'
    //   this.$router.push({ name })
    // },
    // async checkArea() {
    //   if (this.buildingId && this.areaId) {
    //     this.area = this.spec.areas.find(area => {
    //       return area.id === parseInt(this.areaId)
    //     })
    //   } else this.area = null
    // }
  },
  computed: {
    options() {
      let options = [
        {
          label: 'Details',
          value: 'Details',
          description: 'Details of completed reports',
        },
        {
          label: 'Summary',
          value: 'Summary',
          description: 'Summary of completed reports',
        },
        {
          label: 'Completed Grid',
          value: 'Completed',
          description: 'Completed checklists across dates',
        },
        {
          label: 'Grouped',
          value: 'Grouped',
          description: 'Grouped checklist result summaries',
        },
        {
          label: 'Map',
          value: 'Map',
          description: 'Completed checklists shown on map.',
        },
      ]
      if (this.$store.state.user && this.$store.state.user.isAdmin) {
        options.push({ label: 'Raw Data', value: 'RawData', description: 'All the data, just as your computer wants it' })
      }
      return options
    },
    // specName() {
    //   return this.spec && this.spec.name
    // },
    // buildingId() {
    //   return parseInt(this.$route.params.specId)
    // },
    // areaId() {
    //   return parseInt(this.$route.params.areaId)
    // }
  },
}
</script>

<style lang="sass"></style>
