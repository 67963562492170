<template>
  <div>
    <q-toolbar class="text-primary">
      <q-toolbar-title class="flex">
        <q-popup-edit v-model="selectedTree.name" auto-save @save="saveCurrentTree">
          <q-input v-model="selectedTree.name" dense autofocus maxlength="40"></q-input>
        </q-popup-edit>
        <div>{{ selectedTree.name }}</div>
        <q-btn round color="primary" class="q-ml-sm" dense size="12px" icon="fas fa-plus" @click.stop="startNewNode(selectedTree.root, selectedTree)">
          <q-tooltip anchor="center middle" self="top left"> add a new sub Location </q-tooltip>
        </q-btn>
        <q-btn
          round
          class="q-ml-sm"
          color="primary"
          size="12px"
          dense
          icon="fas fa-clipboard-list"
          @click.stop="
            pasteNodes = true
            pasteBranch = selectedTree.root
            pasteParent = selectedTree
          "
        >
          <q-tooltip anchor="center middle" self="top left"> add new sub Locations from a list </q-tooltip>
        </q-btn>
      </q-toolbar-title>

      <!-- <q-btn flat no-caps color="primary" label="Cancel" @click="cancelEdits" />
      <q-btn :flat="!anyEdits" no-caps color="primary" label="Save" @click="saveCurrentTree" /> -->
    </q-toolbar>

    <div class="q-mx-md q-mb-lg">
      <q-popup-edit v-model="selectedTree.description" auto-save @save="saveCurrentTree">
        <q-input v-model="selectedTree.description" dense autofocus maxlength="120"></q-input>
      </q-popup-edit>
      {{ selectedTree.description }}
    </div>
    <!-- <q-input ref="filter" filled v-model="filter" label="Filter">
        <template v-slot:append>
          <q-icon v-if="filter !== ''" name="clear" class="cursor-pointer" @click="resetFilter" />
        </template>
      </q-input> -->
    <!-- <div class="row q-ma-lg">
      <div class="row col-6">
        <q-btn round color="primary" class="q-mx-sm" dense size="sm" icon="fas fa-plus" @click="startNewNode(selectedTree.root)" />
        <div style="line-height: 24px; vertical-align: middle; font-size: 1.1em">New top level</div>
      </div>
    </div> -->
    <div class="row">
      <q-tree class="col-sm-8 q-pa-sm" node-key="id" :nodes="selectedTree.root" :selected.sync="selected" :expanded.sync="expanded" :filter="filter" default-expand-all>
        <template v-slot:default-header="prop">
          <div class="row items-center" style="width: 700px" @mouseover="hoverId = prop.node.id" @mouseleave="hoverId = null">
            <!-- <q-icon v-if="prop.node.children" key="branch" name="fas fa-share-alt" size="15px" class="q-mr-sm" />
            <q-icon v-else key="leaf" name="fas fa-leaf" size="15px" class="q-mr-sm" /> -->
            <div class="row items-center" @click.stop>
              <div class="text-weight-bold text-primary" style="font-size: 1.3em">{{ prop.node.label }}</div>
              <q-popup-edit v-model="prop.node.label" auto-save @save="saveCurrentTree">
                <q-input v-model="prop.node.label" dense autofocus maxlength="45"></q-input>
              </q-popup-edit>
            </div>
            <div class="row" v-show="hoverId === prop.node.id">
              <q-btn round class="q-ml-sm" color="primary" size="12px" dense icon="fas fa-plus" @click="startNewNode(prop.node.children, prop.node)" />
              <q-btn
                round
                class="q-ml-sm"
                color="primary"
                size="12px"
                dense
                icon="fas fa-clipboard-list"
                @click="
                  pasteNodes = true
                  pasteBranch = prop.node.children
                  pasteParent = prop.node
                "
              />
              <q-btn
                round
                class="q-mx-sm"
                color="primary"
                size="12px"
                dense
                icon="fas fa-trash"
                @click="
                  removeNode = prop.node
                  $refs.removeDialog.toggle()
                "
              />
            </div>
          </div>
        </template>
        <template v-slot:default-body="prop">
          <div v-if="prop.node.children"></div>
        </template>
      </q-tree>
    </div>

    <q-dialog v-model="newNode">
      <q-card>
        <q-card-section>
          <div class="text-h6">Label the new sub Location of '{{ newNodeParentName }}'</div>
        </q-card-section>

        <q-card-section class="q-pt-none">
          <q-input ref="newNodeInput" v-model="newNodeLabel" label="Label" v-on:keyup.enter="insertLeaf(newNodeParent, newNodeLabel)" maxlength="45" />
        </q-card-section>

        <q-card-actions align="right">
          <q-btn rounded flat no-caps label="Okay" class="bg-secondary text-primary text-bold" v-close-popup @click="insertLeaf(newNodeParent, newNodeLabel)" />
        </q-card-actions>
      </q-card>
    </q-dialog>
    <q-dialog v-model="pasteNodes" persistent>
      <q-card>
        <q-card-section> Type or paste new names, one on each line, to be added to '{{ pasteParent.label || pasteParent.name }}' </q-card-section>

        <q-card-section>
          <q-editor v-model="pasteText" min-height="15rem" :toolbar="[]" />
        </q-card-section>

        <q-card-actions align="right">
          <q-btn flat label="Cancel" color="primary" v-close-popup />
          <q-btn flat label="Add" color="primary" v-close-popup @click="addMultiple" :disabled="!pasteText.length" />
        </q-card-actions>
      </q-card>
    </q-dialog>
    <sc-confirm
      ref="removeDialog"
      @cancel="
        $refs.removeDialog.toggle()
        removeNode = {}
      "
      @confirm="
        remove(removeNode)
        $refs.removeDialog.toggle()
      "
      :text="'Are you sure you want to delete the Location \'' + removeNode.label + '\', and all of its sub Locations?'"
      confirmText="Remove"
    ></sc-confirm>
  </div>
</template>

<script>
import { debounce } from 'quasar'
export default {
  name: 'Tree',
  props: {
    selectedTree: { required: true, type: Object },
  },
  data() {
    return {
      hoverId: null,
      newNode: false,
      pasteNodes: false,
      pasteText: '',
      pasteBranch: null,
      pasteParent: {},
      newNodeLabel: '',
      newNodeParent: null,
      newNodeParentName: null,
      filter: '',
      selected: '',
      ticked: [],
      expanded: [],
      console,
      removeNode: {},
    }
  },
  watch: {
    selectedTreeId() {
      this.$log('selectedTreeId changes')
      this.expanded = []
      this.selected = ''
      // expand all
    },
  },
  methods: {
    addMultiple() {
      // Quasar editor lists things as HTML. We do not show editor bar. Each line is within own <div> tags
      // first, remove bad text
      var regex = new RegExp('(</.*?>)|&nbsp;', 'gi')
      let formatted = this.pasteText.replace(regex, '')
      let lines = formatted.split('<div>')
      if (!this.pasteBranch) {
        this.pasteParent.children = []
        this.pasteBranch = this.pasteParent.children
        this.expanded.push(this.pasteParent.id)
      }
      this.$log(`adding multiple! ${this.pasteBranch.length} children already`)
      lines.forEach((name) => {
        this.insertLeaf(this.pasteBranch, name.trim().substring(0, 45))
      })
      this.pasteNodes = null
      this.pasteText = ''
    },
    rename(branch, name, root) {
      if (!root) {
        this.$log(`rename branch to ${name} using root of this.selectedTree`)
        root = { label: 'TOPLEVEL', children: this.selectedTree.root }
      }
      // search the entire tree for a matching index. Can we find a match?
      const idx = root.children.findIndex((t) => t === branch)
      if (idx === -1) {
        // not found, check the children
        for (const t of root.children) {
          if (t.children) this.rename(branch, name, t) // do not check leaves
        }
      } else {
        if (branch.children && branch.children.length) {
          this.$log('this is a branch')
        } else this.$log('a leaf')
        this.$log(`node was found as a child of ${root.label || 'TOPLEVEL'}`)
        root.children[idx].label = name
        this.selected = name
      }
      this.saveCurrentTree()
    },
    remove(branch, root) {
      if (!root) {
        this.$log(`removing branch ${branch.label} using root of this.selectedTree`)
        root = { label: 'root', children: this.selectedTree.root }
      }
      // search the entire tree for a matching index. Can we find a match?
      const idx = root.children.findIndex((t) => t === branch)
      if (idx === -1) {
        // not found, check the children
        for (const t of root.children) {
          if (t.children) this.remove(branch, t) // do not check leaves
        }
      } else {
        if (branch.children && branch.children.length) {
          this.$log('this is a branch')
        } else this.$log('a leaf')
        this.$log(`node was found as a child of ${root.label || 'TOPLEVEL'}`)
        root.children.splice(idx, 1)
        const expandedIdx = this.expanded.find((e) => e === branch.id)
        if (expandedIdx) this.expanded.splice(expandedIdx, 1)
        this.saveCurrentTree()
      }
      this.removeNode = {}
    },
    startNewNode(branch, branchParent) {
      if (!branch) {
        branchParent.children = []
        this.expanded.push(branchParent.id)
        branch = branchParent.children
      }
      this.newNodeParentName = branchParent.label || branchParent.name
      this.newNodeParent = branch
      this.newNode = true
      setTimeout(() => {
        if (this.$refs.newNodeInput) this.$refs.newNodeInput.focus()
      }, 300)
    },
    insertLeaf(branch, newName) {
      if (!newName || newName.length === 0) return
      const newleaf = { label: newName, id: this.$nanoid(8) }
      if (!branch.children || !branch.children.length) branch.children = []
      branch.push(newleaf)
      this.selected = newleaf.id
      this.newNode = false
      this.newNodeLabel = ''
      this.saveCurrentTree()
    },
    makeFolder(leaf) {
      this.$log('makeFolder')
      this.$log(leaf)
      if (!leaf.children || !leaf.children.length) leaf.children = []
      this.expanded.push(leaf.id)
    },
    resetFilter() {
      this.filter = ''
      this.$refs.filter.focus()
    },
    filterUpdated: debounce(function () {
      this.$emit('filterUpdated')
      this.fetchHeaderInfo()
    }, 200),
    saveCurrentTree: debounce(function () {
      //sort the entire tree too!
      this.sortBranch(this.selectedTree.root)
      this.$emit('save')
    }, 250),
    sortBranch(branch) {
      function compareFn(a, b) {
        if (a.label < b.label) return -1
        if (a.label > b.label) return 1
        return 0
      }
      branch.sort(compareFn)
      branch.forEach((node) => {
        if (node.children) this.sortBranch(node.children)
      })
    },
    cancelEdits() {
      this.$emit('cancel')
    },
  },
}
</script>
