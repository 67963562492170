import { render, staticRenderFns } from "./Welcome.vue?vue&type=template&id=db8533ea&scoped=true&"
import script from "./Welcome.vue?vue&type=script&lang=js&"
export * from "./Welcome.vue?vue&type=script&lang=js&"
import style0 from "./Welcome.vue?vue&type=style&index=0&id=db8533ea&lang=css&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "db8533ea",
  null
  
)

export default component.exports
import QCard from 'quasar/src/components/card/QCard.js';
import QItemLabel from 'quasar/src/components/item/QItemLabel.js';
import QSeparator from 'quasar/src/components/separator/QSeparator.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import qInstall from "../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(component, 'components', {QCard,QItemLabel,QSeparator,QBtn});
