import Vue from 'vue'
import Container from './Container.vue'
import router from './router'
import EventBus from './eventbus.js'
import store from './store'
import * as moment from 'moment'
import ConfirmDialog from './components/blocks/ConfirmDialog.vue'
import IconPickerDialog from './components/blocks/IconPickerDialog.vue'
import { nanoid, customAlphabet } from 'nanoid'
import SearchBar from './components/blocks/SearchBar.vue'
import faIconsModal from 'vue-font-awesome-picker'
import * as firebase from 'firebase'
import LogRocket from 'logrocket'
import VueGtm from '@gtm-support/vue2-gtm'
import VueMobileDetection from 'vue-mobile-detection'

import 'mapbox-gl/dist/mapbox-gl.css'
import './quasar'

const isProduction = process.env.VUE_APP_IS_PRODUCTION
if (isProduction) {
  console.log('LogRocket initialized.')
  // only using this in Production
  LogRocket.init('w6vp8b/simple-check-dev')
} else console.log('Not production, not using LogRocket.')

Vue.use(faIconsModal)
Vue.use(VueMobileDetection)

Vue.use(VueGtm, {
  id: 'GTM-N5XPBLN', // Your GTM single container ID or array of container ids ['GTM-xxxxxx', 'GTM-yyyyyy'] or array of objects [{id: 'GTM-xxxxxx', queryPararms: { gtm_auth: 'abc123', gtm_preview: 'env-4', gtm_cookies_win: 'x'}}, {id: 'GTM-yyyyyy', queryParams: {gtm_auth: 'abc234', gtm_preview: 'env-5', gtm_cookies_win: 'x'}}]
  defer: false, // defaults to false. Script can be set to `defer` to increase page-load-time at the cost of less accurate results (in case visitor leaves before script is loaded, which is unlikely but possible)
  enabled: true, // defaults to true. Plugin can be disabled by setting this to false for Ex: enabled: !!GDPR_Cookie (optional)
  debug: true, // Whether or not display console logs debugs (optional)
  loadScript: true, // Whether or not to load the GTM Script (Helpful if you are including GTM manually, but need the dataLayer functionality in your components) (optional)
  vueRouter: router, // Pass the router instance to automatically sync with router (optional)
  ignoredViews: [], // Don't trigger events for specified router names (case insensitive) (optional)
  trackOnNextTick: false, // Whether or not call trackView in Vue.nextTick
})

Vue.component('sc-confirm', ConfirmDialog)
Vue.component('sc-icon-picker', IconPickerDialog)
Vue.component('sc-search', SearchBar)

Vue.config.productionTip = false

const configOptions = {
  apiKey: process.env.VUE_APP_FIREBASE_API_KEY,
  authDomain: process.env.VUE_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.VUE_APP_FIREBASE_DATABBASE_URL,
  storageBucket: process.env.VUE_APP_FIREBASE_STORAGE_BUCKET,
  projectId: process.env.VUE_APP_FIREBASE_PROJECT_ID,
  messagingSenderId: '868044077224',
  appId: '1:868044077224:web:f5fb1d2d7612ba2f1caa9d',
  measurementId: 'G-8TG9VK3QQE',
}

firebase.initializeApp(configOptions)

firebase.analytics()
require('firebase/functions')

firebase.auth().onAuthStateChanged(async (user) => {
  console.log('onAuthStateChanged. Now authed?')
  await store.dispatch('fetchUser', user)
  if (user) {
    console.log(`Setting LogRocket.identify() ${user.email}`)
    LogRocket.identify(user.uid, {
      name: user.displayName,
      email: user.email,
    })
  }
})

Vue.prototype.$androidDownloadLink = 'https://play.google.com/store/apps/details?id=com.cms.simplecheck'
Vue.prototype.$appleDownloadLink = 'https://apps.apple.com/us/app/simple-check-app/id1542613005'

Vue.prototype.$firebase = firebase
Vue.prototype.$firestore = firebase.firestore()
Vue.prototype.$fieldValue = firebase.firestore.FieldValue
Vue.prototype.$functions = firebase.functions()
// firebase.firestore.setLogLevel("debug");

Vue.prototype.$moment = moment
Vue.prototype.$eventBus = EventBus
Vue.prototype.$nanoid = nanoid
Vue.prototype.$joinCode = customAlphabet('1234567890ABCDEFGHIJKLMNOPQRSTUVWXYZ', 6)

Vue.prototype.$log = function () {
  if (process.env.VUE_APP_DEV) console.log(...arguments)
}

Vue.prototype.$isIOS = function () {
  const ua = navigator.userAgent
  if (/android/i.test(ua)) {
    return false
  } else if (/iPad|iPhone|iPod/.test(ua) || (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1)) {
    return true
  }
  return false
}

Vue.filter('readableDate', function (timestamp, hideTime) {
  if (!timestamp) return 'Invalid Date'
  var time = timestamp instanceof firebase.firestore.Timestamp ? timestamp.toDate() : timestamp
  const format = hideTime ? 'MMMM Do, YYYY' : 'MMMM Do YYYY, HH:mm'
  return moment(time).format(format)
})

Vue.filter('price', function (cents) {
  return '$' + Number(cents / 100).toLocaleString()
})

Vue.filter('trunc', function (value, count = 40) {
  if (!value) return ''
  return value.length < count ? value : value.slice(0, count)
})

new Vue({
  router,
  store,
  render: (h) => h(Container),
}).$mount('#app')
