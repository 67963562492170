<template>
  <q-dialog v-model="showing" :persistent="hasEdits">
    <q-card class="item-card" v-if="showing">
      <q-card-section class="q-gutter-md">
        <div class="col text-h6">
          {{ title }}
        </div>
        <q-input v-model="newItem.name" placeholder="Item Name" outlined dense maxlength="120" />
        <q-input v-model="newItem.description" placeholder="Item Description" outlined dense maxlength="240" />
        <q-card class="q-pa-md">
          <q-btn-toggle
            v-model="newItem.type"
            class="q-mb-sm"
            style="border: 1px solid lightgray"
            no-caps
            rounded
            unelevated
            toggle-color="primary"
            color="white"
            text-color="primary"
            :options="[
              { label: 'Predefined Options', value: 'options' },
              { label: 'Text Input', value: 'input' },
            ]"
          />
          <q-separator />
          <OptionsEditor
            v-if="newItem.type === 'options'"
            :options="newItem.options"
            @updateOptions="updateOptions($event)"
            description="This item will show predefined single choice options that you define here."
          />
          <div class="flex row q-py-md" style="width: 100%" v-else>
            <q-item-label caption>This item will use a text field to record it's results. You can set the text field placeholder below.</q-item-label>
            <q-input class="q-mt-sm full-width" placeholder="Placeholder" v-model="newItem.input" outlined dense maxlength="30" />
          </div>
        </q-card>

        <q-card class="flex space-between column q-pa-sm">
          <q-item-label header class="q-pa-sm">Requirements</q-item-label>
          <div style="width: 100%" class="flex items-start q-gutter-x-sm">
            <q-toggle v-model="newItem.required" label="Required. This item must be checked in order to submit checklist." />
            <q-toggle v-model="newItem.photoRequired" label="Photo Required. If this item is checked, a photo must be attached." />
          </div>
        </q-card>
        <q-card class="flex space-between column q-pa-md">
          <q-item-label header class="q-pa-none">Resources</q-item-label>
          <q-item-label caption>Resource documents to correctly identify an items compliance.</q-item-label>
          <q-uploader style="max-width: 300px" url="http://localhost:4444/upload" label="Resource Documents" multiple accept=".pdf, .jpg, image/*" />

          <!-- <q-file class="q-mt-sm" outlined v-model="sopDocument" label="Choose File" hint=".pdf .png .jpg .jpeg" accept=".pdf, .png, .jpg, .jpeg">
            <template v-slot:prepend>
              <q-icon name="fas fa-paperclip" />
            </template>
          </q-file> -->
        </q-card>
      </q-card-section>
      <q-card-actions class="relative-bottom" align="right">
        <q-btn rounded flat no-caps color="negative" label="Cancel" @click="confirmCancel" />
        <q-btn no-caps rounded unelevated class="bg-secondary text-primary text-bold" :disabled="!hasEdits" :label="isEditing ? 'Save' : 'Create Item'" @click="action" />
      </q-card-actions>
    </q-card>
    <sc-confirm
      ref="confirmDialog"
      @cancel="$refs.confirmDialog.toggle()"
      @confirm="cancel"
      text="Are you sure you would like to discard your changes?"
      cancelText="Back"
      confirmText="Discard Changes"
    ></sc-confirm>
  </q-dialog>
</template>

<style></style>

<script>
import OptionsEditor from '../blocks/OptionsEditor.vue'
export default {
  name: 'ItemModal',
  components: { OptionsEditor },
  props: {
    item: {
      type: Object,
      required: false,
    },
  },
  data() {
    return {
      showing: null,
      originalItem: {},
      newItem: {},
      isEditing: false,
    }
  },
  computed: {
    title() {
      return this.isEditing ? 'Edit - ' + this.originalItem.name : 'New Item'
    },
    hasEdits() {
      return JSON.stringify(this.originalItem) !== JSON.stringify(this.newItem)
    },
  },
  mounted() {},
  methods: {
    toggle(item, checklist) {
      if (item) {
        this.isEditing = true
        this.newItem = item
      } else {
        this.isEditing = false
        this.newItem = {
          required: false,
          photoRequired: false,
          type: checklist.defaultType || 'options',
          input: checklist.defaultInput || 'Input',
          options: checklist.defaultOptions.map((o) => {
            let newO = JSON.parse(JSON.stringify(o))
            newO.id = this.$nanoid()
            return newO
          }),
        }
      }
      this.originalItem = JSON.parse(JSON.stringify(this.newItem))
      this.showing = !this.showing
    },
    updateType(event) {
      this.$log('event: ' + event)
      this.newItem.type = event
    },
    updateOptions(event) {
      this.newItem.options = JSON.parse(JSON.stringify(event))
    },
    action() {
      if ((!this.newItem.name || this.newItem.name.length === 0) && (!this.newItem.description || this.newItem.description.length === 0)) {
        return this.$q.notify({ type: 'warning', message: 'Name and Description may not both be empty.' })
      }
      if (this.newItem.type === 'options' && this.newItem.options.some((o) => !o.name || o.name.length === 0)) {
        return this.$q.notify({ type: 'warning', message: 'Options cannot have empty names.' })
      }
      if (this.isEditing) this.updateItem()
      else this.createItem()
    },
    createItem() {
      const checklistRef = this.$firestore.collection('configChecklists').doc(this.$route.params.checklistId)
      var newItem = checklistRef.collection('items').doc()
      this.newItem.itemId = newItem.id
      newItem
        .set(this.newItem)
        .then(() => {
          return checklistRef.update({ itemOrder: this.$fieldValue.arrayUnion(this.newItem.itemId) })
        })
        .then(() => {
          this.$refs.confirmDialog.toggle()
          this.showing = false
          this.$emit('close')
          this.$q.notify({
            type: 'positive',
            message: (this.newItem.name || 'Item') + ' Created',
          })
        })
    },
    updateItem() {
      // this.$log('item: ' + JSON.stringify(this.newItem, null, 2))
      this.$firestore
        .collection('configChecklists')
        .doc(this.$route.params.checklistId)
        .collection('items')
        .doc(this.newItem.itemId)
        .update(this.newItem)
        .then(() => {
          this.showing = false
          this.$emit('close')
          this.$q.notify({
            type: 'positive',
            message: this.originalItem.name + ' Updated',
          })
        })
    },
    confirmCancel() {
      if (this.hasEdits) this.$refs.confirmDialog.toggle()
      else this.showing = false
    },
    cancel() {
      this.$refs.confirmDialog.toggle()
      this.showing = false
    },
  },
}
</script>
<style lang="sass" scoped>
.item-card
  width: 600px
  height: auto
</style>
