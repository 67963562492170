import Vue from 'vue'
import VueRouter from 'vue-router'
import App from '../App.vue'
import NotFound from '../components/NotFound.vue'
import Home from '../components/Home.vue'
import Checklist from '../components/Checklists/Checklist.vue'
import Checklists from '../components/Checklists/Checklists.vue'
import Report2 from '../components/Reports/Report2.vue'
import RawDataReport from '../components/Reports/RawDataReport.vue'
import SummaryReport from '../components/Reports/SummaryReport.vue'
import CompletedReport from '../components/Reports/CompletedReport.vue'
import MapReport from '../components/Reports/MapReport.vue'
import DetailsReport from '../components/Reports/DetailsReport.vue'
import GroupedReport from '../components/Reports/GroupedReport.vue'
import Account from '../components/Account/Account.vue'
import AccountSetup from '../components/Account/Setup.vue'
import Dashboard from '../components/Account/Dashboard.vue'
import ActiveChecklist from '../components/Active/ActiveChecklist.vue'
import StartChecklist from '../components/Active/StartChecklist.vue'
import Enrolled from '../components/Active/Enrolled.vue'
import ViewCompletedChecklist from '../components/Active/ViewCompletedChecklist.vue'

import Users from '../components/Users/Users.vue'
import Login from '../components/Auth/Login.vue'
import Buy from '../components/Auth/Buy.vue'
import TreeList from '../components/Tree/TreeList.vue'

import Welcome from '../components/Auth/Welcome.vue'

import store from '../store'

Vue.use(VueRouter)

const routes = [
  { path: '*', component: NotFound },

  {
    path: '/AccountSetup',
    name: 'AccountSetup',
    component: AccountSetup,
    meta: { parent: '', title: 'Account Setup' },
  },
  {
    path: '/Login',
    name: 'Login',
    component: Login,
    meta: { parent: '', title: 'Login' },
  },
  {
    path: '/Buy',
    name: 'Buy',
    component: Buy,
    meta: { parent: '', title: 'Buy' },
  },
  {
    path: '/Welcome',
    name: 'Welcome',
    component: Welcome,
    meta: { parent: 'Welcome', title: 'Welcome' },
  },
  {
    path: '/',
    component: App,
    meta: { parent: 'root' },
    children: [
      {
        path: 'dashboard',
        name: 'Dashboard',
        component: Dashboard,
        meta: { parent: 'Admin', title: 'Admin' },
      },
      {
        path: 'Checklists',
        name: 'Checklists',
        component: Checklists,
        meta: { parent: 'Checklists', title: 'Checklist Manager', requireAccount: true },
      },
      {
        path: 'Locations',
        name: 'Locations',
        component: TreeList,
        meta: { parent: 'Locations', title: 'Locations', requireAccount: true },
      },
      {
        path: 'checklist/:checklistId',
        name: 'Checklist',
        props: true,
        component: Checklist,
        meta: { parent: 'Checklists', title: 'Edit Checklist', requireAccount: true },
      },
      {
        path: 'Reports',
        name: 'Reports',
        component: Report2,
        meta: { parent: 'Reports', requireAccount: true },
        children: [
          {
            path: ':checklistId/rawdata',
            name: 'RawDataReport',
            meta: { parent: 'Reports', requireAccount: true },
            component: RawDataReport,
            props: true,
          },
          {
            path: ':checklistId/summary',
            name: 'SummaryReport',
            meta: { parent: 'Reports', requireAccount: true, title: 'Report - Summary' },
            component: SummaryReport,
            props: true,
          },
          {
            path: ':checklistId/map',
            name: 'MapReport',
            meta: { parent: 'Reports', requireAccount: true, title: 'Report - Map' },
            component: MapReport,
            props: true,
          },
          {
            path: ':checklistId/completed',
            name: 'CompletedReport',
            meta: { parent: 'Reports', requireAccount: true, title: 'Report - Completed' },
            component: CompletedReport,
            props: true,
          },

          {
            path: ':checklistId/details',
            name: 'DetailsReport',
            meta: { parent: 'Reports', requireAccount: true, title: 'Report - Details' },
            component: DetailsReport,
            props: true,
          },

          {
            path: ':checklistId/grouped',
            name: 'GroupedReport',
            meta: { parent: 'Reports', requireAccount: true, title: 'Report - Grouped' },
            component: GroupedReport,
            props: true,
          },
        ],
      },
      {
        path: 'Enrolled',
        name: 'Enrolled',
        component: Enrolled,
        meta: { parent: 'Enrolled', title: 'Enrolled' },
      },
      {
        path: 'Users',
        name: 'Users',
        component: Users,
        meta: { parent: 'Users', requireAccount: true, title: 'Users' },
      },
      {
        path: 'Account',
        name: 'Account',
        component: Account,
        meta: { parent: 'Account', title: 'Account' },
      },
      { path: '', component: Home, meta: { parent: 'Home' } },
      {
        path: 'Home',
        name: 'Home',
        component: Home,
        meta: { parent: 'Home' },
      },
    ],
  },
  {
    path: '/startchecklist/:checklistId',
    name: 'StartChecklist',
    component: StartChecklist,
    meta: { parent: '', independent: true },
  },
  {
    path: '/completed/:checklistId',
    name: 'ViewCompletedChecklist',
    component: ViewCompletedChecklist,
    meta: { parent: '', independent: true },
  },
  {
    path: '/activechecklist/:id',
    name: 'ActiveChecklist',
    component: ActiveChecklist,
    meta: { parent: '' },
  },
]

export const router = new VueRouter({
  routes,
})

router.beforeEach((to, from, next) => {
  if (to.name !== 'Login' && to.name !== 'AccountSetup' && to.name !== 'Buy' && !store.state.user.loggedIn && !to.meta.independent) {
    next({ name: 'Login', query: { redirect: encodeURI(to.path) } })
  } else if (to.meta.requireAccount && !store.getters.accountCompanies.length) {
    console.log(`Route ${to.name} is for account holders only, redirecting Home`)
    next({ name: 'Home' })
  } else next()
})

const DEFAULT_TITLE = 'Simple Check'
router.afterEach((to) => {
  // Use next tick to handle router history correctly
  // see: https://github.com/vuejs/vue-router/issues/914#issuecomment-384477609
  Vue.nextTick(() => {
    document.title = to.meta.title || DEFAULT_TITLE
  })
})

export default router
