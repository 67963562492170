import { render, staticRenderFns } from "./Buy.vue?vue&type=template&id=2b4821e0&scoped=true&"
import script from "./Buy.vue?vue&type=script&lang=js&"
export * from "./Buy.vue?vue&type=script&lang=js&"
import style0 from "./Buy.vue?vue&type=style&index=0&id=2b4821e0&lang=css&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2b4821e0",
  null
  
)

export default component.exports
import QBtnToggle from 'quasar/src/components/btn-toggle/QBtnToggle.js';
import QCard from 'quasar/src/components/card/QCard.js';
import QItemLabel from 'quasar/src/components/item/QItemLabel.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import qInstall from "../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(component, 'components', {QBtnToggle,QCard,QItemLabel,QBtn});
