import * as firebase from 'firebase'

const state = {
  ready: false,
  company: null,
  subscription: null,
  product: null,
  price: null,
  notOwner: true,
}
const getters = {
  company: (state) => state.company,
  subscription: (state) => state.subscription,
  product: (state) => state.product,
  price: (state) => state.price,
  notOwner: (state) => state.notOwner,
}
const actions = {
  async fetchCompany({ commit }, companyId) {
    commit('resetCompany')
    if (!companyId) return
    let companyDocRef = await firebase.firestore().collection('companies').doc(companyId).get()
    if (!companyDocRef.exists) return
    let company = companyDocRef.data()
    company.id = companyDocRef.id
    // also, you gotta check for a valid subscription.

    let snapshot = await firebase
      .firestore()
      .collection('companies')
      .doc(company.id)
      .collection('subscriptions')
      .where('status', 'in', ['trialing', 'active', 'canceled', 'past_due']) // incomplete and incomplete_expired and unpaid are not handled
      .orderBy('created', 'desc') // always work with the latest subscription
      .get()
    // set these after finding the snapshot to avoid network delay of having company without subscription
    commit('setCompany', company)
    commit('setNotOwner', false)
    if (snapshot.empty) return console.log(`Subscription was empty for company ${companyId}. Setting to null.`)
    // There might be more than one! Query was ordered by the latest
    const subscription = snapshot.docs[0].data()
    console.log(`found subscription (of ${snapshot.size}) with status ${subscription.status}`)
    commit('setSubscription', subscription)
    let [productRef, priceRef] = await Promise.all([subscription.product.get(), subscription.price.get()])
    if (productRef.exists) commit('setProduct', productRef.data())
    if (priceRef.exists) commit('setPrice', priceRef.data())
  },
}
const mutations = {
  setCompany(state, company) {
    state.company = company
  },
  setSubscription(state, subscription) {
    state.subscription = subscription
  },
  setProduct(state, product) {
    state.product = product
  },
  setPrice(state, price) {
    state.price = price
  },
  setNotOwner(state, notOwner) {
    state.notOwner = notOwner
  },
  resetCompany(state) {
    state.company = null
    state.subscription = null
    state.product = null
    state.price = null
    state.notOwner = true
  },
}
export default {
  state,
  getters,
  actions,
  mutations,
}
