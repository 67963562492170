<script>
import moment from 'moment'
import { mapGetters } from 'vuex'
export default {
  data() {
    return {
      tasks: [],
    }
  },
  computed: {
    usagePercentage() {
      if (!this.$store.state.company.company || !this.$store.state.company.company.limits) return 0
      if (this.$store.state.company.company.limits.completedChecklists === 0) return 0
      const percentage = Math.round((this.$store.state.company.company.limits.completedChecklists / this.$store.state.company.company.limits.checklistLimit) * 100)
      return percentage
    },
    checklistsUsed() {
      if (!this.$store.state.company.company || !this.$store.state.company.company.limits) return '0/100'
      return this.$store.state.company.company.limits.completedChecklists.toLocaleString() + '/' + this.$store.state.company.company.limits.checklistLimit.toLocaleString()
    },
    completedTaskCount() {
      return this.tasks.filter((task) => task.status === 'complete').length
    },
    tasksRemainingCount() {
      return this.tasks.length - this.completedTaskCount
    },
    isTrialing() {
      return this.subscription && this.subscription.status === 'trialing'
    },
    daysRemainingInFreeTrial() {
      if (!this.subscription || this.subscription.status === 'active') return 500
      if (this.subscription.trial_end === this.subscription.trial_start) return 500 // on first signup, sometimes Stripe hooks are slow so do not show anything if company still in setup mode
      const timestamp = this.subscription.trial_end
      const trialEnd = timestamp instanceof this.$firebase.firestore.Timestamp ? timestamp.toDate() : timestamp
      return moment(trialEnd).diff(moment(), 'days') + 1
    },
    ...mapGetters(['company', 'subscription']),
  },
  mounted() {
    this.$eventBus.$on('refresh', this.refreshy)
  },
  beforeDestroy() {
    this.$eventBus.$off('refresh', this.refreshy)
  },
  methods: {
    refreshy() {
      this.getCompany()
    },
    async getCompany() {
      if (!this.$store.state.user || !this.$store.state.user.data || !this.$store.state.user.data.id) return
      if (!this.$store.getters.accountCompanies.length) return
      await this.$store.dispatch('fetchCompany', this.$store.getters.accountCompanies[0])
    },
    watchCompanyTasks() {
      if (!this.company) return
      return this.$firestore
        .collection('companies')
        .doc(this.company.id)
        .collection('tasks')
        .onSnapshot((snapshot) => {
          this.tasks = snapshot.docs.map((doc) => doc.data())
        })
    },
  },
}
</script>
