<template lang="html">
  <q-card class="q-pa-md" style="height: 600px">
    <label class="text-h6 q-mb-sm">{{ titleText }}</label>
    <Mapbox
      v-if="showingMap"
      class="inspection-map"
      ref="inspMap"
      id="map"
      :access-token="accessToken"
      :fullscreen-control="{ show: true, position: 'top-right' }"
      :map-options="{
        style: 'mapbox://styles/mapbox/streets-v9',
        center: [-96, 37.8],
        zoom: 3,
      }"
      @map-load="mapLoaded"
    />
  </q-card>
</template>

<script>
import Vue from 'vue'
import { copyToClipboard } from 'quasar'
import Mapbox from 'mapbox-gl-vue'
const mapboxgl = require('mapbox-gl/dist/mapbox-gl')
import MapPopup from '../blocks/MapPopup.vue'

export default {
  name: 'DetailsReport',
  components: { Mapbox },
  props: ['configChecklist', 'reportData'],
  data() {
    return {
      showingMap: false,
      accessToken: process.env.VUE_APP_MAPBOX_KEY,
      mapStyle: 'mapbox://styles/mapbox/streets-v11',
      count: 0,
    }
  },
  mounted() {
    this.$log('DetailsReport mounted, building columns with configChecklist ' + this.configChecklist.name)
    this.showMap()
  },
  computed: {
    titleText() {
      return `${this.count} Checklists available (with GPS data)`
    },
  },
  watch: {
    checklists() {
      this.showMap()
    },
  },
  methods: {
    gotoUrl(link) {
      window.open(link, '_blank')
    },
    copyUrl(link) {
      copyToClipboard(link)
        .then(() => {
          this.$q.notify({
            type: 'positive',
            message: 'Link Copied to Clipboard',
          })
        })
        .catch(() => {
          this.$q.notify({
            type: 'danger',
            message: 'Something went wrong, the link could not be copied.',
          })
        })
    },
    showMap() {
      this.showingMap = false
      setTimeout(() => {
        this.showingMap = true
      }, 500)
    },
    mapLoaded(map) {
      var points = []
      if (map && this.reportData) {
        this.count = 0
        let pins = []
        this.reportData.checklists.forEach((c) => {
          const location = c.endLocation || c.startLocation
          if (location) {
            let pin = {
              title: 'Checklist Completed',
              subtitle: c.submittedByName || 'Anon',
              description: c.readableTreeString,
              coordinates: [location.longitude, location.latitude],
              date: this.$options.filters.readableDate(c.completedAt),
              link: window.location.origin + '/' + this.$router.resolve(`/completed/${c.id}`).href,
              isMeta: true,
              color: '#1976D2',
            }
            pins.push(pin)
            this.count++
          }
        })

        var bounds = new mapboxgl.LngLatBounds()
        pins.forEach((pin) => {
          const feat = {
            type: 'Feature',
            properties: pin,
            geometry: {
              type: 'Point',
              coordinates: pin.coordinates,
            },
          }
          points.push(feat)
          bounds.extend(pin.coordinates)
        })

        let itemCollection = {
          type: 'FeatureCollection',
          features: points,
        }
        map.addSource('checklists', {
          type: 'geojson',
          data: itemCollection,
        })

        map.addLayer({
          id: 'c-point',
          type: 'circle',
          source: 'checklists',
          paint: {
            'circle-color': ['get', 'color'],
            'circle-radius': 8,
            'circle-stroke-width': 1,
            'circle-stroke-color': '#fff',
          },
        })

        map.on('click', 'c-point', function (e) {
          const link = e.features[0].properties.link
          if (link) {
            window.open(link, '_blank')
          }
        })

        var openPopups = []
        map.on('mouseenter', 'c-point', function (e) {
          if (e.features[0].properties.link) map.getCanvas().style.cursor = 'pointer'
          openPopups.push(
            new mapboxgl.Popup({
              closeButton: false,
              closeOnClick: false,
              maxWidth: 'none',
              offset: [0, -10],
            })
              .setHTML('<div id="vue-popup-content"></div>')
              .setLngLat(e.features[0].geometry.coordinates)
              .addTo(map)
          )
          var Popup = Vue.extend(MapPopup)
          new Popup({
            propsData: { pinData: e.features[0].properties },
          }).$mount('#vue-popup-content')
        })

        map.on('mouseleave', 'c-point', () => {
          map.getCanvas().style.cursor = ''
          openPopups.forEach((pop) => pop.remove())
        })

        map.fitBounds(bounds, { maxZoom: 18, padding: 100, duration: 2000 })
      }
    },
  },
}
</script>

<style lang="css">
.inspection-map {
  width: 100%;
  /* height: 650px; */
  text-align: left;
}
#map {
  height: 525px;
}
</style>
