<template>
  <q-layout view="hHh lpR fFf">
    <q-page-container>
      <q-page class="flex flex-center">
        <div v-if="!checklist" class="text-h5">Loading...</div>
        <div v-else class="q-pa-md col-md-8 col-xs-12">
          <completed-checklist />
        </div>
      </q-page>
    </q-page-container>
  </q-layout>
</template>

<style></style>

<script>
import ChecklistMixin from '../mixins/ChecklistMixin.vue'
import CompletedChecklist from '../blocks/CompletedChecklist.vue'

export default {
  name: 'ViewCompletedChecklist',
  mixins: [ChecklistMixin],
  components: { CompletedChecklist },
  data() {
    return {
      items: []
    }
  },
  computed: {
    checklistTitle() {
      if (this.checklist) {
        return this.checklist.name
      }
      return 'Loading Checklist...'
    },
  },
  mounted() {
    this.loadChecklist()
  },
  methods: {
    commentOrImages(item) {
      return item.result && (item.result.comment || (item.result.images && item.result.images.length))
    },
    async loadChecklist() {
      await this.getChecklist()
      if (!this.checklist) return
      if (this.checklist.deletedAt) {
        this.$q.notify({
          type: 'negative',
          message: 'This Checklist has been deleted. Please contact your account owner.',
        })
      }
      this.getItems()
    },
    getItems() {
      this.$firestore
        .collection('checklists')
        .doc(this.checklist.id)
        .collection('items')
        .get()
        .then((snapshot) => {
          this.items = []
          snapshot.forEach((doc) => {
            this.items.push(doc.data())
          })
        })
    },
  },
}
</script>
