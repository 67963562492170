import { render, staticRenderFns } from "./Checklist.vue?vue&type=template&id=3915532a&"
import script from "./Checklist.vue?vue&type=script&lang=js&"
export * from "./Checklist.vue?vue&type=script&lang=js&"
import style0 from "./Checklist.vue?vue&type=style&index=0&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports
import QPage from 'quasar/src/components/page/QPage.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QCard from 'quasar/src/components/card/QCard.js';
import QTabs from 'quasar/src/components/tabs/QTabs.js';
import QTab from 'quasar/src/components/tabs/QTab.js';
import QTabPanels from 'quasar/src/components/tab-panels/QTabPanels.js';
import QTabPanel from 'quasar/src/components/tab-panels/QTabPanel.js';
import QIcon from 'quasar/src/components/icon/QIcon.js';
import QSpace from 'quasar/src/components/space/QSpace.js';
import QMenu from 'quasar/src/components/menu/QMenu.js';
import QList from 'quasar/src/components/item/QList.js';
import QItem from 'quasar/src/components/item/QItem.js';
import QItemSection from 'quasar/src/components/item/QItemSection.js';
import QItemLabel from 'quasar/src/components/item/QItemLabel.js';
import QCardSection from 'quasar/src/components/card/QCardSection.js';
import QDialog from 'quasar/src/components/dialog/QDialog.js';
import QTree from 'quasar/src/components/tree/QTree.js';
import QCardActions from 'quasar/src/components/card/QCardActions.js';
import QToggle from 'quasar/src/components/toggle/QToggle.js';
import QInput from 'quasar/src/components/input/QInput.js';
import QImg from 'quasar/src/components/img/QImg.js';
import QFile from 'quasar/src/components/file/QFile.js';import ClosePopup from 'quasar/src/directives/ClosePopup.js';
import qInstall from "../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(component, 'components', {QPage,QBtn,QCard,QTabs,QTab,QTabPanels,QTabPanel,QIcon,QSpace,QMenu,QList,QItem,QItemSection,QItemLabel,QCardSection,QDialog,QTree,QCardActions,QToggle,QInput,QImg,QFile});qInstall(component, 'directives', {ClosePopup});
