<template>
  <q-layout view="hHh lpR fFf">
    <q-page-container>
      <q-page class="flex flex-center">
        <div>
          <h2>{{ this.checklist ? this.checklist.name : 'Loading...' }}</h2>
        </div>
        <div class="flex row" style="width: 80%">
          <pre class="col-5">{{ checklistString }}</pre>
          <pre class="col-7" style="overflow-x: scroll">{{ itemsString }}</pre>
        </div>
      </q-page>
    </q-page-container>
  </q-layout>
</template>

<style></style>

<script>
export default {
  name: 'ActiveChecklist',
  mixins: [],
  data() {
    return {
      checklist: null,
      items: [],
    }
  },
  computed: {
    checklistString() {
      if (this.checklist) return JSON.stringify(this.checklist, null, 2)
      else return 'Not Loaded'
    },
    itemsString() {
      if (this.items && this.items.length) return JSON.stringify(this.items, null, 2)
      else return 'Not Loaded'
    },
  },
  mounted() {
    this.loadChecklist()
  },
  methods: {
    async loadChecklist() {
      const checklistref = this.$firestore.collection('checklists').doc(this.$route.params.id)
      const doc = await checklistref.get()
      // TODO: Check for completed already as well. Perhaps forward to the report before getting the items?
      if (doc.exists) {
        this.checklist = doc.data()
        const items = await checklistref.collection('items').get()

        this.items = []
        items.forEach((doc) => {
          this.items.push(doc.data())
        })
      } else {
        this.$q.notify({
          type: 'negative',
          message: 'Checklist Not Found',
        })
      }
    },
  },
}
</script>
