<template>
  <div>
    <div class="q-pa-md fixed-center flex column full-width q-gutter-y-md">
      <div class="self-center">
        <q-btn-toggle
          v-model="billingInterval"
          class="toggle"
          no-caps
          rounded
          unelevated
          toggle-color="primary"
          color="white"
          text-color="primary"
          :options="[
            { label: 'Monthly', value: 'month' },
            { label: 'Annually', value: 'year' },
          ]"
        />
      </div>
      <div class="col-8 flex row q-gutter-x-md justify-center items-center">
        <q-card class="col-3 q-pa-md" v-for="product in products" :key="product.name">
          <q-item-label overline class="q-mb-sm">{{ product.name }}</q-item-label>
          <div class="flex justify-bottom items-end q-mb-sm">
            <q-item-label class="text-h4">{{ $options.filters.price(getPrice(product), billingInterval) }}</q-item-label>
            <q-item-label caption style="height: 20px">/{{ billingInterval }}</q-item-label>
          </div>
          <q-item-label class="text-h8 q-mb-sm">{{ product.description }}</q-item-label>
          <q-btn color="primary" class="full-width q-pa-none q-ma-none" no-caps unelevated @click="getStarted(product)">Get Started</q-btn>
        </q-card>
        <q-card class="col-3 q-pa-md q-mt-md" key="custom">
          <q-item-label overline class="q-mb-sm">Enterprise</q-item-label>
          <div class="flex justify-bottom items-end q-mb-sm">
            <q-item-label class="text-h4">Custom Pricing</q-item-label>
            <q-item-label caption style="height: 20px">monthly or annually</q-item-label>
          </div>
          <q-item-label class="text-h8 q-mb-sm">Call Sales, (607) 341-0349</q-item-label>
        </q-card>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Buy',
  data() {
    return {
      products: [],
      billingInterval: 'month',
    }
  },
  computed: {},
  mounted() {
    this.getProducts()
  },
  methods: {
    getStarted(product) {
      product.buyPrice = product.prices.find((price) => price.interval === this.billingInterval)
      //this.$log('product: ' + JSON.stringify(product, null, 2))
      this.$router.push({ name: 'AccountSetup', query: { productId: product.id } })
    },
    getPrice(product) {
      const price = product.prices.find((price) => price.interval === this.billingInterval)
      return price ? price.unit_amount : '???'
    },
    getProducts() {
      this.$firestore
        .collection('products')
        .where('active', '==', true)
        .get()
        .then((snapshot) => {
          snapshot.forEach(async (product) => {
            var subscription = product.data()
            subscription.id = product.id
            const priceSnap = await product.ref.collection('prices').get()

            subscription.prices = priceSnap.docs.map((doc) => {
              var price = doc.data()
              price.id = doc.id
              return price
            })
            this.products.push(subscription)
          })
        })
        .catch((err) => {
          this.$log('Unable to fetch products: ' + err)
        })
    },
  },
  components: {},
}
</script>

<style lang="css" scoped>
.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.input {
  width: 100%;
  height: 40px;
  background-color: white;
  margin-top: 30px;
  font-size: 15px;
}

.full-width {
  width: 100%;
}
.text-muted {
  color: gray;
}
.toggle {
  width: auto;
  border: 1px solid #027be3;
}
</style>
