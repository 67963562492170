<template lang="html">
  <div class="flex column self-center items-center" style="min-width: 300px">
    <q-table flat no-data-label="No Recent Checklists Found" :data="recentChecklists" :columns="columns" row-key="checklistId" hide-pagination @row-click="viewChecklist" />
  </div>
</template>

<script>
export default {
  name: 'RecentlyCompleted',
  props: ['company'],
  data() {
    return {
      recentChecklists: [],
      columns: [
        { name: 'name', align: 'left', label: 'Checklist', field: 'name', sortable: true, headerClasses: 'text-primary' },
        { name: 'location', align: 'left', label: 'Location', field: 'readableTreeString', sortable: false },
        { name: 'submittedByName', align: 'left', label: 'Performed By', field: 'submittedByName', sortable: true, headerClasses: 'text-primary' },
        { name: 'completedAt', align: 'left', label: 'Completed At', field: 'completedAt', sortable: true, headerClasses: 'text-primary' },
      ],
    }
  },
  computed: {},
  mounted() {
    this.getRecentlyCompleted()
  },
  methods: {
    async getRecentlyCompleted() {
      this.$firestore
        .collection('checklists')
        .where('status', '==', 'complete')
        .where('companyId', '==', this.company.companyId)
        .orderBy('completedAt', 'desc')
        .limit(10)
        .get()
        .then((snapshot) => {
          snapshot.forEach((doc) => {
            var checklist = doc.data()
            checklist.completedAt = this.$options.filters.readableDate(checklist.completedAt)
            if (checklist.readableTreeString === '') checklist.readableTreeString = 'No Location'
            checklist.link = window.location.origin + '/' + this.$router.resolve(`/completed/${checklist.checklistId}`).href
            this.recentChecklists.push(checklist)
          })
        })
    },
    viewChecklist(event, row) {
      this.$log('View Checklist: ' + JSON.stringify(row, null, 2))
      window.open(row.link, '_blank')
    },
  },
}
</script>

<style lang="css"></style>
