<script>
export default {
  data() {
    return {}
  },
  methods: {
    genUrl(checklistId, treepath) {
      let routeObj = { path: `/startchecklist/${checklistId}` }
      if (treepath && treepath.length) routeObj.query = { treepath: treepath }
      return window.location.origin + '/' + this.$router.resolve(routeObj).href
    },
    uniqId(checklistId, treepath) {
      return checklistId + '---' + treepath
    },
  },
  computed: {},
}
</script>
