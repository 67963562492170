<template>
  <div :style="'width: ' + size + 'px; max-height: ' + size + 'px;'">
    <q-btn :style="'width: ' + size + 'px; max-height: ' + size + 'px;'" flat>
      <div v-if="url" :ref="url" class="self-align"></div>
      <q-menu>
        <q-list style="min-width: 100px" class="flex column">
          <q-item-label class="q-pa-md" caption>Right-click the QR Code and select "Copy Image" to use elsewhere.</q-item-label>
          <q-separator />
          <q-item clickable @click="copyUrl">
            <q-item-section>Copy Destination URL</q-item-section>
          </q-item>
          <q-separator />
          <q-item class="flex column" clickable @click="openUrl">
            <q-item-section>Open Destination URL</q-item-section>
          </q-item>
        </q-list>
      </q-menu>
    </q-btn>
  </div>
</template>
<script>
import * as QRCode from 'easyqrcodejs'
import { copyToClipboard } from 'quasar'

export default {
  name: 'QRCodeBox',
  props: ['url', 'size'],
  data() {
    return {
      open: false,
      qrCodeObject: null,
      btoa,
    }
  },
  mounted() {
    this.$log('Qr url: ' + this.url)
    this.prepQrCode()
  },
  watch: {
    url() {
      this.prepQrCode()
    },
  },
  methods: {
    openUrl() {
      window.open(this.url, '_blank')
    },
    copyUrl() {
      copyToClipboard(this.url || 'Invalid URL')
        .then(() => {
          this.$q.notify({
            type: 'positive',
            message: 'Link Copied to Clipboard',
          })
        })
        .catch(() => {
          this.$q.notify({
            type: 'danger',
            message: 'Something went wrong, the link could not be copied. Please try again.',
          })
        })
    },
    async prepQrCode() {
      if (this.qrCodeObject) this.qrCodeObject.clear()
      await this.$nextTick() // Prevents an undefined error for the qr code.
      this.qrCodeObject = new QRCode(this.$refs[this.url], {
        text: this.url, // Content
        width: parseInt(this.size), // Widht
        height: parseInt(this.size), // Height
        correctLevel: QRCode.CorrectLevel.L, // L, M, Q, H
      })
    },
  },
  computed: {},
}
</script>
<style>
.tree-chooser-link {
  cursor: pointer;
}
</style>
