<template>
  <div class="fixed-center flex row" style="width: 500px;">
    <q-card class="q-pa-md q-gutter-y-md" style="width: 500px;" >
      <q-item-label class="text-h5 q-mb-sm">Can the page be found?</q-item-label>
      <q-item-label class="caption">Determine if the page can be found, if not, report a 404 error.</q-item-label>
      <q-card flat class="bg-grey-3 flex row" style="height: 40px; border: 1px solid lightgray">
        <label class="self-center col-6 text-center text-white bg-red-10" style="line-height: 40px;">No</label>
        <label class="col-6 text-center" style="line-height: 40px;">Yes</label>
      </q-card>
    </q-card>
    <q-item-label class="q-mt-md text-center" caption>Sorry, the page you are looking for is missing or broken, please try again or return to the <a href=""> home page</a>.</q-item-label>
  </div>
</template>

<style>
</style>

<script>
export default {
  name: 'NotFound'
}
</script>
