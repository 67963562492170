<template>
  <q-dialog v-model="showing" :persistent="hasEdits">
    <q-card class="checklist-card" v-if="showing">
      <q-card-section class="q-gutter-md">
        <div class="col text-h6">
          {{ title }}
        </div>
        <q-tabs v-if="!isEditing" v-model="tab" dense inline-label align="justify" class="text-primary" :breakpoint="0">
          <q-tab name="fromtemplate" label="From Template" icon="fas fa-clipboard-list" />
          <q-tab name="custom" label="Custom" icon="fas fa-clipboard" @click="chooseCustom" />
        </q-tabs>
        <q-tab-panels v-model="tab" animated>
          <q-tab-panel name="fromtemplate">
            <q-list>
              <q-item tag="label" v-ripple v-for="checklist in templateChecklists" :key="checklist.id">
                <q-item-section avatar>
                  <q-radio v-model="templateId" :val="checklist.id" />
                </q-item-section>
                <q-item-section>
                  <q-item-label>{{ checklist.name }}</q-item-label>
                  <q-item-label caption>{{ checklist.description }}</q-item-label>
                </q-item-section>
              </q-item>
            </q-list>
          </q-tab-panel>
          <q-tab-panel name="custom" class="q-gutter-y-sm">
            <q-input v-model="newChecklist.name" placeholder="Checklist Name" outlined dense maxlength="120" />
            <q-input v-model="newChecklist.description" placeholder="Description" outlined dense maxlength="240" />
            <q-card class="q-pa-md">
              <q-btn-toggle
                v-model="newChecklist.defaultType"
                class="q-mb-sm"
                style="border: 1px solid lightgray"
                no-caps
                rounded
                unelevated
                toggle-color="primary"
                color="white"
                text-color="primary"
                :options="[
                  { label: 'Multiple Choice', value: 'options' },
                  { label: 'Text Input', value: 'input' },
                ]"
              />
              <q-separator />
              <OptionsEditor
                v-if="newChecklist.defaultType === 'options'"
                :options="newChecklist.defaultOptions"
                @updateOptions="updateOptions($event)"
                description="All new items created in this checklist will default to these multiple choice options unless otherwise specified."
              />
              <div class="flex row q-py-md" style="width: 100%" v-else>
                <q-item-label caption
                  >All new items in this checklist will use a text field for responses unless otherwise specified. You can set the default placeholder text below.</q-item-label
                >
                <q-input class="q-mt-sm full-width" placeholder="Placeholder" v-model="newChecklist.defaultInput" outlined dense />
              </div>
            </q-card>

            <!-- Signature, require signed in and name are always required. Checklist items determine own required-ness  -->
            <!-- <q-card class="flex space-between column q-pa-sm">
              <q-item-label header class="q-pa-sm">REQUIREMENTS</q-item-label>
              <div style="width: 100%" class="flex items-start q-gutter-x-sm">
                <q-toggle v-model="newChecklist.authRequired" label="Users must be logged in to perform checklist." />
                <q-toggle v-if="!newChecklist.authRequired" v-model="newChecklist.nameRequired" label="Anonymous users must provide their name before submitting the checklist." />
                <q-toggle v-model="newChecklist.allRequired" label="All items must be checked in order to submit checklist." />
                <q-toggle v-model="newChecklist.signatureRequired" label="Users must provide signature in order to submit checklist." />
              </div>
            </q-card> -->

            <q-card class="flex space-between column q-pa-md">
              <q-item-label header class="q-pa-none">Settings</q-item-label>
              <q-toggle v-model="newChecklist.allowAnonymous" label="Allow checklist to be completed without a user login" />
            </q-card>
            <q-card class="flex space-between column q-pa-md">
              <q-item-label header class="q-pa-none">SOP Document</q-item-label>
              <q-item-label caption>An optional document to aid the completion of this checklist.</q-item-label>
              <q-file
                class="q-mt-sm"
                outlined
                v-model="sopDocument"
                label="Choose File"
                hint="PDF or Image File. Max Size 10 MB"
                accept=".pdf, image/*"
                @rejected="fileRejected"
                :max-file-size="maxFileSize"
              >
                <template v-slot:prepend>
                  <q-icon name="fas fa-paperclip" />
                </template>
              </q-file>
            </q-card>
          </q-tab-panel>
        </q-tab-panels>
      </q-card-section>
      <q-card-actions class="relative-bottom bg-white" align="right">
        <q-btn rounded flat no-caps color="negative" label="Discard" @click="confirmCancel" />
        <q-btn no-caps rounded unelevated class="bg-secondary text-primary text-bold" :disabled="!hasEdits" :label="isEditing ? 'Save' : 'Create Checklist'" @click="action" />
      </q-card-actions>
    </q-card>
    <sc-confirm
      ref="confirmDialog"
      @cancel="$refs.confirmDialog.toggle()"
      @confirm="cancel"
      text="Are you sure you would like to discard this checklist?"
      cancelText="Cancel"
      confirmText="Discard Checklist"
    ></sc-confirm>
  </q-dialog>
</template>

<style></style>

<script>
import OptionsEditor from '../blocks/OptionsEditor.vue'
import moment from 'moment'
import firebaseUpload from '../mixins/firebaseUpload.vue'
import CopyConfigChecklistMixin from '../mixins/CopyConfigChecklistMixin.vue'
export default {
  name: 'ChecklistModal',
  components: { OptionsEditor },
  mixins: [firebaseUpload, CopyConfigChecklistMixin],
  props: {
    checklist: {
      type: Object,
      required: false,
    },
  },
  data() {
    return {
      templateId: null,
      tab: 'fromtemplate',
      showing: false,
      orginalChecklist: {},
      newChecklist: {},
      locationType: 'all',
      isEditing: false,
      sopDocument: null,
      maxFileSize: 1024 * 1024 * 10, // ~10mb
    }
  },
  computed: {
    title() {
      return this.isEditing ? 'Edit - ' + this.originalChecklist.name : 'New Checklist'
    },
    hasEdits() {
      return JSON.stringify(this.orginalChecklist) !== JSON.stringify(this.newChecklist)
    },
    templateChecklists() {
      return this.$store.state.templates.checklists
    },
  },
  async mounted() {
    this.$eventBus.$emit('spinner')
    await this.$store.dispatch('loadTemplateChecklists')
    this.$eventBus.$emit('spinner', false)
  },
  methods: {
    chooseCustom() {
      this.templateId = null
    },
    toggle(checklist) {
      if (checklist) {
        this.isEditing = true
        this.newChecklist = checklist
        this.tab = 'custom'
      } else {
        this.isEditing = false
        this.newChecklist = {
          authRequired: true,
          nameRequired: false,
          allRequired: false,
          signatureRequired: true,
          allowAnonymous: false,
          defaultInput: 'Input',
          defaultType: 'options',
          defaultOptions: [
            {
              name: 'Compliant',
              color: '#27AF60',
              isDefault: false,
              iconName: '',
            },
            {
              name: 'Non-Compliant',
              color: '#C0382B',
              isDefault: false,
              iconName: '',
            },
          ],
        }
      }
      this.originalChecklist = JSON.parse(JSON.stringify(this.newChecklist))
      this.showing = !this.showing
    },
    updateOptions(event) {
      this.newChecklist.defaultOptions = JSON.parse(JSON.stringify(event))
    },
    action() {
      if (this.isEditing) this.updateChecklist()
      else this.createChecklist()
    },
    fileRejected() {
      this.$q.notify({
        type: 'error',
        message: 'Invalid SOP Document, ensure the file is an acceptable type and is smaller than 10 MB.',
      })
    },
    async createChecklist() {
      let newChecklistName
      let newChecklistId

      this.$eventBus.$emit('spinner')
      if (this.templateId) {
        newChecklistName = this.templateChecklists.find((tc) => tc.id === this.templateId).name
        this.$log(`we have templateId ${this.templateId}, let's copy`)
        newChecklistId = await this.copyConfigChecklist(this.templateId)
      } else {
        newChecklistName = this.newChecklist.name
        this.$log('New checklist:' + JSON.stringify(this.newChecklist, null, 2))
        this.newChecklist.companyId = this.$store.getters.accountCompanies[0] //checklists belong to a company
        this.newChecklist.deletedAt = false
        const newChecklistRef = this.$firestore.collection('configChecklists').doc()
        this.newChecklist.configChecklistId = newChecklistRef.id
        newChecklistId = this.newChecklist.configChecklistId

        if (this.sopDocument) {
          await this.uploadFile(this.sopDocument, 'resources/' + newChecklistRef.id + moment())
            .then((url) => {
              this.newChecklist.resource = url
            })
            .catch(() => {
              this.$q.notify({
                type: 'error',
                message: 'Error uploading SOP Document. Please try again.',
              })
            })
        }
        await newChecklistRef.set(this.newChecklist)
      }
      this.$refs.confirmDialog.toggle()
      this.showing = false
      this.$emit('close', newChecklistId)

      this.$firestore.collection('companies').doc(this.$store.state.user.data.companies[0]).collection('tasks').doc('createChecklist').update({ status: 'complete' })

      this.$q.notify({
        type: 'positive',
        message: newChecklistName + ' Created',
      })
      this.$eventBus.$emit('spinner', false)
    },
    updateChecklist() {
      this.$firestore
        .collection('configChecklists')
        .doc(this.$route.params.checklistId)
        .update(this.newChecklist)
        .then(() => {
          this.$refs.confirmDialog.toggle()
          this.showing = false
          this.$emit('close')
          this.$q.notify({
            type: 'positive',
            message: this.newChecklist.name + ' Updated',
          })
        })
    },
    confirmCancel() {
      if (this.hasEdits) this.$refs.confirmDialog.toggle()
      else this.showing = false
    },
    cancel() {
      this.$refs.confirmDialog.toggle()
      this.showing = false
    },
  },
}
</script>
<style lang="sass" scoped>
.checklist-card
  width: 600px
  height: 750px
</style>
