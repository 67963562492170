<template>
  <q-layout view="lHh LpR fFf">
    <q-header class="bg-white shadow-1" v-if="$route.meta.parent">
      <q-toolbar>
        <q-btn flat dense round @click="leftDrawerOpen = !leftDrawerOpen" aria-label="Menu" class="text-primary" icon="fas fa-bars" />
        <q-toolbar-title class="text-primary">{{ $route.meta.title || 'Simple Check' }}</q-toolbar-title>
        <q-btn-dropdown class="bg-transparent text-accent" no-caps unelevated>
          <template v-slot:label>
            <div class="row items-center no-wrap">
              <div class="text-right">
                <q-item-label class="text-primary">{{ $store.state.user.data.displayName }}</q-item-label>
                <q-item-label v-if="company" class="text-primary" caption>{{ company.name }}</q-item-label>
              </div>
            </div>
          </template>
          <q-list>
            <q-item>
              <q-item-section>
                <q-item-label caption>{{ $store.state.user.data.email }}</q-item-label>
              </q-item-section>
            </q-item>
            <q-separator />

            <q-item clickable v-close-popup @click="goto('Account')">
              <q-item-section>
                <q-item-label>View Account</q-item-label>
              </q-item-section>
            </q-item>
            <q-item v-if="isAdmin" clickable v-close-popup @click="goto('Dashboard')">
              <q-item-section>
                <q-item-label>Admin Dashboard</q-item-label>
              </q-item-section>
            </q-item>
            <q-item clickable v-close-popup @click="$refs.confirmDialog.toggle()">
              <q-item-section>
                <q-item-label class="text-red">Logout</q-item-label>
              </q-item-section>
            </q-item>
          </q-list>
        </q-btn-dropdown>
        <!-- <q-btn dense flat round icon="fas fa-info-circle" @click="right = !right" v-if="tasksRemainingCount > 0">
          <q-badge color="red" floating>{{ tasksRemainingCount }}</q-badge>
        </q-btn> -->
      </q-toolbar>
    </q-header>

    <q-drawer v-if="$route.meta.parent" v-model="leftDrawerOpen" show-if-above :breakpoint="500" content-class="bg-primary" class="bg-primary">
      <div style="height: 100vh" class="">
        <img class="q-ma-sm" src="./assets/simplecheckbanner.png" width="239px" stretch="aspectFit" />
        <q-list>
          <q-item clickable :class="activeOption === 'Home' ? 'text-primary text-bold border-active' : ''" @click="goto('Home')">
            <q-item-section>
              <q-item-label class="left-option">Home</q-item-label>
            </q-item-section>
          </q-item>
          <q-item v-if="hasAccounts" clickable :class="activeOption === 'Checklists' ? 'text-primary text-bold border-active' : ''" @click="goto('Checklists')">
            <q-item-section>
              <q-item-label class="left-option">Checklists</q-item-label>
            </q-item-section>
          </q-item>
          <q-item v-if="isAdmin" clickable :class="activeOption === 'Locations' ? 'text-primary text-bold border-active' : ''" @click="goto('Locations')">
            <q-item-section>
              <q-item-label class="left-option">Locations</q-item-label>
            </q-item-section>
          </q-item>
          <q-item v-if="hasAccounts" clickable :class="activeOption === 'Users' ? 'text-primary text-bold border-active' : ''" @click="goto('Users')">
            <q-item-section>
              <q-item-label class="left-option">Users</q-item-label>
            </q-item-section>
          </q-item>
          <q-item v-if="hasAccounts" clickable :class="activeOption === 'Reports' ? 'text-primary text-bold border-active' : ''" @click="goto('DetailsReport')">
            <q-item-section>
              <q-item-label class="left-option">Reports</q-item-label>
            </q-item-section>
          </q-item>
          <q-item v-if="hasAccounts" clickable :class="activeOption === 'Account' ? 'text-primary text-bold border-active' : ''" @click="goto('Account')">
            <q-item-section>
              <q-item-label class="left-option">Account</q-item-label>
            </q-item-section>
          </q-item>
        </q-list>
        <sc-confirm ref="confirmDialog" @cancel="$refs.confirmDialog.toggle()" @confirm="logout" text="Are you sure you would like to logout?" confirmText="Logout"></sc-confirm>
      </div>
    </q-drawer>

    <q-drawer v-model="right" side="right" bordered :width="400">
      <div class="q-pa-md q-gutter-y-md" v-if="tasks">
        <q-card class="flex row no-wrap" flat>
          <q-circular-progress
            show-value
            font-size="12px"
            :value="quickStartProgress"
            size="50px"
            :thickness="0.22"
            color="primary"
            track-color="grey-3"
            class="q-ma-sm"
            style="min-width: 50px"
            >{{ completedTaskCount }}/{{ tasks.length }}</q-circular-progress
          >
          <div class="flex row">
            <q-item-label class="q-pa-none text-h6">Quick Start</q-item-label>
            <q-item-label caption class="q-ma-none">Finish setting up your account by completeing the remaining tasks below.</q-item-label>
          </div>
        </q-card>

        <q-separator inset />

        <q-list class="q-gutter-y-md" v-if="tasks">
          <q-card class="" v-for="task in tasks" :key="task.taskId">
            <q-card-section>
              <div class="text-h6 q-mr-sm">{{ task.title }}</div>
              <div class="text-body-1">{{ task.description }}</div>
              <q-badge v-if="task.status === 'complete'" outline dense color="primary" style="height: 20px" class="self-center q-mt-sm" label="Complete" />
            </q-card-section>
            <q-separator />
            <q-card-actions dense align="right" v-if="task.status !== 'complete' && (task.actionLabel || task.detailLabel)">
              <q-item-label dense flat no-caps color="primary">{{ task.detailLabel }}</q-item-label>
              <q-btn dense flat no-caps color="primary" @click="goToQuickStartTask(task)">{{ task.actionLabel }}</q-btn>
              <q-linear-progress indeterminate v-if="task.taskId === 'completeChecklist'" color="primary" />
            </q-card-actions>
          </q-card>
        </q-list>
      </div>
    </q-drawer>
    <q-page-container>
      <q-banner inline-actions class="text-white bg-accent" v-if="daysRemainingInFreeTrial <= 14">
        <q-avatar color="bg-transparent" text-color="white" icon="fas fa-exclamation-circle"></q-avatar>
        {{ expirationBannerText }}
        <template v-slot:action>
          <q-btn flat no-caps color="white text-bold" label="View Plans" @click="viewPlans" />
        </template>
      </q-banner>
      <q-banner inline-actions class="text-white bg-orange" v-if="usagePercentage >= 75">
        <q-avatar color="bg-transparent" text-color="white" icon="fas fa-exclamation-circle"></q-avatar>
        Your company is using {{ checklistsUsed }} of the completed checklist limit for the current billing period. Consider upgrading your plan to avoid reporting restrictions.
        <template v-slot:action>
          <q-btn flat no-caps color="white text-bold" label="View Plans" @click="viewPlans" />
        </template>
      </q-banner>
      <HomeNotSubscribed v-if="company && !subscription && !isAdmin"></HomeNotSubscribed>
      <router-view v-else-if="company || isAdmin" class="w-100 h-100" :company="company"></router-view>
      <HomeUser v-else-if="!loading" />
    </q-page-container>
  </q-layout>
</template>

<script>
import CompanyMixin from './components/mixins/CompanyMixin.vue'
import HomeNotSubscribed from './components/HomeNotSubscribed.vue'
import HomeUser from './components/HomeUser.vue'
import { mapGetters } from 'vuex'

import { QSpinnerFacebook } from 'quasar'
export default {
  name: 'LayoutDefault',
  mixins: [CompanyMixin],
  components: { HomeUser, HomeNotSubscribed },
  data() {
    return {
      leftDrawerOpen: false,
      right: false,
      loading: true,
    }
  },
  computed: {
    quickStartProgress() {
      return (this.completedTaskCount / this.tasks.length) * 100
    },
    activeOption() {
      return this.$route.meta.parent || 'Simple Check'
    },
    ...mapGetters(['accountCompanies']),
    hasAccounts() {
      return (!!this.company && !!this.subscription) || this.isAdmin
    },
    isAdmin() {
      return this.$store.state.user && this.$store.state.user.isAdmin
    },
    expirationBannerText() {
      if (this.daysRemainingInFreeTrial >= 1) return this.daysRemainingInFreeTrial + ' days remaining in your free trial. Subscribe to a plan now to continue using Simple Check.'
      else return 'Your free trail has expired. Subscribe to a plan now to continue using Simple Check.'
    },
  },
  created() {
    this.$log('Running with the auth domain of: ' + process.env.VUE_APP_FIREBASE_AUTH_DOMAIN)

    this.$q.loading.show({ spinner: QSpinnerFacebook, backgroundColor: 'white', spinnerColor: 'primary' })
  },
  async mounted() {
    await this.getCompany()
    this.$q.loading.hide()
    this.loading = false
  },
  methods: {
    async viewPlans() {
      this.$q.loading.show({
        backgroundColor: 'white',
        spinnerColor: 'primary',
      })
      const functionRef = this.$firebase.app().functions('us-central1').httpsCallable('stripe-createPortalLink')
      const { data } = await functionRef({ companyId: this.company.companyId, returnUrl: window.location.origin + '/' + this.$router.resolve(`/Home?reload=true`).href })
      this.$q.loading.hide()
      if (data) window.location.assign(data.url)
    },
    goToQuickStartTask(task) {
      if (task.taskId === 'createChecklist') this.goto('Checklists')
      if (task.taskId === 'invite') this.goto('Account')
    },
    goto(name) {
      this.$eventBus.$emit('spinner')
      this.$eventBus.$emit('spinner', false)
      // this.leftDrawerOpen = false
      return this.$route.name !== name && this.$router.push({ name })
    },
    logout() {
      this.$firebase
        .auth()
        .signOut()
        .then(() => {
          this.leftDrawerOpen = false
          this.$router.replace({ name: 'Login' })
          this.$q.notify({
            type: 'positive',
            message: 'Logged Out Successfully',
          })
        })
    },
  },
}
</script>

<style>
* {
  font-family: 'Poppins', Helvetica, Arial, Lucida, sans-serif;
}
.border-active {
  border-left: 5px solid #faad3a;
  background-color: rgba(255, 255, 255, 0.1);
}

.left-option {
  /* color: rgb(30, 30, 30); */
  font-size: 14px;
  color: white;
  font-weight: bold;
}

.left {
  background-color: primary;
  border-right: 1px solid lightgray;
}
</style>
