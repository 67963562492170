<template>
  <q-card flat class="q-py-md">
    <div class="flex no-wrap justify-between">
      <div class="flex row" style="width: 75%">
        <q-item-label caption>{{ description }}</q-item-label>
      </div>
      <q-btn no-caps rounded unelevated class="bg-secondary text-primary text-bold" size="md" label="Add Option" @click="addOption" />
    </div>
    <draggable class="q-pa-sm" v-model="newOptions" handle=".dragger">
      <div class="option q-pa-sm flex row q-gutter-x-sm" v-for="(option, index) in newOptions" :key="option.id">
        <q-icon class="dragger self-center text-grey-7" style="cursor: grab" name="fas fa-grip-lines"></q-icon>
        <v-swatches
          class="self-center"
          style="height: 25px"
          v-model="option.color"
          swatch-size="25"
          :trigger-style="{ width: '25px', height: '25px', borderRadius: '5px' }"
        ></v-swatches>
        <!-- <q-icon class="icon self-center" label="no icon" :name="option.iconName" @click="$refs.picker.toggle(option)"></q-icon> -->
        <q-input v-model="option.name" placeholder="Option Name" outlined dense maxlength="25" />
        <q-space />
        <q-checkbox v-model="option.isDefault" @input="setDefaultChoice(index)" />
        <q-icon class="self-center text-grey-7 q-pa-sm" style="cursor: pointer" name="fas fa-times" @click="removeOption(option)"></q-icon>
      </div>
    </draggable>
  </q-card>
</template>

<script>
import VSwatches from 'vue-swatches'
import 'vue-swatches/dist/vue-swatches.css'
import draggable from 'vuedraggable'
export default {
  props: ['title', 'description', 'options'],
  components: { VSwatches, draggable },
  data() {
    return {
      newOptions: null,
    }
  },
  mounted() {
    this.newOptions = JSON.parse(JSON.stringify(this.options))
  },
  watch: {
    newOptions: {
      deep: true,
      handler: function (newOptions) {
        this.$emit('updateOptions', newOptions)
      },
    },
  },
  methods: {
    addOption() {
      this.newOptions.push({
        name: '',
        color: '#27AF60',
        isDefault: false,
        iconName: '',
        id: this.$nanoid(),
      })
    },
    setDefaultChoice(index) {
      this.newOptions.forEach((opt, i) => {
        if (i !== index) opt.isDefault = false
      })
    },
    removeOption(option) {
      if (this.newOptions.length === 1) {
        return this.$q.notify({ type: 'warning', message: 'There must be at least one option to select.' })
      }
      this.newOptions = this.newOptions.filter((opt) => opt !== option)
    },
  },
}
</script>
<style lang="sass" scoped>
.option
  border: 1px solid lightgray
  background-color: white
.icon
  background-color: whitesmoke
  border: 1px solid lightgray
  border-radius: 5px
  width: 25px !important
  height: 25px !important
  cursor: pointer
</style>
